import requestBaseMethod from './baseApi';

export const getTenders = (length, start = 0, search = '') => {
  const url = `Tenders/Active?length=${length}&start=${start}${search && `&search=${search}`}`;
  return requestBaseMethod(url, 'GET');
};

export const validateTenders = (id) => {
  const url = `Tenders/${id}`;
  return requestBaseMethod(url, 'GET');
};
