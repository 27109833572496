import requestBaseMethod from './baseApi';

export const getCompany = (id) => {  
  const url = `Companies/company/${id}`;
  return requestBaseMethod(url, 'GET');
};

export const storeCompany = (data) => {
  const url = 'Companies';
  return requestBaseMethod(url, 'POST', data);
};

export const editCompany = (data, id) => {
  const url = `Companies/${id}`;
  return requestBaseMethod(url, 'PUT', data);
};

export const changeSelectedCompany = (userId, companyId) => {
  const url = `ClientUsers/${userId}/defaultCompany/${companyId}`;
  return requestBaseMethod(url, 'PUT');
};

export const sendContactForm = (data) => {
  const url = 'ClientUsers/contactForm';
  return requestBaseMethod(url, 'POST', data);
};
export const postExecutive = (companyId) => {
  const url =`Companies/${companyId}/AssignExecutive`;
  return requestBaseMethod(url, 'POST');
};
export const sendGeneralContact = (data) => {
  const url =`clientUsers/generalContact`;
  return requestBaseMethod(url, 'POST',data);
};
export const sendLandingContact = (data) => {
  const url =`clientUsers/landingContact`;
  return requestBaseMethod(url, 'POST',data);
};
export const whatsappContact = (data) => {
  const url =`clientUsers/whatsappContact`;
  return requestBaseMethod(url, 'POST',data);
};
