import React from 'react';
import Spinner from '../../Spinner';

function StepFour() {
  return (
    <div className="step_notification_container">
      <h1
        className="step_notification_title"
      >
        Estamos simulando tu garantía
      </h1>
      <h2
        className="step_notification_subtitle"
      >
        Esto podría tomar unos minutos
      </h2>
      <Spinner
        className="step_notification_spinner"
        styles={{
          borderLeftColor: '#304F97',
          width: '2.5em',
          height: '2.5em',
          margin: 0,
        }}
      />
    </div>
  );
}

export default StepFour;
