import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import DataTable from '../../components/DataTable';

import PdfModal from '../../components/PdfModal';
import Spinner from '../../components/Spinner';
import getAceptaDocument from '../../utils/apis/aceptaApi';
import { getMyWarrantiesFromAPI } from '../../utils/apis/warrantiesApi';
import useWindowDimentions from '../../utils/helpers/Hooks/useWindowDimentions';
import MobileTable from '../../components/MobileTable/MobileTable';
import { getTenders, validateTenders } from '../../utils/apis/tendersApi';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import InputForm from '../../components/InputForm';
import DataTableWarranty from '../../components/DataTable/indexDataTable';


const columnsWarranties = [
  {
    title: 'Fecha',
    field: 'DateString',
    disableClick: true,
  },
  {
    title: 'Código',
    field: 'DocumentCode',
    disableClick: true,
  },
  {
    title: 'Caduca',
    field: 'EndDateString',
    disableClick: true,
  },
  {
    title: 'Monto',
    field: 'AmountString',
    disableClick: true,
  },
  {
    title: 'Licitación',
    field: 'TenderCode',
    headerStyle: {},
    disableClick: true,
  },
  {
    title: 'Estado',
    field: 'State',
    disableClick: true,
  },
];

const tableOptions = {
  search: false,
  showTitle: false,
  toolbar: false,
  paging: false,

};

function WarrantiesTable({ company }) {

  const [tableData, setTableData] = useState([]);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [pdfData, setPdfData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [emptyTableMessage, setEmptyTableMessage] = useState(null);
  const [width] = useWindowDimentions();

  const [inputText, setInputText] = useState('');
  const [searching, setSearching] = useState(false);
  const [tenders, setTenders] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedTender, setSelectedTender] = useState({});
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [page, setPage] = useState(0);
  const tableRef = useRef();



  const getWarranty = async ({ DocumentCode }) => {
    try {
      setLoadingDocument(true);
      const res = await getAceptaDocument(DocumentCode);
      setLoadingDocument(false);
      setPdfData(res);
      setShowModal(true);
    } catch (error) {
      console.log(error);
      setLoadingDocument(false);
    }
  };

  const documentIcon = () => (
    !loadingDocument ? (
      <FontAwesomeIcon
        icon={faDownload}
        color="var(--secondary-color)"
        style={{ fontSize: 14 }}
      />
    )
      : <Spinner />
  );
  useEffect(async () => {
    setTableData([]);
    setEmptyTableMessage(null);
    try {
      const res = await getMyWarrantiesFromAPI(company.CompanyId);
      res.sort(function (a, b) {
        if (a.ItemId < b.ItemId) {
          return 1;
        } return -1;
      }
      );
      if (res.length === 0) {
        setEmptyTableMessage('Su empresa no posee garantias compradas.');
      }

      setTableData(res);

    } catch (error) {
      console.log(error);
    }
  }, [company]);


  const submitSearch = async (event) => {
    event.preventDefault();
    refreshData();
  };
  const refreshData = () => {
    setPage(0);
    setLoadingDocument(false)
    setSearching(false);
    setSearch(inputText);
    setSearching(true);
    setLoadingDocument(false)
    // if (tableRef.current) tableRef.current.onQueryChange();
  };

  

  return (
    <div>
      <div className="tenders_table_header">
        <h2 className='text__table__container'>Garantías</h2>
        <div className="warranty_search_input">
          {/* {searchIcon()} */}
          <button
          id='btn__warranty'
            className='button__blue mb-4'
            onClick={() => refreshData()}
          >
            Buscar
          </button>

          <form
          id='form__warranty'
          onSubmit={submitSearch} style={{ width: '100%' }}>
            <InputForm
              type="text"
              placeholder="Código de la Garantía..."
              handlerChange={(e) => setInputText(e.target.value)}
              name="searchInput"
              value={inputText}
              className='input__table__container'
              submitHandler
            />
            {/* <input type="submit" hidden /> */}
          </form>
        </div>
      </div>
      {width > 850 ? (
        <DataTableWarranty
          emptyMessage={emptyTableMessage}
          data={tableData}
          columns={columnsWarranties}
          data2={search}
          options={tableOptions}
          stylesContainer={{ width: 'auto', margin: 'auto' }}
          actions={[{
            icon: () => documentIcon(),
            tooltip: 'Verificar Garantía',
            onClick: (event, row) => getWarranty(row),
          }]}

          innerRef={tableRef}
        />
      )
        : (
          <MobileTable
            data={tableData}
            emptyMessage={emptyTableMessage}
            columns={columnsWarranties}
            options={tableOptions}
            stylesContainer={{ width: 'auto', margin: 'auto' }}
            actions={[{
              icon: () => documentIcon(loadingDocument),
              onClick: getWarranty,
              round: true,
            },
            ]}
            headers={['DocumentCode']}
          />
        )}
      {showModal && <PdfModal pdfData={pdfData} setShowModal={setShowModal} />}
    </div>
  );
}

export default WarrantiesTable;
