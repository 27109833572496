import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../DataTable';
import SquareButton from '../SquareButton';
import './styles.css';
import { getOwners, deleteOwner } from '../../utils/apis/ownersApi';
import OwnersModal from '../OwnersModal';
import ModalTemplate from '../ModalTemplate';
import MobileTable from '../MobileTable/MobileTable';
import useWindowDimentions from '../../utils/helpers/Hooks/useWindowDimentions';
import { createNotification } from '../../utils/helpers/helpers';
import trash from "../../assets/images/trash.svg"
import plus from "../../assets/images/plus.svg"

function OwnersTable({ selectedCompany, user, companies: { defaultCompany } }) {
  const columnsContracts = [
    {
      title: 'Nombre',
      field: 'Name',
      headerStyle: {
        fontWeight: 'bolder',
        borderColor: '#0000003B',
      },
      disableClick: true,
    },
    {
      title: 'Rut',
      field: 'Rut',
      headerStyle: {
        fontWeight: 'bolder',
        borderColor: '#0000003B',
      },
      disableClick: true,
    },
    {
      title: 'Domicilio',
      field: 'Address',
      headerStyle: {
        fontWeight: 'bolder',
        borderColor: '#0000003B',
      },
      disableClick: true,
    },
    {
      title: 'Email',
      field: 'Email',
      headerStyle: {
        fontWeight: 'bolder',
        borderColor: '#0000003B',
      },
      disableClick: true,
    },

  ];

  const tableOptions = {
    search: true,
    showTitle: false,
    toolbar: false,
    paging: false,
  };

  const [companyOwners, setCompanyOwners] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentOwner, setCurrentOwner] = useState({});
  const [removeOwnerModal, setRemoveOwnerModal] = useState(false);
  const [emptyTableMesage, setEmptyTableMesage] = useState(null);
  const [width] = useWindowDimentions();

  const refreshOwners = async () => {
    setCompanyOwners([]);
    setEmptyTableMesage(null);
    try {
      const res = await getOwners(selectedCompany.CompanyId);
      if (res.length === 0) setEmptyTableMesage('Aún no tienes socios registrados');
      console.log(res);
      setCompanyOwners(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    refreshOwners();
  }, [selectedCompany]);

  const showRemoveModal = (owner) => {
    setCurrentOwner(owner);
    setRemoveOwnerModal(true);
  };

  const removeOwner = async ({ OwnerId }) => {
    try {
      console.log(OwnerId);
      await deleteOwner(OwnerId);
      console.log('deleted');
      setRemoveOwnerModal(false);
      setCurrentOwner({});
      refreshOwners();
      createNotification('info', 'Socio eliminado exitosamente.', null, 3000);
    } catch (error) {
      setRemoveOwnerModal(false);
      createNotification('error', 'Por favor intentelo nuevamente', 'Ha ocurrido un error', 3000);
    }
  };

  const deleteIcon = () => (
    <img src={trash}></img>
  );

  return (
    <div>
      <div className="owners_table__header">
        <h2
          className="member__title"
          style={{ marginTop: 5 }}
        >
          Mis Socios
        </h2>
        <button onClick={() => setShowModal(true)}>
          <img src={plus}></img>
        </button>
      </div>
      {width > 700 ? (
        <DataTable
          data={companyOwners}
          columns={columnsContracts}
          options={tableOptions}
          stylesContainer={{ width: 'auto', margin: 'auto' }}
          emptyMessage={emptyTableMesage}
          actions={[
            {
              // eslint-disable-next-line react/no-unstable-nested-components
              icon: () => deleteIcon(),
              toolTip: 'Borrar socio',
              onClick: (event, rowData) => showRemoveModal(rowData),
            },
          ]}
        />
      )
        : (
          <MobileTable
            data={companyOwners}
            columns={columnsContracts}
            options={tableOptions}
            stylesContainer={{ width: 'auto', margin: 'auto' }}
            emptyMessage={emptyTableMesage}
            // emptyMessage="Aún no tienes socios registrados"
            headers={['Name', width > 400 && 'Rut']}
            actions={[{
              icon: () => deleteIcon(),
              onClick: showRemoveModal,
              round: true,
              backgroundColor: 'crimson',
            },
            ]}
          />
        )}
      {showModal && (
        <OwnersModal
          setShowModal={setShowModal}
          defaultCompany={defaultCompany}
          user={user}
          refreshOwners={refreshOwners}
        />
      )}
      {removeOwnerModal && (
        <ModalTemplate closeModal={() => setRemoveOwnerModal(false)} title="Eliminar socio">
          <p>
            Está seguro que desea eliminar a
            <span> <strong>{currentOwner.Name}</strong></span>
            {' '}
            de su lista de socios?
          </p>
          <div className="remove_owner_actions">
            <SquareButton
              value="Aceptar"
              type="text"
              clickHandler={() => removeOwner(currentOwner)}
              styles={{
                marginTop: '1rem',
                alignSelf: 'flex-end',
              }}
            />
          </div>
        </ModalTemplate>
      )}
    </div>
  );
}

export default OwnersTable;

