import React, { useEffect } from "react";
import shoppingCart from "../../assets/images/shoppingCart.svg";
import logoInge from "../../assets/images/logoInge.svg";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import "./styles.css";


function CartIcon({ style, user: { isAuth } }) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    history.push("/cart")
    setOpen(false)};
  const { warranties: warrantiesInCart, loading: cartLoading } = useSelector(
    (state) => state.shoppingCart
  );

  useEffect(() => {
    if(warrantiesInCart.length != 0){      
      handleOpen()
    }
  }, [cartLoading])


  return (
    <>
      <div>
        {isAuth ? (
          <div className="cartIcon_container">
            <img
              src={shoppingCart}
              alt="shoppingCart"
              onClick={() => history.push("/cart")}
            ></img>

            {!cartLoading && (
              <div className="cart_indicator">{warrantiesInCart.length}</div>
            )}
          </div>
        ) : null}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <article className="modal__simulation">
          <div className="contenedor__modal text-center">
            <img src={logoInge} alt="logo inge"></img>
            <h4> Aprobación de Garantía</h4>
            <p>Nos complace informarte que tu solicitud de garantía ha sido aceptada con éxito. </p>
            <p>Haz click en el boton o anda al carrito de compras para pagar y descargar tu garantía</p>
            <button
              onClick={handleClose}              
              className="button__blue">
              Haz Click Acá
            </button>
          </div>
        </article>
      </Modal>
    </>

  );
}
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(CartIcon);
