import React from 'react';
import SquareButton from '../../SquareButton';
import './styles.css';

function OwnerResponse({ newOwnerData, nextStep }) {
  return (
    <div className="step_container">
      <div className="step_content step_results">
        <div className="step_results_item">
          <p>
            Nombre:
          </p>
          <span>{newOwnerData.name}</span>
        </div>
        <div className="step_results_item">
          <p>
            RUT:
          </p>
          <span>{newOwnerData.rut}</span>
        </div>
        <div className="step_results_item">
          <p>
            Dirección:
          </p>
          <span>{newOwnerData.address}</span>
        </div>
        <div className="step_results_item">
          <p>
            Email:
          </p>
          <span>{newOwnerData.email}</span>
        </div>
      </div>
      <div
        className="step_actions"
      >
        <SquareButton
          value="Siguiente"
          type="text"
          clickHandler={nextStep}
        />
      </div>
    </div>
  );
}

export default OwnerResponse;
