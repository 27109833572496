import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './styles.css';
import Backdrop from '../Backdrop';

function ModalTemplate({
  title, children, closeModal, styles, hideHeader,
}) {
  const closeBackdrop = (e) => {
    if (e.target.className === 'backdrop_container') {
      closeModal();
    }
  };

  return (
    <Backdrop onClick={(e) => closeBackdrop(e)}>
      <div className="modal_container" style={{ ...styles }}>
        {!hideHeader && (
        <div className="modal_header">
          <h1>
            {title}
          </h1>
          <FontAwesomeIcon
            icon={faTimesCircle}
            size="lg"
            color="#0065FF"
            onClick={() => closeModal()}
            style={{ cursor: 'pointer' }}
          />
        </div>
        )}
        <div className="modal_content">{children}</div>
      </div>
    </Backdrop>
  );
}

export default ModalTemplate;
