import React, { useState } from "react";
import "./styles.css";
import { faTrashAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import Spinner from "../Spinner";
import {
  removeFromCart,
  getWarrantyDraftFromAPI,
} from "../../utils/apis/warrantiesApi";
import {
  createNotification,
  updateShoppingCart,
} from "../../utils/helpers/helpers";
import { addToCart } from "../../store/actions/shoppingCartActions";
import PdfModal from "../../components/PdfModal";
import LoadingScreen from "../Spinner/LoadingScreen";

function CartItem({ item, company, user, ...props }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [removingItem, setRemovingItem] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pdfData, setPdfData] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(company)


  const previewWarrantyDraft = async (warrantyId) => {
    console.log('garantia', warrantyId)
    try {
      setRemovingItem(true);          
      const res = await getWarrantyDraftFromAPI(warrantyId);      
      console.log(res)
      setLoading(false);
      setPdfData(res);
      setShowModal(true);
      setRemovingItem(false);
    } catch (error) {
      console.log(error);      
      setRemovingItem(true);
    }
  };

  const removeItemAndUpdateStore = async (warrantyId) => {
    setRemovingItem(true);
    try {
      await removeFromCart(warrantyId);
      await updateShoppingCart(user.dataUser.ClientId, props.addToCart);
      createNotification("success", "Garantía eliminada con éxito");
    } catch (error) {
    } finally {
      setRemovingItem(false);
    }
  };
  const deleteIcon = () =>
    !removingItem ? (
      <FontAwesomeIcon
        icon={faTrashAlt}
        onClick={() => removeItemAndUpdateStore(item.WarrantyId)}
      />
    ) : (
      <Spinner />
    );
  console.log(item)
  const previewIcon = () => console.log(loading);
  return (
    <div className="product_container">
      <section className="product_info_container w-100">
        <div className="content__title__product">
          <h2>{item.WarrantyCode}</h2>
          <div className="d-flex align-items-center justify-content-around">
            <button
              className="button__blue me-2"
              onClick={() => previewWarrantyDraft(item.WarrantyId)}
            >
              {!removingItem ? (
                <div className="w-50">
                  ver
                  <FontAwesomeIcon icon={faEye} className="ms-2" />
                </div>
              ) : (
                <Spinner />
              )}
            </button>
          </div>
        </div>

        <article className="container__product__info">
          <div className="cont__text">
            <h3>Garantia: </h3>
            <p>{item.WarrantyName}</p>
          </div>
          <div className="cont__text">
            <h3>Finalidad: </h3>
            <p>{item.WarrantyType}</p>
          </div>
          <div className="cont__text">
            <h3>Glosa: </h3>
            <p> {item.Glosa}</p>
          </div>
          <div className="cont__text">
            <h3>Fecha Inicio: </h3>
            <p> {item.StartDate.split("T")[0]}</p>
          </div>
          <div className="cont__text">
            <h3>Fecha Termino: </h3>
            <p> {item.Enddate.split("T")[0]}</p>
          </div>
          <div className="cont__text justify-content-end">
            <p>Comisión Garantía: </p>
            <h3> {" $" + new Intl.NumberFormat().format(item.WarrantyCost)}</h3>
          </div>
        </article>
      </section>
      {showModal && <PdfModal pdfData={pdfData} setShowModal={setShowModal} />}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  addToCart: (payload) => dispatch(addToCart(payload)),
});

export default connect(null, mapDispatchToProps)(CartItem);
