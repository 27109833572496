import icontime from "../../../../assets/images/icontime.svg";
import iconfast from "../../../../assets/images/iconfast.svg";
import iconpencil from "../../../../assets/images/iconpencil.svg";
import iconhappyface from "../../../../assets/images/iconhappyface.svg";

export const icons = [
  {
    subtitle: "AUTOATENCIÓN",
    text: "Podrás gestionar tus solicitudes de forma inmediata desde nuestra plataforma.",
    img: <img src={icontime}></img>,
  },
  {
    subtitle: "RAPIDEZ DE EMISIÓN",
    text: "La emisión de tus garantías se realizará de forma expedita con nuestro sistema",
    img: <img src={iconfast}></img>,
  },
  {
    subtitle: "FIRMA ONLINE",
    text: "Optimizarás tu tiempo gracias a nuestra firma digital, olvídate de ir a la Notaría.",
    img: <img src={iconpencil}></img>,
  },
  {
    subtitle: "EJECUTIVO ASIGNADO",
    text: "Contarás con atención 100% personalizada con un ejecutivo exclusivo para tus casos y solicitudes. ",
    img: <img src={iconhappyface}></img>,
  },
];

