import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import "./styles.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { createNotification } from "../../utils/helpers/helpers";
import OwnersTable from "../../components/OwnersTable";
import Spinner from "../../components/Spinner";
import { getCompany } from "../../utils/apis/companiesApi";
import { getMyCompanies } from "../../utils/apis/clientUsersApi";
import { setCompanies } from "../../store/actions/companiesActions";
import plus from "../../assets/images/plus.svg";
import edit from "../../assets/images/edit.svg";
import { initialState } from "./data";
import ModalEdit from "./ModalEdit";
import CompanySelector from "../../components/CompanySelector";
// import StoreCompany from "../StoreCompany";

function EditCompany(props) {
  const history = useHistory();
  const [formData, setFormData] = useState({ ...initialState });
  const [selectedCompany, setSelectedCompany] = useState(
    props.companies.companies
  );
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState(true);
  const [loadingCompanyData, setLoadingCompanyData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const companies = useSelector((state) => state.companies);

  const numberFormat = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  });

  const numberFormatUF = new Intl.NumberFormat("es-CL");

  const openModal = () => {
    setShowModal(true);
  };
  const getMainCompanyData = async (id) => {
    try {
      setLoadingCompanyData(true);
      setFormData({ ...initialState });
      const res = await getCompany(id);

      if (!res) {
        setShowData(false);
      }
      setFormData({ ...res });
      setLoadingCompanyData(false);
    } catch (error) {
      setFormData({ ...initialState });
    }
  };

  const showCompanys = async () => {
    try {
      const { ClientId } = props.user.dataUser;
      const companies = await getMyCompanies(ClientId);
      setData(companies);
    } catch (error) {}
  };

  useEffect(async () => {
    showCompanys();
  }, []);

  useEffect(async () => {
    const { companies } = props.companies;
    if (companies.length === 0) history.push("/storeCompany");
  }, []);

  useEffect(async () => {
    props.companies.companies.map((e)=>{
      getMainCompanyData(e.CompanyId);
      setSelectedCompany(e);
    })
    
  }, [props.companies.companies]);

  return (
    <article className="edit-company-screen screen__container">
      <div className="company_edit_contenedor">
        <div className="container__e-company_form_container">
          {showData ? (
            <div
              className="e-company_form_container" // onSubmit={handlerSubmit}
            >
              <div className="container__data__company">
                <div className="data__company">
                  <p className="name__company">
                    <div className="container__select__company">
                      <CompanySelector />
                      <button
                        className="button__add__newCompany"
                        onClick={() => history.push("/storeCompany")}
                      >
                        <img src={plus}></img>
                      </button>
                      <button className="data__member" onClick={openModal}>
                        <img src={edit}></img>{" "}
                      </button>
                    </div>
                  </p>
                  {/* <p className="state__company">Activa</p> */}
                </div>
              </div>
              <div className="container__data">
                  <p>
                    <strong>Rut:</strong> {formData.Rut}
                  </p>
                  <p>
                    <strong>Dirección:</strong>{" "}
                    <span>{formData.Address}</span>{" "}
                    {formData.NumberAddress},{" "}
                    {formData.Comuna}
                    {formData.OfficeNumber ? (
                      <span>
                        , Oficina {formData.OfficeNumber}
                      </span>
                    ) : null}
                  </p>
                  <p>
                    <strong>Contacto:</strong>{" "}
                    {formData.Phone || <Spinner />}
                  </p>
                  <p>
                    <strong>Correo:</strong> {formData.Email}
                  </p>
                  <p>
                    <strong>Región:</strong> {formData.Region}
                  </p>
                  <p>
                    <strong>Giro:</strong> {formData.Giro}
                  </p>
                </div>
            
          
            </div>
          ) : (
            <p className="not__company">USTED NO POSEE EMPRESAS</p>
          )}
          {showData ? (
            <div className="e-company_cards_container">
              <div className="company_edit_cards">
                <h4>Linea certificados de fianza</h4>
                <p>
                  Aprobado{" "}
                  <strong>{numberFormat.format(formData.LineaAprobada)}</strong>
                </p>
                <p>
                  Disponible{" "}
                  <strong>
                    {numberFormat.format(formData.LineaDisponible)}
                  </strong>
                </p>
                <p>
                  Utilizado{" "}
                  <strong>{numberFormat.format(formData.LineaUsada)}</strong>
                </p>
              </div>
              <div className="company_edit_cards mt-4">
                <h4>Linea certificados de Pólizas</h4>
                <p>
                  Aprobado UF{" "}
                  <strong>
                    {numberFormatUF.format(formData.LineaAprobadaUF)}
                  </strong>
                </p>
                <p>
                  Utilizado{" "}
                  <strong>
                    {numberFormatUF.format(formData.LineaUsadaUF)}
                  </strong>
                </p>
              </div>
            </div>
          ) : null}
        </div>

        {showData ? (
          <div className="e-company_owners_table__container">
            <OwnersTable selectedCompany={selectedCompany} {...props} />
          </div>
        ) : null}
      </div>
      {showModal ? (
        <ModalEdit
          companies={companies}
          setShowModal={setShowModal}
          formData={formData}
          props={props}
          setFormData={setFormData}
        />
      ) : (
        false
      )}
    </article>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  companies: state.companies,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanies: (payload) => dispatch(setCompanies(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);
