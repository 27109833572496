import "./style.css";
import React, { useState, useEffect } from "react";
import executive from "../../assets/images/executive.svg";
import mail from "../../assets/images/mail.svg";
import { connect } from "react-redux";
import executivephone from "../../assets/images/executivephone.svg";
import { getCompany, postExecutive } from "../../utils/apis/companiesApi";
import CompanySelector from "../../components/CompanySelector";
import Spinner from "../../components/Spinner";
import { NavLink } from "react-router-dom";
import { assignExecutive, executiveByRut } from "../../utils/apis/Executive";


function MyExecutive(props) {
  const [formData, setFormData] = useState({});
  const [ejecutive, setExecutive] = useState({});
  const [executiveData, setExecutiveData] = useState(false);
  const [reload, setReload] = useState(false);
  const [nameExecutive, setnameExecutive] = useState("");
  const { companies } = props;
  const { Name, CompanyId, Email, Phone } = formData;


  const getMyExecutive = async (id) => {
    try {
      setReload(true)
      // const res = await getCompany(id);
      const res = await executiveByRut(id);
      // console.log(res)
      setFormData({ ...res, res });
      setExecutiveData(true);
      setReload(false);
    } catch (error) {
      console.error(error);
      setFormData({});
    }
  };

  const addExecutive = async (id) => {
    try {
      await postExecutive(id);
    } catch (error) {
      console.error(error);
    }
  };
  //si existe una empresa o no
  useEffect(() => {
    if (companies.defaultCompany == undefined) {
      setExecutiveData(false);
    } else {
      getMyExecutive(props.companies.defaultCompany.CompanyRut);
      setExecutiveData(true);
    }
  }, [props.companies.defaultCompany]);


  // console.log(props.companies.defaultCompany.CompanyId)

  //si existe un ejecutivo o no
  useEffect(() => {
    setReload(false);
    if (Name == "Sin ejecutivo") {
      addExecutive(props.companies.defaultCompany.CompanyId);
      getMyExecutive(props.companies.defaultCompany.CompanyRut);
      setReload(true);
    }
  }, [Name]);

  // console.log(formData);
  // // console.log(res);
  // // console.log(res.ExecutiveName);
  // console.log(reload);

  return (
    <article className="screen__container__executive">
      {executiveData ? (
        <section className="e-company_main_container">

          <article className="container_all_info  mt-5">
            <section className="container__company__selector">
              <p>
                Selecciona tu empresa para ver los datos del ejecutivo
                asociado:
              </p>
              <CompanySelector setReload={setReload} />
            </section>

            <div className="myexecutive">
              <div className="container__executive">
                <p>Datos de mi ejecutivo:</p>
              </div>
              {
                reload ?
                  <div className="pt-5 d-flex align-items-center flex-column justify-content-center text-muted">
                    <p className="mb-0">Se esta asignando un Ejecutivo</p>
                    <p>Espere un momento...</p>
                    <Spinner />
                  </div> :
                  <section className="container__info__all__exe mt-4">
                    <div className="container__info__exe ">
                      <img src={executive} alt="myexecutive"></img>
                      <strong>{Name} </strong>
                    </div>
                    <div className="container__info__exe my-3">
                      <img className="message" src={mail} alt="myexecutive"></img>
                      <p> {Email} </p>
                    </div>
                    <div className="container__info__exe">
                      <img
                        className="phone"
                        src={executivephone}
                        alt="myexecutive"
                      ></img>
                      <p>{Phone} </p>

                    </div>


                  </section>
              }
              <div className="text__executive">
                <p>"Comprometidos en ayudar en todo el proceso"</p>
              </div>
            </div>
          </article>
        </section>
      ) : (
        <section className="ej_asig">
          <p>
            Para asignar un ejecutivo primero debes
            <NavLink to="/storeCompany">
              <strong> agregar una empresa</strong>
            </NavLink>
          </p>
        </section>
      )}
    </article>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  companies: state.companies,
});

export default connect(mapStateToProps)(MyExecutive);
