import { LOGIN, LOGOUT, SET_USER } from "../constants/userConstants";

const INITIAL_STATE = {
  isAuth: false,
  token: null,
  dataUser: {},
};

export default function userReducer(state = { ...INITIAL_STATE }, action) {
  switch (action.type) {
    case LOGIN: {
      const { Token, ...dataUser } = action.payload;
      return {
        ...state,
        token: Token,
        dataUser,
        isAuth: true,
      };
    }
    case SET_USER: {
      const { Token, ...dataUser } = action.payload;
      return {
        isAuth: true,
        dataUser,
        token: Token,
      };
    }
    case LOGOUT:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
}
