import requestBaseMethod from './baseApi';

export const getOwners = (companyId) => {
  const url = `Companies/${companyId}/Owners`;
  return requestBaseMethod(url, 'GET');
};
export const addOwner = (data) => {
  const url = 'Companies/Owner';
  return requestBaseMethod(url, 'POST', data);
};
export const deleteOwner = (OwnerId) => {
  const url = `Companies/Owner?OwnerId=${OwnerId}`;
  return requestBaseMethod(url, 'DELETE');
};
