import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useState, useEffect } from "react"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Graphic() {
  const [dataChart, setDataChart] = useState({
    datasets: [],
  });

  const [optionsChart, setOptionsChart] = useState({});

useEffect(() => {
  setDataChart({
    labels: [
      "Marzo 2021",
      "Abril 2021",
      "Mayo 2021",
      "Junio 2021",
      "Julio 2021",
      "Agosto 2021",
      "Septiembre 2021",
      "Octubre 2021",
      "Noviembre 2021",
      "Diciembre 2021",
      "Enero 2022",
      "Febrero 2022",
      "Marzo 2022",
      "Abril 2022",
      "Mayo 2022",
      "Junio 2022",
      "Julio 2022",
    ],

    datasets: [
        {
            label: "Venta de Garantías",
            data: ["2","8","105","265","484","717","1070","1413","1720","1974","2218","2456","2904","3353","3829","4304","4676"],
            borderColor: "black",
            backgroundColor:"#0099FF",

        }
    ]
  });

  setOptionsChart({
    responsive: true,
    plugins: {
        legend: {
            position: "top"
        },
        title: {
            display: true,
            text: " "
        }
    }
  })
}, []);

  return <Bar options={optionsChart} data={dataChart} />;
};
