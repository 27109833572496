import React from 'react';
import './styles.css';
import { Box, Button, Modal, Typography } from '@mui/material';
import { ConctactWsp } from '../../screens/Home/components/Contact/ContactWsp';

function WhatsappButton() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  return (
    <>
      <button
        className="whatsapp-button-styles"
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConctactWsp />
      </Modal>
    </>


  );
}

export default WhatsappButton;
