import React, { useState } from "react";
import "./style.css";
import PasswordChange from "../../components/PasswordChange";
import { connect } from "react-redux";

function NewPassword({ user: { dataUser: userData }}) {
  const [formData, setFormData] = useState({
    userData: userData.ClientId,
  });
console.log('formData',formData)

  return (
    <div className="forgot__password__screen__container">
      <div className="form__new__password">
        <h2 className="forgotPassword__title">Ingresa tu nueva contraseña</h2>
        {
          <PasswordChange
            userData={userData}
            formData={formData}
            setFormData={setFormData}
          />
        }
      </div>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(NewPassword);
