//IMPORTACIONES
import React, { useEffect, useState } from "react";
import InputForm from "../../components/InputForm";
import InputPassword from "../../components/InputForm/InputPassword";
import InputRut from "../../components/InputForm/InputRut";
import InputSelect from "../../components/InputSelect";
import { initialOption, options } from "./data";
import { checkIncompleteFields } from "../../utils/helpers/helpers";
import { ProgressBar } from "react-bootstrap";
import { InputAdornment, TextField } from "@mui/material";

export default function DataClient({ formData, setFormData, setPage, page }) {
  //VARIABLES DE ESTADO Y HOOKS
  const FormText = [
    "Con nosotros obtendrás lo que necesites para tu licitación de forma rápida y online!",
  ];
  const [selectedOption, setSelectedOption] = useState(options);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [unmatching, setUnmatching] = useState(false);
  const [validateRut, setValidateRut] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);


  //VALIDA CAMPOS OBLIGATORIOS EN EL FORM
  const validateFields = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      [
        "name",
        "lastName",
        "email",
        "phone",
        "rut",
        "password",
        "repeatPassword",
        "acceptCheck",
      ],
      formData
    );
    setIncompleteFields(incompleteFieldsArray);
    if (formData.password != formData.repeatPassword) {
      setUnmatching(true);
    }
  };

  //VALIDA EL BOTÓN DE CONTINUAR
  const validateButton = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      [
        "name",
        "lastName",
        "email",
        "phone",
        "rut",
        "password",
        "repeatPassword",
        "acceptCheck",
      ],
      formData
    );
    if (formData.password != formData.repeatPassword) {
      setSubmitButtonEnabled(false);
    } else {
      if (incompleteFieldsArray.length > 0) setSubmitButtonEnabled(false);
      else setSubmitButtonEnabled(true);
    }
  };

  //GUARDA EL VALOR AGREGADO A LOS INPUTS
  const handlerInput = (event) => {
    const name = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    localStorage.setItem("data register", JSON.stringify(formData));
  };

  const inputHandlerFono = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const regex = /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/;

    if (!value || regex.test(value) === false) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  //GUARDA EL VALOR DEL CHECK
  const handlerCheck = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //HOOK DE EFECTO PARA VALIDAR BUTTON Y QUE SE INICIALICE EN LA DATA
  useEffect(() => {
    validateButton();
  }, [formData]);

  return (
    <section className="register__container">
      <div className="container-wizzard">
        <div className="text-wizzard">
          <p>Registro</p>
          <p>+Empresa</p>
          <p>+Integrante</p>
        </div>
        <div className="wizzard">
          <ProgressBar now={0} />
        </div>
      </div>
      <section className="contact__container__user">
        <form className="form__register__data">
          <h2 className="register__title">¡BIENVENIDO!</h2>
          <p className="text-register-title">
            Con nosotros obtendrás lo que necesites
            <p>para tu licitación de forma rápida y online</p>
          </p>
          <div className="form-contact__form">
            <div className="form-contact__inputs">
              <InputForm
                type="text"
                name="name"
                label="Nombre*"
                value={formData?.name}
                handlerChange={handlerInput}
                className="mb-2"
                error={incompleteFields.includes("name")}
              />

              <InputForm
                type="text"
                name="lastName"
                label="Apellido*"
                className="mb-2"
                placeholder="Perez"
                value={formData?.lastName}
                handlerChange={handlerInput}
                error={incompleteFields.includes("lastName")}
              />
              <InputForm
                type="email"
                name="email"
                label="Email*"
                className="mb-2"
                placeholder="nombre@inge.cl"
                value={formData?.email}
                handlerChange={handlerInput}
                error={incompleteFields.includes("email")}
              />
              <InputRut
                formData={formData}
                setFormData={setFormData}
                validateRut={setValidateRut}
                className="mb-2"
                name="rut"
                label="RUT*"

              />
              <InputSelect
                name="optionValue"
                value={initialOption}
                options={options}
                className="select__person__company mb-2"
                handlerChange={({ target: { value } }) =>
                  setSelectedOption(value)
                }
                defaultValue="Solicitaré como:"
              />
              <TextField
                type="number"
                name="phone"
                className="mb-2"
                value={formData.phone}
                id="outlined-basic"
                label="Teléfono Móvil"
                title="Teléfono Móvil"
                variant="outlined"
                onChange={inputHandlerFono}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+56</InputAdornment>
                  ),
                }}
              />
              {isError && <p className="invalid_phone">Telefono invalido</p>}

              <InputPassword
                label="Contraseña"
                name="password"
                title="Contraseña*"
                placeholder="*******"
                className="mb-2"
                value={formData?.password}
                handlerChange={handlerInput}
                error={incompleteFields.includes("password")}
              />

              <InputPassword
                name="repeatPassword"
                label="Repetir Contraseña*"
                placeholder="*******"
                value={formData?.repeatPassword}
                handlerChange={handlerInput}
                error={incompleteFields.includes("repeatPassword")}
              />
            </div>
            {unmatching && (
              <p className="text__password__unmatching">
                Las contraseñas deben coincidir
              </p>
            )}
            <p className="acept-check">
              <input
                name="acceptCheck"
                value={formData.acceptCheck}
                type="checkbox"
                onClick={(e) => {
                  handlerCheck(e);
                  validateFields();
                }}
              ></input>
              Acepto los
              <a className="checkbox__text__register">

                Términos y Condiciones
              </a>
            </p>
            <div className="container__button__next">
              <button
                disabled={!submitButtonEnabled}
                className="button__blue"
                onClick={
                  selectedOption === "Persona Natural"
                    ? () => setPage(1)
                    : () => setPage(3)
                }
              >
                CONTINUAR
              </button>
            </div>
          </div>
        </form>
      </section>

      <p className="send__login">
        Ya tengo cuenta,<a href="/login">Ingresar</a>
      </p>
    </section>
  );
}
