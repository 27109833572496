/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { connect } from "react-redux";
import "./styles.css";
import { Link, useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import googleSvg from "../../assets/images/google_icon.svg";
import Button from "../Button";
import {
  login,
  register,
  getMyCompanies,
  googleLogIn,
} from "../../utils/apis/clientUsersApi";
import { addToCart, resetCart } from "../../store/actions/shoppingCartActions";
import {
  createNotification,
  getItemsAndTotalPrice,
  updateShoppingCart,
} from "../../utils/helpers/helpers";
import { doLogin } from "../../store/actions/userActions";
import {
  addCompany,
  defaultCompany,
} from "../../store/actions/companiesActions";
import swal from "sweetalert";
import Swal from "sweetalert2";

function Form({
  goTo,
  onClickHandler,
  buttonValue,
  text1,
  text2,
  formData,
  registerForm,
  type,
  submitButtonEnabled,
  selectedOption,
  setRegisterForm,
  setPage,
  setUnmatching,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const setCompaniesInfo = async (userId) => {
    try {
      const res = await getMyCompanies(userId);
      const mainCompany = res.find((item) => item.IsActive === true);
      await props.addCompany(res);
      props.defaultCompany(mainCompany);
    } catch (err) {
      console.error(err);
    }
  };

  const loginUser = async (userData) => {
    props.doLogin(userData);
    setCompaniesInfo(userData.ClientId);
    await updateShoppingCart(userData.ClientId, props.addToCart);
    history.push("/welcome");
    setLoading(false);
  };

  const authenticate = async (data) => {
    try {
      const loginResponse = await login(data);
      loginUser(loginResponse);
      setUnmatching(false);
    } catch (err) {
      console.error(err);
      setUnmatching(true);
    }
    setLoading(false);
  };

  const authenticateWithGoogle = async (googleResponse) => {
    setLoading(true);
    const {
      profileObj: { email },
    } = googleResponse;
    try {
      const loginResponse = await googleLogIn(email);
      loginUser(loginResponse);
    } catch (error) {
      createNotification(
        "error",
        "Si usted no está registrado por favor haga click aquí.",
        "Ha ocurrido un error con su autenticación",
        4000,
        () => {
          history.push("/register");
        }
      );
      console.log(error);
    }
    setLoading(false);
  };

  const registerUser = async (data) => {
    {
      setLoading(false);
    }
    try {
      const res = await register(data);
      
      Swal.fire(
        "Cuenta Registrada!",
        "Ingresa con tu nuevo usuario",
        "success"
      );
      history.push("/login");
    } catch (error) {
      console.log("error", error.response.data.Message);
      switch (error.response.data.Message) {
        case "Ya existe un usuario registrado con ese email":
          swal("Ya existe un usuario registrado con ese email");
          break;
        case "Ya existe una Compañia con ese RUT":
          swal(
            "Ya existe una compañia con ese RUT, no fue posible registrar tu compañia."
          );
          break;
        default:
          swal(
            "Estamos teniendo algunos incovenientes, Por favor inténtelo nuevamente"
          );
          history.push("/newUser");
      }
    }

    setLoading(false);
  };
  const submitHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    setTimeout(() => {
      if (type === "login") authenticate(formData);
      if (type === "register") {
        registerUser(formData);
      }
    }, 1000);
  };

  const responseGoogle = async (response) => {
    console.log(response);
  };

  return (
    <form onSubmit={submitHandler}>
      {props.children}
      <div className="form__buttons">
        <Button
          className="button__blue"
          onClick={onClickHandler}
          type="submit"
          value={buttonValue}
          color="#fff"
          loading={loading}
          disabled={submitButtonEnabled}
        />
        {type === "login" && (
          <GoogleLogin
            clientId="862092864235-p7uaj23lqfbmit8r3870iccr1cn5ne6n.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={authenticateWithGoogle}
            onFailure={responseGoogle}
            render={(renderProps) => (
              <Button
                value="Google"
                className="button__profile__login__google"
                icon={googleSvg}
                clickHandler={renderProps.onClick}
                loading={loading}
              />
            )}
            cookiePolicy="single_host_origin"
          />
        )}
      </div>
      <div className="form_bottom_text">
        <span>{text1}</span>
        <Link to={goTo}>{text2}</Link>
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  companies: state.companies,
  shoppingCart: state.shoppingCart,
});

const mapDispatchToProps = (dispatch) => ({
  doLogin: (payload) => dispatch(doLogin(payload)),
  addCompany: (payload) => dispatch(addCompany(payload)),
  defaultCompany: (payload) => dispatch(defaultCompany(payload)),
  addToCart: (payload) => dispatch(addToCart(payload)),
  resetCart: () => dispatch(resetCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
