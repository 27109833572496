/* eslint-disable eqeqeq */
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeSelectedCompany } from '../../utils/apis/companiesApi';
import { getMyCompanies } from '../../utils/apis/clientUsersApi';
import { setCompanies } from '../../store/actions/companiesActions';
import './styles.css';
import InputSelect from '../InputSelect';

function CompanySelector({
  setSelectedCompany,
  setReload
}) {
  const user = useSelector((state) => state.user);
  const companies = useSelector((state) => state.companies);
  const dispatch = useDispatch();



  const companyOptions = () => (
    companies.companies.map((company) => ({
      value: company.CompanyId,
      label: company.CompanyName,
    }))
  );

  // Cambia la empresa activa del usuario en la db y en la store.
  const selectCompany = async ({ target: { value: companyId } }) => {
    const { companies: companiesList } = companies;
    const { ClientId } = user.dataUser;
    try {
      setReload(true)
      // Agiliza el cambio de compañia para poder reflejar rapidamente en la ui.
      if (setSelectedCompany) {
        setSelectedCompany(companiesList.find((company) => company.CompanyId == companyId));
      }
      // await changeSelectedCompany(ClientId, companyId);
      const clientCompanies = await getMyCompanies(ClientId);
      dispatch(setCompanies(clientCompanies));
      setReload(false)
    } catch (error) {
      console.log(error);
    } 
  };

  return (
    <div className="container text-center">
      {/* {!loading ? ( */}
      <InputSelect
        handlerChange={selectCompany}
        options={companyOptions()}
        defaultValue={companies.defaultCompany && companies.defaultCompany.CompanyName}
      />
      {/* )
      :
      {' '}
      <InputForm readOnly value="Cambiando..." /> */}
    </div>
  );
}

export default CompanySelector;
