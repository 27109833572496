import React from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faInstagram,
  faLinkedin,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhoneSquareAlt,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import logofooter from "../../assets/images/logofooter.png";
import wave from "../../assets/images/footer-wave.svg";
import inge from "../../assets/images/LOGO-INGE-SERVFINANCIEROS-BLANCO-EDDEF2022.png";
import Newsletter from "../Newsletter";
import ReactWhatsapp from "react-whatsapp";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function Footer() {

  const location = useLocation();

  return (
    <footer className="footer__container">
      <div className="footer_wave__container">
        <img src={wave} alt="" />
      </div>
      <section className="footer__content">
        <div className="footer__content_top">
          <div className="footer_section">
            <div className="footer_social">
            {location.pathname === "/formGarantia" || location.pathname === "/formCapitalTrabajo" || location.pathname === "/formFactoring" ?
                <figure className="brand_logo">
                  <img src={inge} alt="logo INGE" />
                </figure> : <figure className="brand_logo">
                  <img src={logofooter} alt="logo INGE" />
                </figure>}
              <div className="social-media__links">
                <FontAwesomeIcon
                  icon={faFacebook}
                  size="l"
                  color="#FFF"
                  onClick={() =>
                    window.open("https://www.facebook.com/inge.latam")
                  }
                  style={{ cursor: "pointer", fontSize: "24px" }}
                />
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="2x"
                  color="#FFF"
                  onClick={() =>
                    window.open("https://www.instagram.com/inge_latam")
                  }
                  style={{ cursor: "pointer", fontSize: "24px" }}
                />
                <FontAwesomeIcon
                  icon={faLinkedin}
                  size="2x"
                  color="#FFF"
                  onClick={() =>
                    window.open("https://www.linkedin.com/company/inge")
                  }
                  style={{ cursor: "pointer", fontSize: "24px" }}
                />
              </div>
            </div>
          </div>
          <div className="footer_section footer_info">
            <h4 className="footer_title info_title">Contacto</h4>
            <ul className="info_list">
              <li className="footer_map">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  size="lg"
                  color="#FFF"
                  style={{ marginRight: 9 }}
                />
                <a href="https://goo.gl/maps/ZWtAJgxAXjf39qvP9">
                  Avenida Presidente Riesco #5561.
                  <br /> Piso 4. Edificio Arrau. Las Condes{" "}
                </a>
              </li>

              <li className="tel__footer">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="lg"
                  color="#FFF"
                  style={{ marginRight: 7 }}
                />
                <span>
                  <a
                    href="mailto:contacto@inge.cl"
                    style={{ color: "white" }}
                  >
                    contacto@inge.cl
                  </a>
                </span>
              </li>
            </ul>
          </div>
          <div className="footer_section footer_info">
            <h4 className="footer_title contact--title"></h4>
            <ul className="info_list">
              <li className="tel__footer">
                <ReactWhatsapp
                  number="56988030351"
                  element="p"
                  style={{ color: "white", cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    size="lg"
                    color="#FFF"
                    style={{ marginRight: 7 }}
                  />
                  +56 9 8803 0351 &nbsp;| Garantía
                </ReactWhatsapp>
              </li>
              <li className="tel__footer">
                <ReactWhatsapp
                  number="56987072683"
                  element="p"
                  style={{ color: "white", cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    size="lg"
                    color="#FFF"
                    style={{ marginRight: 7 }}
                  />
                  +56 9 8707 2683 &nbsp;| Factoring
                </ReactWhatsapp>
              </li>
              <li className="tel__footer">
                <ReactWhatsapp
                  number="56946426930"
                  element="p"
                  style={{ color: "white", cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    size="lg"
                    color="#FFF"
                    style={{ marginRight: 7 }}
                  />
                  +56 9 4642 6930 &nbsp;| Créditos
                </ReactWhatsapp>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__content_bottom">
          <div />
          <span className="social-media__brandname">
            © <strong>Inge! </strong> | 2022 Servicios Financieros
          </span>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
