import React from 'react';
import Spinner from '.';
import './styles.css';

function LoadingScreen({ loadingText, className }) {
  return (
    <div className={`screen_center loading_screen ${className || ''}`}>
      <h1>
        {loadingText || ''}
        {' '}
      </h1>
      <Spinner />
    </div>
  );
}

export default LoadingScreen;
