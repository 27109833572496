import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import MaterialTable from 'material-table';
import tableIcons from './icons';
import Spinner from '../Spinner';

function DataTableWarranty({
  data,
  data2,
  columns,
  options,
  actions,
  stylesContainer,
  emptyMessage,
  rowClickHandler,
  detailPanel,
  innerRef,
  onSearchChange,
  id,
  searchMap
}) {
  const [value, setValue] = useState(data);
  const tableOptions = {
    rowStyle: {
      fontSize: '0.9rem',
      fontWeight: 500,
      maxHeight: '100px',
    },
    headerStyle: {
      backgroundColor: '#E9F2FF',
      color: '#00367b',
      fontWeight: 'bolder',
    },
    actionsColumnIndex: -1,
    showFirstLastPageButtons: false,
  };

  const mapWarranty = (valor) => {
    let garantia;
    let expresion = new RegExp(`${data2}.*`, "i");

    if (valor != undefined) {
      garantia = valor.filter(e => expresion.test(e.DocumentCode))    
      setValue(garantia)
    }
  }

  
  useEffect(() => {
    setValue(data)
    mapWarranty(data)
  }, [data, data2])

  return (
    <section
      className="data-table__container"
      style={{ ...stylesContainer }}
    >
      <MaterialTable
        tableRef={innerRef}
        actions={actions ? [...actions] : null}
        icons={tableIcons}
        columns={columns}
        data={value.length === 0 ? data : value}
        // data={data}
        title="Licitaciones"
        style={{ boxShadow: '0px 5px 10px #0000003B' }}
        options={{
          ...tableOptions,
          ...options
        }}

        data2={mapWarranty()}
        onSearchChange={onSearchChange}
        detailPanel={detailPanel || null}
        onRowClick={(e, rowData) => rowClickHandler && rowClickHandler(rowData)}
        id={id}
        localization={{
          header: {
            actions: '',
            labelRowsPerPage: 'licitaciones por página:',
          },
          body: {
            emptyDataSourceMessage: emptyMessage || <Spinner styles={{ fontSize: '25px' }} />,
            filterRow: {
              filterTooltip: 'Filtro',
            },
          },
          pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'licitaciones por página',
            previousAriaLabel: 'Anterior',
            previousTooltip: 'Anterior',
            nextAriaLabel: 'Siguiente',
            nextTooltip: 'Siguiente',
            firstAriaLabel: 'Primera página',
            firstTooltip: 'Primera página',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página',
          },
          toolbar: {
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Nombre, código, comprador...',
          }
        }}
      />
    </section>
  );
}

export default DataTableWarranty;
