import React from "react";
import "./styles.css"
import { connect } from "react-redux";
import { setCompanies } from "../../store/actions/companiesActions";
import { createNotification } from "../../utils/helpers/helpers";
import { editCompany } from "../../utils/apis/companiesApi";
import { getMyCompanies } from "../../utils/apis/clientUsersApi";
import InputForm from "../../components/InputForm";
import InputRut from "../../components/InputForm/InputRut";

function ModalEdit({formData, setFormData, props,setShowModal}) {


    const inputHandler = (event) => {
        const key = event.target.name;
        const { value } = event.target;
        setFormData({
          ...formData,
          [key]: value,
        });
      };
    

const closeModal = () => {
    setShowModal()
}


console.log('props companies',props)

    const handlerSubmit = async (event) => {
        event.preventDefault();
        const { CompanyId } = formData;
        try {
          await editCompany(formData, CompanyId);
          const { ClientId } = props.user.dataUser;
          const clientCompanies = await getMyCompanies(ClientId);
          console.log('cliente companies',clientCompanies)
          props.setCompanies(clientCompanies);
          createNotification("info", "Compañia editada con éxito");
          setShowModal(false)
        } catch (error) {
          console.log(error);
          createNotification("error", "Ha ocurrido un error al editar");
        }
      };
    
    return(
      <div className="Modal__background">
        <div className="form__background">
        <div className="company_form_inputs">
          <InputForm
            type="text"
            name="Name"
            title="Nombre/Razón Social:"
            placeholder="Nombre/Razón Social"
            value={formData.Name}
            handlerChange={inputHandler}
            className="flex_4"
  
          />
          <InputRut
            name="Rut"
            title="Rut:"
            setFormData={setFormData}
            formData={formData}
            className="flex_2"
          />
          <InputForm
            type="text"
            name="Giro"
            title="Giro:"
            value={formData.Giro}
            handlerChange={inputHandler}
            className="flex_6"
  
          />
        </div>
        <div className="company_form_inputs">
          <InputForm
            type="text"
            name="Address"
            title="Dirección:"
            value={formData.Address}
            handlerChange={inputHandler}
            className="flex_6"
  
          />
          <div className="company_form_inputs company_form_inputs--small flex_3">
            <InputForm
              type="text"
              name="AddressNumber"
              title="Numero:"
              value={formData.AddressNumber}
              handlerChange={inputHandler}
              className="flex_3"
  
            />
            <InputForm
              type="text"
              name="AddressOffice"
              title="Oficina:"
              value={formData.AddressOffice}
              handlerChange={inputHandler}
              className="flex_3"
  
            />
          </div>
        </div>
        <div className="company_form_inputs">
          <InputForm
            type="text"
            name="Region"
            title="Región:"
            value={formData.Region}
            handlerChange={inputHandler}
            className="flex_3"
  
          />
          <InputForm
            type="text"
            name="Location"
            title="Comuna:"
            value={formData.Location}
            handlerChange={inputHandler}
            className="flex_3"
  
          />
          <InputForm
            type="text"
            name="Phone"
            title="Teléfono"
            value={formData.Phone}
            handlerChange={inputHandler}
            className="flex_3"
  
          />
          <InputForm
            type="text"
            name="Email"
            title="Email:"
            value={formData.Email}
            handlerChange={inputHandler}
            className="flex_3"
  
          />
        </div>
        <div className="container__buttons__modalEdit">
  <button className="btn__modal__edit" onClick={closeModal} >Cancelar</button>
<button className="btn__modal__edit" onClick={handlerSubmit}>Aceptar</button>
</div>
      </div>
      </div>
    )
} 


const mapStateToProps = (state) => ({
    user: state.user,
    companies: state.companies,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    setCompanies: (payload) => dispatch(setCompanies(payload)),
  });

  export default connect(mapStateToProps, mapDispatchToProps)(ModalEdit);