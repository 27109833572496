import movilidad3 from "../../assets/images/movilidad3.svg";
import movilidad2 from "../../assets/images/movilidad2.svg";
import movilidad1 from "../../assets/images/movilidad1.svg";

export const dataOptions = {
  contactFormId: 0,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  message: "",
};

export const initialOption = "Selecciona un seguro";

export const options = [
  {
    value: "Seguro de asiento pasajero",
    label: "Seguro de asiento pasajero",
  },
  {
    value: "Vehiculos",
    label: "Vehiculos",
  },
  {
    value: "Seguro de transporte",
    label: "Seguro de transporte",
  },
];

export const initialRequiredFields = [
  "firstName",
  "lastName",
  "email",
  "phone",
  "message",
];

// export const items = [
//   {
//     subtitle: "Seguro de asiento pasajero",
//     img:  <img src={movilidad1} alt="img" ></img>,
//     text: "Su vigencia es anual, y está disponible para todo tipo de vehículos.",
//   },
//   {
//     subtitle: "Vehículos",
//     img:  <img src={movilidad2} alt="img" className="img__two" ></img>,
//     text: <p>Indemniza al conductor cuando sufre algún imprevisto contenidos en su póliza.</p>,
//   },
//   {
//     subtitle: "Seguro de transporte",
//     img:  <img src={movilidad3} alt="img" ></img>,
//     text: "Indemniza los daños sobrevenidos durante transporte terrestre, marítimo o aéreo.",
//   },
// ];
