import React from 'react';
import './styles.css';
import { useHistory } from 'react-router-dom';
import image from '../../assets/images/rocket-welcome.png';


function Welcome() {
  const history = useHistory();
  return (
    <article className="welcome-screen">
      <div className="welcome-screen__container">
        <h1 className="welcome-screen__second-title">
          ¡Bienvenido a bordo!
        </h1>
        <figure>
          <img className='img__welcome' src={image}></img>
        </figure>
        <section className='welcome-screen__third-title'>
          <h2 >
            Estamos encantados de verte.
          </h2>
          <p className='p_fly'>
            Puedes tomar el control total sobre tus garantías,
            asegurando un vuelo seguro hacia el éxito de tu negocio.
          </p>
          <p className='p_fly_2'>
            Si necesitas ayuda o tienes alguna pregunta, nuestro equipo de ejecutivos estará encantado de asistirte.
            ¡Gracias por confiar en nosotros para la gestión de tus garantías!
          </p>
        </section>


        <section className="welcome-screen-buttons">
          <button className='button__welcome__blue'
            onClick={() => history.push("/Quoter")}>
            Simula tu Garantía
          </button>
          <button className='button__welcome__blue'
            onClick={() => history.push("/tenders")}>
            Busca tu Licitación
          </button>
          <button className='button__welcome__blue'
            onClick={() => history.push("/editCompany")}>
            Tu Empresa
          </button>
          <button className='button__welcome__blue'
            onClick={() => history.push("/profile")} >
            Perfil
          </button>
        </section>
      </div>
    </article>

  );
}




export default Welcome;
