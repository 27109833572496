//DATA DEL BLOG
import piggy from "../../../../assets/images/piggy.svg"
import warranty from "../../../../assets/images/warranty.svg"
import credit from "../../../../assets/images/credit.svg"


export const ourProducts = [

  {
    image: <img src={warranty} alt="piggy-bank" />,
    text: "Adquiere documentos para licitaciones o tratos directos",
    url: "/WarrantySimulation",
    header: "Garantías"
  },
  {
    image: <img src={piggy} alt="piggy-bank" />,
    text: "Mejora el flujo de caja de tu empresa con InGe! Factoring",
    url: "/factoring",
    header: "Factoring"
  },
  {
    image: <img className="img__credits" src={credit} alt="piggy-bank" />,
    text: "Obtén el capital de trabajo que necesitas",
    url: "/credit",
    header: "Créditos"
  },

];
