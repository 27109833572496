import React, { useState } from "react";
import Service from "./Service";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Quoter() {
  const [page, setPage] = useState(0);

  const history = useHistory();

  const views = () => {
    if (page === 0) {
      return <Service setPage={setPage} />;
    } else if (page === 1) {
      return history.push("/Credit");
    } else if (page === 2) {
      return history.push("/WarrantySimulation");
    } else if (page === 3) {
      return history.push("/factoring");
    }
  };
  return (
    <div>
      <div>{views()}</div>
    </div>
  );
}
