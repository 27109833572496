import React, { useState } from "react";
import InputForm from "../../components/InputForm";
import InputRut from "../../components/InputForm/InputRut";
import "./style.css";
// import { dataOptions } from "./data";
import { checkIncompleteFields } from "../../utils/helpers/helpers";
import { sendFormCredits } from "../../utils/apis/quotation";
import { useHistory } from "react-router-dom";
import { createNotification } from "../../utils/helpers/helpers";
import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { dataOptions } from "../Factoring/data";
import { InputAdornment, TextField } from "@mui/material";
import Spinner from "../../components/Spinner";

export default function Credit() {
  const history = useHistory();
  const [formData, setFormData] = useState({ ...dataOptions });
  const [loading, setLoading] = useState(false)
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [validateRut, setValidateRut] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);



  const handlerOnChange = (value) => {
    // console.log("captcha value", value);
  };

  //VALIDANDO BOTÓN DE ENVIAR
  const validateButton = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["firstName", "rutProveedor", "email", "phone","factura" ],
      formData
    );
    console.log("campos vacios", incompleteFieldsArray);
    if (incompleteFieldsArray.length == 0 && validateRut && !isError) {
      setSubmitButtonEnabled(false);

    } else {
      setSubmitButtonEnabled(true);
    }
  };
  useEffect(() => {
    validateButton();
  }, [formData]);

  const inputHandler = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const inputHandlerFono = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const regex = /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/;

    if (!value || regex.test(value) === false) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const inputHandlerEmail = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!value || regex.test(value) === false) {
      setIsErrorEmail(true);
    } else {
      setIsErrorEmail(false);
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      setLoading(true)
      await sendFormCredits(formData);
  
      history.push("/infoReceived");
    } catch (error) {
      createNotification(
        "error",
        "Por favor intentelo más tarde.",
        "Ha ocurrido un error al enviar su formulario"
      );
      setLoading(false)
    }
  };
  
  return (
    <div className="container-credits">
      <div className="me-5 capital_trabajo">
        <h3>Capital de Trabajo para empresas</h3>
        <p>
          El financiamiento para <strong>Capital de Trabajo InGe! </strong>
          es un apoyo monetario que podrás solicitar, asesorado por nosotros,
          sin dejar algún bien como respaldo. Te asesoramos para que el proceso
          de solicitud sea exitoso y así alcances el objetivo que tanto deseas.
          Somos expertos en el tema y contamos con un grupo de partners que se
          encargan de entregar los fondos necesarios para este financiamiento.
        </p>
        <h3>¿Por qué solicitar Capital de Trabajo InGe?</h3>
        <p>
          <ol>
            <li>Estabilizas el flujo de efectivo de tu empresa</li>
            <li>
              No requiere <strong>garantías</strong>
            </li>
            <li>Liquidez inmediata</li>
            <li>Sin cobros por adelantado</li>
            <li>Asesoría de expertos en el tema</li>
          </ol>
        </p>
        <p>
          Con nuestra gestión podrás alcanzar el financiamiento desde el 30%
          hasta el 50% del promedio de tus últimos seis meses de venta (con un
          tope máximo de $50.000.000 millones de pesos).
        </p>
        <p className="">
          <strong>Contáctanos</strong> y recibe más información sobre esta
          alternativa de financiamiento que te dará la liquidez inmediata que
          necesitas.
        </p>
      </div>
      <section className="credits__forms">
        <form className="form-factoring-credits float-end">
          <GoogleReCaptchaProvider reCaptchaKey="6LdW_9khAAAAAEGx6saxoqhGp0rqrJAGrGRJVUuo">
            <div className="form-security__form">
              <div className="form-security__inputs">
                <div className="info-contact">
                  <h5>Información de Contacto</h5>
                  <p>
                    Necesitamos estos datos para darte la cotización que
                    necesitas, ¿me los puedes Compartir?
                  </p>
                </div>
                <InputForm
                  type="text"
                  value={formData.firstName}
                  name="firstName"
                  label="Nombre Completo*"
                  className=""
                  handlerChange={inputHandler}
                  error={incompleteFields.includes("firstName")}
                />
                <InputForm
                  type="number"
                  value={formData.factura}
                  name="factura"
                  label="Monto promedio mensual de ventas"
                  className=" mt-4"
                  handlerChange={inputHandler}
                  error={incompleteFields.includes("firstName")}
                />
                <InputRut
                  formData={formData}
                  setFormData={setFormData}
                  validateRut={setValidateRut}
                  name="rutProveedor"
                  label="Rut Empresa*"
                  value={formData.rutProveedor}
                  className="input__rut__factoring my-4"
                />
                <InputForm
                  type="email"
                  name="email"
                  label="Email"
                  value={formData.email}
                  handlerChange={inputHandlerEmail}
                />
                {isErrorEmail && <p className="invalid_phone">Email invalido</p>}
                <TextField
                  type="number"
                  name="phone"
                  className="mt-4"
                  value={formData.phone}
                  id="outlined-basic"
                  label="Teléfono Móvil"
                  title="Teléfono Móvil"
                  variant="outlined"
                  onChange={inputHandlerFono}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+56</InputAdornment>
                    ),
                  }}
                />

                {isError && <p className="invalid_phone">Telefono invalido</p>}
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="button__blue w-75"
                  onClick={submitHandler}
                  disabled={submitButtonEnabled}
                >
                  {loading ? (
                    <Spinner />
                  ) : (
                    <p className="m-0"> Cotizar</p>
                  )}

                </button>
              </div>
            </div>
          </GoogleReCaptchaProvider>
        </form>
      </section>
    </div>
  );
}
