import React, { useState } from "react";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import rocket from "../../../../assets/images/rocket.png";
import imgSlide2 from "../../../../assets/images/imgSlide2.png";
import newHeader from "../../../../assets/images/newHeader.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Contact from "../Contact";
import { ContactHeader } from "../Contact/ContactHeader";
import img from "../../../../assets/images/10.svg"

export default function CarouselInitial() {
  const [initial, setInitial] = useState(0);

  const handleSelect = () => {
    setInitial(initial);
  };
  const history = useHistory();
  return (
    <article className="bg-main">
      <div className="big-container">

        <section className="contenedor-main">
          <img src={img} />
          {/* <div className="content-img-1">
            <h1 className="subtitle-img-1">
              Es el momento, cumple tus <strong>proyectos</strong>
            </h1> 
             <div className="">
              <p className="text-img-1-end">
                Para que lo logres tenemos para ti: garantías, capital de trabajo y factoring
              </p>
            </div> 
          </div> */}
          <ContactHeader />
        </section>


      </div>

    </article>

  );
}
