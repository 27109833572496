/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  getFormatedDate,
  checkIncompleteFields,
} from "../../utils/helpers/helpers";
import InputForm from "../InputForm";
import { useHistory } from "react-router-dom";
import "./styles.css";
import TendersTable from "../tendersTable";
import SimulationInitial from "./SimulationInitial";
import { validateTenders } from "../../utils/apis/tendersApi";
import CompanySelector from "../CompanySelector";
import { simulate } from "../../utils/apis/warrantiesApi";
import ModalLoading from "./ModalLoading";
import ModalError from "./ModalError";
import ModalSummary from "./ModalSummary";
import InputSelect from "../InputSelect";
import Spinner from "../Spinner";
import { Modal } from "@mui/material";

const requiredFields = [
  "tenderCode",
  "tenderBuyerName",
  "tenderBuyerRut",
  "glosa",
  "tenderBuyerAddress3",
  "tenderBuyerAddress2",
  "tenderbuyerAddress",
  "tenderWarrantyTarget",
  "tenderAmountString",
  "startDate",
  "endDate",
];

export const options = [
  {
    value: "-1",
    nameValue: "Seriedad de oferta",
    label: "Seriedad de oferta",
  },
  {
    value: "1",
    nameValue: "Fiel cumplimiento",
    label: "Fiel cumplimiento",
  },
  {
    value: "2",
    nameValue: "Correcta ejecución",
    label: "Correcta ejecución",
  },
  {
    value: "3",
    nameValue: "Fiel cumplimiento y correcta ejecución",
    label: "Fiel cumplimiento y correcta ejecución",
  },
  {
    value: "4",
    nameValue: "Correcta ejecución y buen comportamiento de la obra",
    label: "Correcta ejecución y buen comportamiento de la obra",
  },
];

function SimpleSimulationSO({ user: { dataUser, isAuth }, warrantyType }) {
  const [formatedNumber, setFormatedNumber] = useState();
  const user = useSelector((state) => state.user.dataUser);
  const userCompanies = useSelector((state) => state.companies);
  const [todaysDate, setTodaysDate] = useState();
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);
  const [codeLicitacion, setCodeLicitacion] = useState(false)
  const [tenders, setTenders] = useState({});
  const [response, setResponse] = useState({});

  let initialState = {
    companyId: userCompanies.defaultCompany?.CompanyId,
    tenderId: tenders.res?.TenderId,
    tenderMoneyUnit: tenders.res?.Currency,
    tenderAmountString: "",
    tenderBuyerName: tenders.res?.TenderBuyer?.Name,
    tenderBuyerRut: tenders.res?.TenderBuyer?.Rut,
    tenderBuyerAddress2: tenders.res?.TenderBuyer?.UnitLocation,
    tenderBuyerAddress3: tenders.res?.TenderBuyer?.UnitArea,
    tenderbuyerAddress: tenders.res?.TenderBuyer?.UnitAddress,
    companyRut: userCompanies.defaultCompany?.CompanyRut,
    companyName: userCompanies.defaultCompany?.CompanyName,
    glosa: "",
    tenderWarrantyTarget: warrantyType,
    startDate: "",
    endDate: "",
    insuranceCompanyId: 0,
    lineTypeId: 0,
    contactName: user.ClientName,
    contactEmail: user.Email,
    contactPhone: user.Phone,
    contactRut: user.Rut,
    tenderCode: tenders.resp?.Code
  };

  let warrantyTypeName = options.find(
    (w) => w.value === warrantyType
  ).nameValue;

  const [dataTenders, setDataTenders] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [summary, setSummary] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const { tenderCode } = dataTenders

  const inputHandler = (e) => {

    const key = e.target.name;
    const { value } = e.target;
    setIncompleteFields(incompleteFields.filter((field) => field !== key));
    setDataTenders({
      ...dataTenders,
      [key]: value,
    });

    if (key === "companyId") {
      const selectedCompany = userCompanies.companies.find(
        (c) => c.CompanyId === parseInt(value)
      );
      setDataTenders({
        ...dataTenders,
        companyRut: selectedCompany.CompanyRut,
        companyName: selectedCompany.CompanyName,
      });
    }
  };

  const validateButton = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["tenderBuyerName", "glosa", "tenderAmountString", "startDate", "endDate"],
      dataTenders
    );
    if (incompleteFieldsArray.length > 0) {
      setSubmitButtonEnabled(false);
    } else {
      setSubmitButtonEnabled(true);
    }
  };


  useEffect(() => {
    validateButton();
  }, [dataTenders]);


  const inpuBlurHandler = (e) => {
    showTenderDetails(dataTenders);
  };

  const userCompaniesOptions = () =>
    userCompanies.companies.map((company) => ({
      value: company.CompanyId,
      label: company.CompanyName,
    }));

  const showTenderDetails = async (e) => {

    const key = e.target.name;
    const { value } = e.target;
    setIncompleteFields(incompleteFields.filter((field) => field !== key));
    setDataTenders({
      ...dataTenders,
      [key]: value,
    });

    setCodeLicitacion(true)
    await validateTenders(value).then((resp) => {
      setTenders({ resp });
      setDataTenders({
        ...dataTenders,
        tenderCode: resp?.Code ?? "",
        tenderBuyerName: resp?.TenderBuyer?.Name ?? "",
        tenderBuyerRut: resp?.TenderBuyer?.Rut ?? "",
        tenderbuyerAddress: resp?.TenderBuyer?.UnitAddress ?? "",
        tenderBuyerAddress2: resp?.TenderBuyer?.UnitLocation ?? "",
        tenderBuyerAddress3: resp?.TenderBuyer?.UnitArea ?? "",
      });
      setCodeLicitacion(false)
    }).catch(error => {
      setCodeLicitacion(false)
      setDataTenders({
        ...dataTenders,
        TenderBuyerCode: "",
        tenderBuyerName: "",
        tenderBuyerRut: "",
        tenderbuyerAddress: "",
        tenderBuyerAddress2: "",
        tenderBuyerAddress3: "",
      });
    })
  };

  const simulateWarranties = async () => {
    const incompleteFieldsArray = checkIncompleteFields(
      requiredFields,
      dataTenders
    );
    setIncompleteFields(incompleteFieldsArray);
    if (incompleteFieldsArray.length > 0) {
      return;
    }

    try {
      setLoading(true);
      const res = await simulate(dataTenders);
      const load = setTimeout(() => {
        setSummary(true);
        setLoading(false);
      }, 3000);
      setResponse(res);
      const setTenderWarrantyTarget = () => {
        switch (res.TenderWarrantyTarget) {
          case "-1":
            return "Seriedad de oferta";
          case "1":
            return "Fiel cumplimiento";
          case "2":
            return "Correcta ejecución";
          case "3":
            return "Fiel cumplimiento y correcta ejecución";
          case "4":
            return "Correcta ejecución y buen comportamiento de la obra";
          default:
            return " ";
        }
      };
    } catch (err) {
      setError(true);
      setLoading(false);
      console.error(err);
    }
  };
  const inputHandlerDate = ({ target: { name, value } }) => {
    setIncompleteFields((fields) => fields.filter((field) => field !== name));
    setDataTenders((d) => ({ ...d, [name]: value }));
  };

  useEffect(() => {
    const { year, month, day } = getFormatedDate();
    setTodaysDate(`${year}-${month}-${day}`);
  }, []);

  return (

    <article className="principal__tenders">
      <div className="d-flex">
        {summary ? (

          <ModalSummary
            response={response}
            setSummary={setSummary}
            dataUser={dataUser}
          />
        ) : null}

      </div>
      <section className="container__text__warrantys">
        <h5>Información de contacto</h5>
        <p>
          Necesitamos estos datos para darte la cotización que necesitas, ¿Me
          los puedes compartir?
        </p>
      </section>
      <section className="container__simulation__form">
        <form className=" simulation__form__login">
          {isAuth ? (
            <div className="simulation__form__inputs-container">
              <div className="container__companies  px-5">

                <InputSelect
                  name="companyId"
                  options={userCompaniesOptions()}
                  defaultValue={
                    userCompanies.defaultCompany &&
                    userCompanies.defaultCompany.CompanyName
                  }
                  className="mb-4"
                  handlerChange={inputHandler}
                />
              </div>
              {loading ? <ModalLoading /> : null}
              {error ? <ModalError setError={setError} /> : null}

              <div className=" mb-4 d-flex flex-column px-5">
                <InputForm
                  type="text"
                  name="tenderCode"
                  title="Codigo de licitación"
                  value={dataTenders?.TenderCode}
                  handlerKeyDown={showTenderDetails}
                  className="flex_2"
                  error={incompleteFields.includes("tenderCode")}
                />
                <div className="container__lic">
                  <p>*Debes completar primero este campo para habilitar los demas</p>
                </div>
                <InputForm
                  type="text"
                  name="tenderBuyerName"
                  title="Nombre mandante"
                  value={dataTenders?.tenderBuyerName}
                  handlerChange={inputHandler}
                  className="flex_2"
                  error={incompleteFields.includes("tenderBuyerName")}
                />
                <InputForm
                  type="text"
                  name="tenderBuyerRut"
                  title="Rut"
                  value={dataTenders?.tenderBuyerRut}
                  handlerChange={inputHandler}
                  className="flex_2"
                  error={incompleteFields.includes("tenderBuyerRut")}
                />
                <InputForm
                  type="text"
                  name="glosa"
                  title="Glosa:"
                  value={dataTenders?.glosa}
                  handlerChange={inputHandler}
                  className="flex_4"
                  error={incompleteFields.includes("glosa")}
                />

                <InputForm
                  type="text"
                  name="tenderBuyerAddress3"
                  title="Región Empresa Mandante"
                  value={dataTenders?.tenderBuyerAddress3}
                  handlerChange={inputHandler}
                  className="flex_2"
                  error={incompleteFields.includes("tenderBuyerAddress3")}
                />

                <InputForm
                  type="text"
                  name="tenderBuyerAddress2"
                  title="Comuna Empresa Mandante"
                  value={dataTenders?.tenderBuyerAddress2}
                  handlerChange={inputHandler}
                  className="flex_2"
                  error={incompleteFields.includes("tenderBuyerAddress2")}
                />
                <InputForm
                  type="text"
                  name="tenderbuyerAddress"
                  title="Dirección Empresa Mandante"
                  value={dataTenders?.tenderbuyerAddress}
                  handlerChange={inputHandler}
                  className="flex_2"
                  error={incompleteFields.includes("tenderbuyerAddress")}
                />

              </div>
              <section className=" d-flex flex-column px-5">
                <InputForm
                  name="tenderWarrantyTarget"
                  title="Finalidad"
                  value={warrantyTypeName}
                  className="mb-3"
                  readOnly
                />
                <InputForm
                  type="number"
                  name="tenderAmountString"
                  title="Monto de garantía"
                  value={dataTenders?.tenderAmountString}
                  handlerChange={inputHandlerDate}
                  className="mb-3"
                  error={incompleteFields.includes("tenderAmountString")}

                />
                <InputForm
                  title="Fecha de inicio"
                  type="date"
                  min={todaysDate}
                  name="startDate"
                  className="mb-3"
                  value={dataTenders.startDate || ""}
                  handlerChange={inputHandlerDate}
                  error={incompleteFields.includes("startDate")}
                />
                <InputForm
                  title="Fecha de termino"
                  type="date"
                  min={todaysDate}
                  name="endDate"
                  value={dataTenders.endDate || ""}
                  handlerChange={inputHandlerDate}
                  error={incompleteFields.includes("endDate")}
                />
              </section>
              <div className="w-100">
                <button
                  className="button__blue mt-4 float-end"
                  type="button"
                  disabled={!submitButtonEnabled}

                  onClick={() => simulateWarranties()}
                >
                  {loading ? (
                    <Spinner />
                  ) : (
                    <p className="m-0">Continuar</p>
                  )}
                </button>
              </div>
              <div className="tenders__form__button"></div>
            </div>
          ) : (
            <SimulationInitial
              incompleteFields={incompleteFields}
              formatedNumber={formatedNumber}
              options={options}
              todaysDate={todaysDate}
            />
          )}
        </form>
      </section>
      <section className="container__search">
        <strong>
          <p>¿Necesitas más información?</p>
        </strong>
        <p>Usa nuestro buscador</p>
        <TendersTable />
      </section>
      {isAuth ? (
        <div className="container__search">
          <strong>
            <p>¿Necesitas más información?</p>
          </strong>
          <p>Usa nuestro buscador</p>
          <TendersTable />
        </div>
      ) : (
        <div className="container__auto">
          <p>Auto atiendete</p>{" "}
          <p>
            <strong>
              <a href="/login">Iniciando Sesión</a>
            </strong>
          </p>
        </div>
      )}
    </article>
  );
}

const mapStateToProps = ({ user, companies }) => ({ user, companies });

export default connect(mapStateToProps)(SimpleSimulationSO);
