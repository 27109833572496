import "./styles.css";
import React, { useState } from "react";
import { connect } from "react-redux";
import InputForm from "../InputForm";
import { sendSimulation } from "../../utils/apis/warrantiesApi";
import { updateShoppingCart } from "../../utils/helpers/helpers";
import { createNotification } from "../../utils/helpers/helpers";
import { useHistory } from "react-router-dom";
import { addToCart } from "../../store/actions/shoppingCartActions";
import ModalErrorExecutive from "./ModalErrorExecutive";
import Spinner from "../Spinner";
import ModalLoading from "./ModalLoading";
import Modal from '@mui/material/Modal';
import logoInge from '../../assets/images/logoInge.svg'

function ModalSummary({ response, setSummary, dataUser, ...props }) {
  const [active, setActive] = useState(false);
  const [modalError, setModalError] = useState(false);
    const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
    const handleClose = () => {
    history.push("/cart")
    setOpen(false)};
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const numberFormat = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  });

  const setTenderWarrantyTarget = (value) => {
    switch (value) {
      case "-1":
        return "Seriedad de oferta";
      case "1":
        return "Fiel cumplimiento";
      case "2":
        return "Correcta ejecución";
      case "3":
        return "Fiel cumplimiento y correcta ejecución";
      case "4":
        return "Correcta ejecución y buen comportamiento de la obra";
      default:
        return " ";
    }
  };
  const changeCheck = (e) => {
    const value = e.target.value;
    setActive(!active);
  };

  const sendSimulationWarranties = async () => {
    try {
      const warrantyTarget = setTenderWarrantyTarget(
        response.TenderWarrantyTarget
      );
      const request = {
        ...response,
        TenderWarrantyTarget: warrantyTarget,
        clientId: dataUser.ClientId,
      };
      setLoading(true)
      const res = await sendSimulation(request);
      setData(res);
      setLoading(false)
      if (res.Message) {
        setModalError(true);
      } else if (res.ScoringState === "Error") {
        setModalError(true);
      } else {
        await updateShoppingCart(dataUser.ClientId, props.addToCart);
        handleOpen()
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="container__modal__summary">
        <div className="modal__simulation__summary">
          {modalError ? <ModalErrorExecutive data={data} /> : null}
          <div className="contenedor__modal__summary">
            <p className="summary__text">RESUMEN</p>
            <InputForm
              readOnly
              value={"ID de licitación:" + " " + response.TenderCode}
              className="input__form__summary"
            />
            {loading ? <ModalLoading /> : null}
            <InputForm
              readOnly
              value={
                "Finalidad:" +
                " " +
                setTenderWarrantyTarget(response.TenderWarrantyTarget)
              }
              className="input__form__summary"
            />
            <InputForm
              readOnly
              value={"Monto:" + " " + numberFormat.format(response.TenderAmount)}
              className="input__form__summary"
            />
            <InputForm
              readOnly
              value={
                "Comisión:" + " " + numberFormat.format(response.WarrantyCost)
              }
              className="input__form__summary"
            />
            <InputForm
              readOnly
              value={"Glosa:" + " " + response.Glosa}
              className="input__form__summary"
            />
            <div className="accept-section">
              <input
                type="checkbox"
                value={active}
                onChange={changeCheck}
              ></input>
              <span>
                La información es <strong>correcta</strong>
              </span>
            </div>
            <div className="container__button__checkbox">
              <button
                type="button"
                className="button__blue"
                disabled={!active}
                onClick={() => sendSimulationWarranties()}
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <p className="m-0">SOLICITAR</p>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <article className="modal__simulation">
          <div className="contenedor__modal text-center">
            <img src={logoInge} alt="logo inge"></img>
            <h4> Aprobación de Garantía</h4>
            <p>Nos complace informarte que tu solicitud de garantía ha sido aceptada con éxito. </p>
            <p>Haz click en el boton o anda al carrito de compras para pagar y descargar tu garantía</p>
            <button
              onClick={handleClose}
              className="button__blue">
              Haz Click Acá
            </button>
          </div>
        </article>
      </Modal>
    </>

  );
}

const mapDispatchToProps = (dispatch) => ({
  addToCart: (payload) => dispatch(addToCart(payload)),
});

export default connect(null, mapDispatchToProps)(ModalSummary);
