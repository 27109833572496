import React, { useState } from "react";
import "./styles.css";
import { useSelector } from "react-redux";
import ModalTemplate from "../ModalTemplate";
import KhipuLogo from "../../assets/images/Khipu-logo.svg";
import WebpayLogo from "../../assets/images/WebPay-logo.svg";
import {
  createNotification,
  createWebPayTransaction,
  createWebPayTransactionNotAuth,
} from "../../utils/helpers/helpers";
import createKhipuTransactionApi from "../../utils/apis/khipuApi";
import LoadingScreen from "../Spinner/LoadingScreen";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function PaymentModal({
  closeModal,
  totalPrice,
  shoppingCart,
  totalPriceDesc,
  user,
}) {
  let { id } = useParams();
  console.log(id);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [precio, setPrecio] = useState(totalPrice);
  const [shoppin, setShopping] = useState(shoppingCart);
  const [users, setusers] = useState(user);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setPrecio(totalPrice);
    if (!precio.ShoppingCartTotal) return;
    sessionStorage.setItem("items", shoppin);
    setShopping({
      ...shoppin,
      CouponClientId: precio.CouponClientId,
      ShoppingCartTotal: precio.ShoppingCartTotal,
    });
  }, []);

  const webPayTransaction = async () => {
    const response = await createWebPayTransaction(shoppin, precio, users);
    if (response.message) {
      closeModal();
      setLoadingPayment(false);
      createNotification("error", response.message, null, 3000);
    } else {
      const { RedirectionFormUrl, Token } = response;
      // console.log(RedirectionFormUrl, Token);
      setLoadingPayment(true);

      window.open(`${RedirectionFormUrl}?token_ws=${Token}`, "_self").focus();
    }
  };
  const webPayTransactionNotAuth = async () => {
    const response = await createWebPayTransactionNotAuth(
      shoppin,
      precio,
      users
    );
    if (response.message) {
      closeModal();
      setLoadingPayment(false);
      createNotification("error", response.message, null, 3000);
    } else {
      const { RedirectionFormUrl, Token } = response;
      // console.log(RedirectionFormUrl, Token);
      setLoadingPayment(true);

      window.open(`${RedirectionFormUrl}?token_ws=${Token}`, "_self").focus();
    }
  };

  const khipuTransaction = async () => {
    const { dataUser } = users;
    const { Company } = dataUser;
    const transactionData = {
      companyUserId: dataUser.ClientId,
      warrantiesIds: shoppin.map(({ WarrantyId }) => WarrantyId),
      amount: precio,
      companyId: Company.CompanyId,
    };
    try {
      setLoadingPayment(true);
      const res = await createKhipuTransactionApi(transactionData);
      const { simplified_transfer_url: paymentUrl } = res;
      setLoadingPayment(false);
      window.open(`${paymentUrl}`, "_self").focus();
    } catch (error) {
      createNotification(
        "error",
        "Ha ocurrido un error al crear la transacción",
        null,
        3000
      );
      console.log(error);
    }
    closeModal();
  };

  const khipuTransactionNotAuth = async () => {
    const { ClientUserId, Warranties, ShoppingCartTotal, CompanyId } = shoppin;
    const wid = Warranties.map((e) => {
      return e.WarrantyId;
    });

    const transactionData = {
      companyUserId: ClientUserId,
      warrantiesIds: wid,
      amount: ShoppingCartTotal,
      companyId: CompanyId,
    };
    console.log("Khipu transaction data: ", transactionData);
    try {
      setLoadingPayment(true);
      const res = await createKhipuTransactionApi(transactionData);
      const { simplified_transfer_url: paymentUrl } = res;
      setLoadingPayment(false);
      window.open(`${paymentUrl}`, "_self").focus();
    } catch (error) {
      createNotification(
        "error",
        "Ha ocurrido un error al crear la transacción",
        null,
        3000
      );
      console.log(error);
    }
    closeModal();
  };
  return (
    <div>
      <ModalTemplate closeModal={closeModal} title="Modo de pago" hideHeader>
        {!loadingPayment ? (
          <div className="paymentModal_container">
            <h1>Modos de Pago:</h1>
            {id == undefined ? (
              <section>
                {/* <div
                  className="payment_method_container"
                  onClick={() => webPayTransaction()}
                  role="button"
                  tabIndex={0}
                >
                  <img src={WebpayLogo} alt="Webpay logo" />
                </div> */}
                <div
                  className="payment_method_container"
                  onClick={() => khipuTransaction()}
                  role="button"
                  tabIndex={0}
                >
                  <img src={KhipuLogo} alt="khipu logo" />
                </div>
              </section>
            ) : (
              <section>
                {/* <div
                  className="payment_method_container"
                  onClick={() => webPayTransactionNotAuth()}
                  role="button"
                  tabIndex={0}
                >
                  <img src={WebpayLogo} alt="Webpay logo" />
                </div> */}
                <div
                  className="payment_method_container"
                  onClick={() => khipuTransactionNotAuth()}
                  role="button"
                  tabIndex={0}
                >
                  <img src={KhipuLogo} alt="khipu logo" />
                </div>
              </section>
            )}
          </div>
        ) : (
          <LoadingScreen
            loadingText="Redireccionando a plataforma de pago"
            className="loading_payment_modal"
          />
        )}
      </ModalTemplate>
    </div>
  );
}

export default PaymentModal;
