import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import './styles.css';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../DataTable';
import InputForm from '../InputForm';
import TenderDetailsModal from '../TenderDetailsModal';
import TenderWarrantiesModal from '../TenderWarrantiesModal';
import { getTenders, validateTenders } from '../../utils/apis/tendersApi';
import MobileTable from '../MobileTable/MobileTable';
import useWindowDimentions from '../../utils/helpers/Hooks/useWindowDimentions';
import { addToCart } from '../../store/actions/shoppingCartActions';

function TendersTable({ user, companies }) {
  const columns = [
    {
      title: 'Código',
      field: 'Code',
      cellStyle: {
        width: 'auto',
        color: '#0065FF',
        textDecoration: 'underline',
        cursor: 'Pointer',
      },
    },
    {
      title: 'Licitación',
      field: 'Name',
      disableClick: true,
    },
    {
      title: 'Comprador',
      field: 'BuyerName',
      disableClick: true,
    },
    {
      title: 'Monto estimado',
      field: 'EstimatedAmountString',
      disableClick: true,
    },
    {
      title: 'Inicio',
      field: 'StartDateString',
      type: 'date',
      disableClick: true,
    },
    {
      title: 'Cierre',
      field: 'CloseDateString',
      type: 'date',
      disableClick: true,
      readonly: true,
    },
  ];
  const [tenders, setTenders] = useState([]);
  const [selectedTender, setSelectedTender] = useState({});
  const [inputText, setInputText] = useState('');
  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(0);
  const [showWarrantiesModal, setShowWarrantiesModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [width] = useWindowDimentions();
  const history = useHistory();
  const tableRef = useRef();
  const [formData, setFormData] = useState(user.dataUser)
  

  const refreshData = () => {
    setPage(0);
    setSearch(inputText);
    setSearching(true);
    if (tableRef.current) tableRef.current.onQueryChange();
  };

  const submitSearch = async (event) => {
    event.preventDefault();
    refreshData();
  };

  const showTenderDetails = async ({ Code }) => {
    try {
      const res = await validateTenders(Code);
      setSelectedTender(res);
      setShowDetailsModal(true);      
    } catch (error) {
      console.error(error);
    }
  };

  const promisedDataForTable = (query) => {
    // eslint-disable-next-line no-param-reassign

    if (query === undefined) {
      const res = getTenders(10);
      setTenders(res.data);
    } else {
      if (searching) query.page = 0;
      const promise = new Promise((resolve) => {
        getTenders(query.pageSize, query.page * query.pageSize, search)
          .then((result) => {
            resolve({
              data: result.data,
              page: query.page,
              totalCount: result.recordsFiltered,
            });
          });
      });
      setPage(query.page);
      setSearching(false);
      return promise;
    }
  };

  // eslint-disable-next-line consistent-return
  const simulateTender = () => {
    if (!user.isAuth) return history.push('/login');
    setShowWarrantiesModal(true);
  };

  const simulateButton = () => (
    <p>Detalles</p>
  );



  useEffect(async () => {
    try {
      const res = await getTenders(10);
      setTenders(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div className="tenders_table_container">
      <div className="tenders_table_header">
        <h2 className='text__table__container'>Licitaciones</h2>
        <div className="tenders_search_input">
          <button
            className='button__blue mb-4'
            onClick={() => refreshData()}
          >
            Buscar
          </button>

          <form onSubmit={submitSearch} style={{ width: '100%' }}>
            <InputForm
              type="text"
              placeholder="Buscar licitaciones..."
              handlerChange={(e) => setInputText(e.target.value)}
              name="searchInput"
              value={inputText}
              className='input__table__container'
              submitHandler
            />
            {/* <input type="submit" hidden /> */}
          </form>
        </div>
      </div>
      <div className="data_Table_container" style={{ width: '100%', marginBottom: 30, position: 'relative' }}>
        {width > 950 ? (
          <DataTable
            data={(query) => promisedDataForTable(query)}
            columns={columns}
            rowClickHandler={showTenderDetails}
            options={{ showTitle: false, search: false, toolbar: false }}
            innerRef={tableRef}
          />
        )
          : (
            <MobileTable
              innerRef={tableRef}
              data={(query) => promisedDataForTable(query)}
              columns={columns}
              options={{ pageSize: 20, paging: true, showTitle: false, toolbar: false }}
              rowClickHandler={showTenderDetails}
              headers={width > 400 ? ['Code', 'BuyerName'] : ['BuyerName']}
              stylesContainer={{ width: 'auto', margin: 'auto' }}
              actions={[{
                icon: () => simulateButton(),
                onClick: showTenderDetails,
              },
              ]}
            />
          )}
      </div>

      {showDetailsModal && (
        <TenderDetailsModal
          tender={selectedTender}
          simulateTender={simulateTender}
          closeModal={() => setShowDetailsModal(false)}
        />
      )}

      {showWarrantiesModal && (
        <TenderWarrantiesModal
          closeModal={() => setShowWarrantiesModal(false)}
          dataUser={user.dataUser}
          formData={formData}
          userCompanies={companies}
          tender={selectedTender}
        />
      )}

    </div>
  );
}

const mapStateToProps = ({ user, companies }) => ({
  user,
  companies,
});

export default connect(mapStateToProps)(TendersTable);
