import requestBaseMethod from './baseApi';

export const simulate = (data) => {
  const url = 'Warranties/Simulate';
  return requestBaseMethod(url, 'POST', data);
};
export const simpleSimulation = (data) => {
  const url = 'Warranties/OnlySimulate';
  return requestBaseMethod(url, 'POST', data);
};
export const sendSimulation = (data) => {
  const url = 'Warranties/RequestNew';
  return requestBaseMethod(url, 'POST', data);
};
export const sendAnonymousSimulation = (data) => {
  const url = 'ClientUsers/Warranties';
  return requestBaseMethod(url, 'POST', data);
};
export const getShoppingCart = (userId) => {
  const url = `Companies/${userId}/warrantiesAwaitingPayment`;
  return requestBaseMethod(url, 'GET');
};
export const getTotalPrice = (userId, couponCode = null) => {
  const url = `ClientUsers/${userId}/shoppingCartTotal${couponCode ? `?couponCode=${couponCode}` : ''}`;
  return requestBaseMethod(url, 'GET');
};

export const sendToPay = (data) => {
  console.log(data)
  const url = 'RentaNacional/createPayOrder'
  return requestBaseMethod(url, 'POST',data)
}

export const removeFromCart = (warrantyId) => {
  const url = `Warranties/${warrantyId}/removeFromCart`;
  return requestBaseMethod(url, 'POST');
};

export const getMyWarrantiesFromAPI = (companyId) => {
  const url = `Warranties/${companyId}/GetMyWarranties`;
  return requestBaseMethod(url, 'GET');
};

export const getMyContractsFromAPI = (companyId) => {
  const url = `Warranties/${companyId}/GetMyContracts`;
  return requestBaseMethod(url, 'GET');
};

export const getWarrantyDraftFromAPI = (warrantyId) =>{
  const url = `Warranties/CreateNewWarrantyDraft/${warrantyId}`;
  return requestBaseMethod(url, 'GET');
}
// 548852-1-LE22
