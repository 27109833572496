import {
  SET_COMPANIES,
  ADD_COMPANY,
  REMOVE_COMPANY,
  UPDATE_COMPANY,
  DEFAULT_COMPANY,
  RESET,
} from '../constants/companiesConstants';

const INITIAL_STATE = {
  defaultCompany: {
  },
  companies: [],
};

export default function companiesReducer(state = { ...INITIAL_STATE }, action) {


  switch (action.type) {
    case SET_COMPANIES: {
      const activeCompany = action.payload.find((company) => company.IsActive);
      return {
        companies: action.payload,
        defaultCompany: activeCompany,

      };
    }
    case DEFAULT_COMPANY:
      return {
        ...state,
        defaultCompany: action.payload,
      };
    case ADD_COMPANY:
      // const defaultCompany = state.companies.find(company=> company.isActive)
      return {
        // defaultCompany: defaultCompany,
        ...state,
        companies: [...state.companies, ...action.payload],
      };
    case REMOVE_COMPANY:
      return {
        ...state,
        companies: state.companies.filter((item) => item.id !== action.payload),
      };
    case UPDATE_COMPANY: {
      // eslint-disable-next-line max-len
      const newArray = state.companies.filter((item) => item.companyId !== action.payload.companyId);
      return {
        defaultCompany: action.payload,
        companies: [...newArray, action.payload],
      }; }
    case RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
}
