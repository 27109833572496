import "./styles.css";
import logoInge from "../../assets/images/logoInge.svg";
import Spinner from "../Spinner";

function ModalLoading() {
  return (
    <div className="container__modal__simulation">
      <div className="modal__simulation">
        <div className="contenedor__modal">
          <img src={logoInge} alt="logo inge"></img>
          <h3>¡Estas a un paso más cerca!</h3>
          <p>Danos unos segundos... </p>
          <Spinner />
        </div>
      </div>
    </div>
  );
}

export default ModalLoading;
