import sendInfo from "../../assets/images/sendInfo.png"
import "./style.css"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";



export default function Received() {
const history = useHistory();
  return (
    <div  className="container__infoReceived">
      <head className="header__infoReceived">
        <h1>Información Recibida</h1>
      </head>
      <div className="box-text-infoReceived">
        <h4>¡Gracias por tu tiempo!</h4>
        <p>Te contactaremos en breve para darte la mejor cotización.</p>
      </div>
      <div className="background-img">
      <img src={sendInfo} alt={sendInfo} ></img>
      </div>
      <div className="button-back-container">
      <button className="button__blue" onClick={() => history.push("/")}>Volver al Inicio</button>
      </div>
    </div>
  );
}
