import React, { useState } from "react";
import InputForm from "../../components/InputForm";
import InputRut from "../../components/InputForm/InputRut";
import "./style.css";
import { dataOptions } from "./data";
import { checkIncompleteFields } from "../../utils/helpers/helpers";
import { sendFormFactoring } from "../../utils/apis/quotation";
import { useHistory } from "react-router-dom";
import { createNotification } from "../../utils/helpers/helpers";
import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { InputAdornment, TextField } from "@mui/material";
import InputSelect from "../../components/InputSelect";
import Spinner from "../../components/Spinner";

const optionsMoney = [
  {
    value: "Pesos",
    label: "$ Peso",
  },
  {
    value: "UF",
    label: "UF Unidad de fomento",
  },
];



export default function Factoring() {
  const [loading, setLoading] = useState(false)
  const history = useHistory();
  const [formData, setFormData] = useState({ ...dataOptions });
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [validateRut, setValidateRut] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);

  const handlerOnChange = (value) => {
    console.log("captcha value", value);
  };
  const {
    typeMoney,
    stringAmount

  } = formData;

  //VALIDANDO BOTÓN DE ENVIAR
  const validateButton = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["firstName", "rutProveedor", "email", "phone", "rutReceptor", "factura"],
      formData
    );
    console.log("campos vacios", incompleteFieldsArray);
    if (incompleteFieldsArray.length == 0 && validateRut && !isErrorEmail && !isError) setSubmitButtonEnabled(false);
    else setSubmitButtonEnabled(true);
  };

  //  //HOOK DE EFECTO PARA VALIDAR BUTTON Y QUE SE INICIALICE EN LA DATA
  useEffect(() => {
    validateButton();
  }, [formData]);

  const inputHandler = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      setLoading(true)
      await sendFormFactoring(formData);
      console.log("esto se envió", formData);
      history.push("/infoReceived");
      setLoading(false)

    } catch (error) {
      createNotification(
        "error",
        "Por favor intentelo más tarde.",
        "Ha ocurrido un error al enviar su formulario"
      );
      setLoading(false)

    }
  };
  const inputHandlerEmail = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!value || regex.test(value) === false) {
      setIsErrorEmail(true);
    } else {
      setIsErrorEmail(false);
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const inputHandlerFono = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const regex = /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/;

    if (!value || regex.test(value) === false) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  useEffect(() => {
    if (typeMoney.length > 0) {
      setFormData({
        ...formData,
        factura: `${formData.stringAmount} ${formData.typeMoney}`,
        typeMoney: formData.typeMoney,
      });
      console.log(typeMoney.length);
    }
  }, [typeMoney]);
  console.log(formData)

  return (
    <div className="principal__factoring">
      <div className="container__text__factoring">
        <h3>¡Te ayudamos a mejorar la liquidez de tu empresa con Factoring!</h3>
        <p>
          Esta forma de financiamiento se realiza a través de un contrato en
          donde una empresa cede las facturas que tiene por cobrar y recibe a
          cambio el pago inmediato de las mismas.
        </p>
        <h2>Beneficios del factoring</h2>
        <p>
          <ol>
            <li>Liquidez Inmediata</li>
            <li>La deuda no se registra en el sector financiero</li>
            <li>Mejora el flujo de caja</li>
            <li>Ahorro en costos</li>
          </ol>
        </p>
        <h2>¿Quieres hacer un factoring con nosotros?</h2>
        <p>
          Si deseas tomar esta alternativa con nosotros para tener tranquilidad
          y mayor liquidez en tu empresa, deja tus datos en el formulario de
          contacto y te llamaremos.
        </p>
      </div>
      <section className="factoring-container">
        <div className="container-form__factoring">
          <form className="form-factoring">
            <GoogleReCaptchaProvider reCaptchaKey="6LdW_9khAAAAAEGx6saxoqhGp0rqrJAGrGRJVUuo">
              <div className="form-security__form">
                <div className="form-security__inputs">
                  <div className="info-contact">
                    <h5>Información de Contacto</h5>
                    <p>
                      Necesitamos estos datos para darte la cotización que
                      necesitas, ¿me los puedes Compartir?
                    </p>
                  </div>
                  <InputForm
                    type="text"
                    value={formData.firstName}
                    name="firstName"
                    label="Nombre Completo*"
                    handlerChange={inputHandler}
                    error={incompleteFields.includes("firstName")}
                  />
                  <InputRut
                    formData={formData}
                    setFormData={setFormData}
                    validateRut={setValidateRut}
                    name="rutProveedor"
                    label="Rut Empresa*"
                    value={formData.rutProveedor}
                    className="input__rut__factoring my-4"
                  />

                  <InputForm
                    type="email"
                    name="email"
                    label="Email"
                    value={formData.email}
                    handlerChange={inputHandlerEmail}
                    error={incompleteFields.includes("email")}
                  />
                  {isErrorEmail && <p className="invalid_phone">Email invalido</p>}
                  <TextField
                    type="number"
                    name="phone"
                    className="mt-3"
                    value={formData.phone}
                    id="outlined-basic"
                    label="Teléfono Móvil"
                    title="Teléfono Móvil"
                    variant="outlined"
                    onChange={inputHandlerFono}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+56</InputAdornment>
                      ),
                    }}
                  />
                  {isError && <p className="invalid_phone">Telefono invalido</p>}
                  <InputForm
                    type="number"
                    value={formData.factura}
                    name="factura"
                    className="my-4"
                    label="Monto Factura"
                    handlerChange={inputHandler}
                  />
                  <div className="container__rut__factoring">
                    <InputRut
                      formData={formData}
                      setFormData={setFormData}
                      name="rutReceptor"
                      label="Rut Pagador "
                      validateRut={setValidateRut}
                      value={formData.rutReceptor}
                      className="input__rut__factoring"
                    />
                  </div>
                </div>
                <div className="form-contact__optional_inputs">
                  <div className="form-contact-button w-100 d-flex justify-content-center">
                    <button
                      type="submit"
                      className="button__blue w-75"
                      onClick={submitHandler}
                      disabled={submitButtonEnabled}
                    >
                      {loading ? (
                        <Spinner />
                      ) : (
                        <p className="m-0">Cotizar</p>
                      )}

                    </button>
                  </div>
                </div>
              </div>
            </GoogleReCaptchaProvider>
          </form>
        </div>
      </section>
    </div>
  );
}
