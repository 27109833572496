import React, { useState } from 'react';
import './styles.css';
import TendersTable from '../../components/tendersTable';

const initialState = {
  name: '',
  month: '',
  Purpose: '',
};

function Tenders() {
  const [formData, setFormData] = useState({ ...initialState });

  const inputHandler = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    console.log(formData);
  };

  return (
    <section className="tenders__container">
      <div className="tenders__title">
        <h1 className="tenders__title__main">
          Busca tu licitación y Simula tu garantía
        </h1>
        <h3 className="tenders__title__secondary">
          Contamos con más de 58.000 licitaciones para que puedas seguir
          trabajando e inspirándote.
        </h3>
      </div>
      <TendersTable/>
    </section>
  );
}

export default Tenders;
