import "./styles.css";
import React, { useState } from "react";
import seriedadOferta from "./assets/seriedad.png";
import fielCumplimiento from "./assets/fielCumplimiento.png";
import correctoUso from "./assets/correctoUso.png";
import correctaEjecucion from "./assets/correctaEjecucion.png";
import canjeRetenciones from "./assets/canjeRetenciones.png";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const Warranty = ({ setPage }) => {
  const [company, setCompany] = useState(false);

  const warrantys = [
    {
      subtitle: "Seriedad de la oferta",
      img: seriedadOferta,
      text: "Es la primera garantía que se solicita para participar en una licitación. Respalda que lo que se prometió se va a cumplir. ",
      url: (
        <button className="button__blue" onClick={() => setPage(1)}>
          Cotizar
        </button>
      ),
    },
    {
      subtitle: "Fiel cumplimiento",
      img: fielCumplimiento,
      text: "Garantizamos que se cumplan las condiciones acordadas en el contrato de la licitación.",
      url: (
        <button className="button__blue" onClick={() => setPage(2)}>
          Cotizar
        </button>
      ),
    },
    {
      subtitle: "Correcta ejecución",
      img: correctaEjecucion,
      text: "Garantizamos que la empresa que se adjudicó la licitación haya ejecutado el trabajo de forma satisfactoria.",
      url: (
        <button className="button__blue" onClick={() => setPage(3)}>
          Cotizar
        </button>
      ),
    },
    {
      subtitle: "Correcto uso de anticipo",
      img: correctoUso,
      text: "Aseguramos que los recursos que se entregan como anticipo ante un proyecto sean usados de forma correcta.",
      url: (
        <button className="button__blue" onClick={() => setPage(4)}>
          Cotizar
        </button>
      ),
    },
    {
      subtitle: "Canje de retenciones",
      img: canjeRetenciones,
      text: "Permite que se pueda obtener el pago de las retenciones efectuadas en relación a los estados de pago contractuales.",
      url: (
        <button className="button__blue" onClick={() => setPage(5)}>
          Cotizar
        </button>
      ),
    },
  ];

  return (
    <div className="container__principal">
      <div className="subtitle__quoter__warranty">
        <h1>
          <strong>¿Que garantía necesitas?</strong>
        </h1>
      </div>
      <div className="box__cards__warranty">
        {warrantys.map((warranty) => (
          <div key={warranty.subtitle} className="cards_warranty">
            <h5>{warranty.subtitle}</h5>
            <div className="img__quoter">
              <img className="img__icon" src={warranty.img} alt={"imagen"} />
            </div>
            <div className="container__text__warrany">
              <p>{warranty.text}</p>
            </div>
            {warranty.url}
          </div>
        ))}
      </div>
    </div>
  );
};
