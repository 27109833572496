import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import InputForm from "../../components/InputForm";
import InputRut from "../../components/InputForm/InputRut";
import { chile } from "./data";
import { checkIncompleteFields } from "../../utils/helpers/helpers";
import lugage from "../../assets/images/lugage.svg";
import Form from "../../components/Form";
import ModalSii from "./ModalSii";
import { ProgressBar } from "react-bootstrap";

export default function DataOptional({ formData, setFormData, setPage }) {
  const FormTitles = [
    "BIENVENIDO",
    "REGISTRA TU EMPRESA",
    "INGRESA UN INTEGRANTE",
  ];
  // const [formData, setFormData] = useState({...globalData});
  const [idChile, setIdChile] = useState(-1);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const [validateRut, setValidateRut] = useState(false);
  const [incompleteFields, setIncompleteFields] = useState([
    "reason",
    "rutCompany",
    "officeNumber",
    "giro",
    "address",
    "number",
    "region",
    "comuna",
  ]);
  const [modalSii, setModalSii] = useState(true);
  console.log("esto:", incompleteFields);
  const history = useHistory();

  const checkFormData = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["reason", "rutCompany", "giro", "address", "number", "region", "comuna"],
      formData
    );
    setIncompleteFields(incompleteFieldsArray);
  };

  const data = localStorage.getItem("data register");
  const registerForm = JSON.parse(data);

  const handlerRegion = (e) => {
    const option = e.target.value;
    setIdChile(option);
    setFormData({
      ...formData,
      region: chile.regiones[option].region,
    });
    localStorage.setItem("data company", JSON.stringify(formData));
    checkFormData();
  };

  const handlerComuna = (e) => {
    const option = e.target.value;
    setFormData({
      ...formData,
      comuna: chile.regiones[idChile].comunas[option],
    });
    localStorage.setItem("data company", JSON.stringify(formData));
    checkFormData();
  };
  const handlerInput = (event) => {
    const name = event.target.name;
    const lastName = event.target.lastName;
    const { value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
      [lastName]: value,
    });
    localStorage.setItem("data company", JSON.stringify(formData));
    checkFormData();
  };

  const validateButton = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["reason", "rutCompany", "giro", "address", "number", "region", "comuna"],
      formData
    );
    if (incompleteFieldsArray.length > 0) {
      setSubmitButtonEnabled(false);
    } else {
      setSubmitButtonEnabled(true);
    }
  };

  useEffect(() => {
    validateButton();
  }, [formData]);

  return (
    <section className="create__container">
      <div className="container-wizzard">
        <div className="text-wizzard">
          <p>Registro</p>
          <p>+Empresa</p>
          <p>+Integrante</p>
        </div>
        <div className="wizzard">
          <ProgressBar now={30} />
        </div>
      </div>
      <article className="form__container__create">
        {modalSii ? (
          <ModalSii
            formData={formData}
            setFormData={setFormData}
            setModalSii={setModalSii}
          />
        ) : (
          false
        )}
        <section className="container__title__create">
          <h2 className="create__title">COMPLETA TU REGISTRO</h2>
          <p className="create__subtitle">
            Con nosotros obtendrás lo que necesites para tu licitación de forma
            rápida y online
          </p>
        </section>
        <section className="store-company__form__inputs-container">
          <div className="company_form_inputs">
          <InputForm
              type="text"
              name="reason"
              label="Nombre/Razón Social"
              value={formData.reason}
              handlerChange={handlerInput}
              className="flex_3"
            />
            <InputRut
              formData={formData}
              setFormData={setFormData}
              name="rutCompany"
              label="RUT"
              validateRut={setValidateRut}
              value={formData.rutCompany}
              onChange={checkFormData}
              className="flex_2"
            />

          </div>
          <div className="company_form_inputs_register">
          
            <InputForm
              type="text"
              name="giro"
              label="Giro"
              value={formData.giro}
              onChange={(event) => setFormData(event.target.value)}
              handlerChange={handlerInput}
              className="flex_6"
            />
          </div>
          <div className="company_form_inputs">
            <InputForm
              type="text"
              name="address"
              label="Dirección"
              onChange={(event) => setFormData(event.target.value)}
              handlerChange={handlerInput}
              className="flex_3"
            />
            <InputForm
              type="number"
              name="number"
              label="Número"
              onChange={(event) => setFormData(event.target.value)}
              handlerChange={handlerInput}
              className="flex_2"
            />
          </div>
          <div className="company_form_inputs ">
            <InputForm
              type="text"
              name="contact"
              label="Nombre de contacto"
              value={registerForm?.name + " " + registerForm?.lastName}
              onChange={(event) => setFormData(event.target.value)}
              handlerChange={handlerInput}
              className="flex_3"

            />

            <InputForm
              type="number"
              name="officeNumber"
              label="N° Oficina"
              onChange={(event) => setFormData(event.target.value)}
              handlerChange={handlerInput}
              className="flex_2"


            />
          </div>
          <div className="company_form_inputs">
            <select
              className="select-regiones"
              name="region"
              onChange={(e) => {
                handlerRegion(e);
              }}
            >
              <option>Región</option>
              {chile.regiones.map((item, index) => (
                <option value={index}>{item.region}</option>
              ))}
            </select>
            <select
              className="select-comunas"
              name="comuna"
              onChange={(e) => {
                handlerComuna(e);
              }}
            >
              <option>Comuna</option>
              {idChile > -1 &&
                chile.regiones[idChile].comunas.map((item, index) => (
                  <option value={index}>{item}</option>
                ))}
            </select>
            <InputForm
              type="text"
              name="phone"
              title="Celular"
              placeholder="+5699999999"
              value={registerForm?.phone}
              handlerChange={handlerInput}
              className="flex_3"
            />
            <InputForm
              type="text"
              name="email"
              title="Email"
              placeholder="nombre@inge.cl"
              value={registerForm?.email}
              handlerChange={handlerInput}
              className="flex_3"
            />
          </div>
          <div className="container__button__create">
            <button
              disabled={!submitButtonEnabled}
              className="button__blue"
              onClick={() => setPage(2)}
            >
              CONTINUAR
            </button>
          </div>
        </section>
      </article>
      <div className="container__buttons__create">
        <button
          type="button"
          className="button__gray me-4"
          onClick={() => {
            setPage(0);
          }}
        >
          Atrás
        </button>
        <div className="container-btn-later">
          <Form
            buttonValue="Agregar más tarde"
            type="register"
            formData={formData}
            className="button__blue"
          ></Form>
        </div>
      </div>
    </section>
  );
}
