import {
  REMOVE_FROM_CART, SET_CART, RESET_CART, SET_LOADING,
}
  from '../constants/shoppingCartConstants';

const INITIAL_STATE = {
  warranties: [],
  ShoppingCartTotal: null,
  ShoppingCartTotalString: '',
  loading: false,
};

// eslint-disable-next-line no-unused-vars
export default function shoppingCartReducer(state = { ...INITIAL_STATE }, action) {
  switch (action.type) {
    case SET_CART: {

      return {
        ...state,
        ...action.payload,
      };
    }
    case REMOVE_FROM_CART: {
      // console.log('REMOVED FROM CART');
      return state;
    }
    case RESET_CART: {
      // console.log('reset de carrito');
      return {
        ...INITIAL_STATE,
      };
    }
    case SET_LOADING: {
      // console.log('Cart loading...');
      return {
        ...state,
        loading: action.payload,
      };
    }

    default:
      return state;
  }
}
