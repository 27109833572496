import { ArrowForward } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import InputForm from "../../components/InputForm";
import ModalTemplate from "../../components/ModalTemplate";
import Spinner from "../../components/Spinner";
import { validateCoupon } from "../../utils/apis/clientUsersApi";
import { getTotalPrice } from "../../utils/apis/warrantiesApi";
import { createNotification } from "../../utils/helpers/helpers";
import "./styles.css";
import TermsConditions from "./TermsConditions";

export const CartTotalNotAuth = ({ goToPayment, cart }) => {
    const user = useSelector((state) => state.user);
    const {
      dataUser: { ClientId },
    } = user;
    const [showConditionsModal, setShowConditionsModal] = useState(false);
    const [conditionsAccepted, setConditionsAccepted] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [loadingCoupon, setLoadingCoupon] = useState(false);
    const [discountPrice, setdiscountPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const applyCoupon = async () => {
      if (!couponCode) {
        createNotification("error", "Ingrese el código del cupón");
        return;
      }
      setLoadingCoupon(true);
      try {
        const isValidated = await validateCoupon(cart.ClientUserId, couponCode);
        console.log(isValidated)
        if (!isValidated) throw new Error("Cupón no valido");
        const price = await getTotalPrice(cart.ClientUserId, couponCode);
        setTotalPrice(price);
        setdiscountPrice(cart.ShoppingCartTotal - price.ShoppingCartTotal);
        createNotification("success", "Cupón agregado correctamente!");
      } catch (error) {
        createNotification("error", error.message);
      }
      setLoadingCoupon(false);
    };
    useEffect(() => {
      setCouponCode("");
    }, []);
    const { ShoppingCartTotal } = totalPrice;
    console.log(cart);
  
    return (
      <div className="total_price_container">
        <h1>Resumen de orden:</h1>
        <div className="total_price_info ">
          <div className="total_price_item">
            <p>Subtotal</p>
            <span>{cart.ShoppingCartTotalString}</span>
          </div>
          {cart.Warranties[0].InsuranceCompanyId != -3 && (
            <section>
              <div className="total_price_item">
                <p>Desc. cupón</p>
                <span>
                  {"- $" + new Intl.NumberFormat().format(discountPrice)}
                </span>
              </div>
              <div className="total_price_item">
                <p>A pagar</p>
                <span className="total">
                  {!ShoppingCartTotal
                    ? "$ 0"
                    : "$" + new Intl.NumberFormat().format(ShoppingCartTotal)}
                </span>
              </div>
            </section>
          )}
        </div>
        {cart.Warranties[0].InsuranceCompanyId == -3 ? (
          <span></span>
        ) : (
          <div className="coupon_input_container total_price_item">
            <p>Ingresa tu cupón: </p>
            <InputForm
              placeholder="Código cupón"
              value={couponCode}
              handlerChange={(e) => setCouponCode(e.target.value)}
            />
            <div
              className="coupon_input_button"
              onClick={() => applyCoupon()}
              role="button"
              tabIndex={0}
            >
              {loadingCoupon ? <Spinner /> : <ArrowForward />}
            </div>
          </div>
        )}
  
        <div className="terms_input_container">
          <input
            type="checkbox"
            value="terminos y condiciones"
            checked={conditionsAccepted}
            onChange={() => setConditionsAccepted(!conditionsAccepted)}
            id="conditions"
            style={{ cursor: "pointer" }}
          />
          <label>
            Acepto los{" "}
            <span onClick={() => setShowConditionsModal(true)}>
              términos y condiciones
            </span>
            .
          </label>
        </div>
        <div className="w-100">
          <Button
            value="Ir a pagar"
            className="button__blue float-end"
            clickHandler={() => goToPayment(totalPrice)}
            disabled={!conditionsAccepted}
          />
        </div>
  
        <div className="terms_modal_container">
          {showConditionsModal && (
            <ModalTemplate
              closeModal={() => setShowConditionsModal(false)}
              title="Términos y condiciones"
            >
              <div className="terms_container">
                {/* <embed src={terms} type="" style={{ width: '100%', height: '600px' }} /> */}
                <TermsConditions />
              </div>
            </ModalTemplate>
          )}
        </div>
      </div>
    );
}
