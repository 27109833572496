import React, { useEffect, useState } from "react";
import InputForm from "../../../../components/InputForm";
import Button from "../../../../components/Button";
import "./styles.css";
import InputSelect from "../../../../components/InputSelect";
import DateInputs from "../../../../components/DateInputs";
import CaseCotizacion from "./CaseCotizacion";
import {
  checkIncompleteFields,
  createNotification,
} from "../../../../utils/helpers/helpers";
import handscontract from "../../../../assets/images/handscontract.svg";

import InputRut from "../../../../components/InputForm/InputRut";
import {
  sendContactForm,
  sendGeneralContact,
  whatsappContact,
} from "../../../../utils/apis/companiesApi";
import { InputAdornment, TextField } from "@mui/material";
import Spinner from "../../../../components/Spinner";

const initialState = {
  name: "",
  firstName: "",
  lastName: "",
  rut: "",
  email: "",
  phone: "",
  message: "",
  warrantyType: "",
  startDate: "",
  endDate: "",
  businessRut: "",
  businessName: "",
  amount: "",
  stringAmount: "",
  tenderId: "",
  factoring: "",
  capitalTrabajo: "",
  garantia: "",
  typeMoney: "",
};

const initialOption = "Elija una opción...";

const initialRequiredFields = [
  "name",
  "phone",
  "email",
  "businessRut",
  "factoring",
  "capitalTrabajo",
  "typeMoney",
  "stringAmount",
];

export const ConctactWsp = () => {
  const [formData, setFormData] = useState({ ...initialState });
  const [selectedOption, setSelectedOption] = useState(initialOption);
  const [validateRut, setValidateRut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobile, setmobile] = useState("");
  const [isError, setIsError] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);

  const [requiredFields, setRequiredFields] = useState([
    ...initialRequiredFields,
  ]);
  const [incompleteFields, setIncompleteFields] = useState([]);

  const inputHandler = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const inputHandlerFono = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const regex = /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/;

    if (!value || regex.test(value) === false) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const inputHandlerEmail = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!value || regex.test(value) === false) {
      setIsErrorEmail(true);
    } else {
      setIsErrorEmail(false);
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const validateButton = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["name"],
      formData
    );
    if (
      incompleteFieldsArray.length == 0 &&
      validateRut &&
      !isError &&
      !isErrorEmail
    ) {
      setSubmitButtonEnabled(false);
    } else {
      setSubmitButtonEnabled(true);
    }
  };

  useEffect(() => {
    validateButton();
  }, [formData]);

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      setFormData({
        ...formData,
        phone: `+56 ${formData.phone}`,
      });
      setLoading(true);
      await whatsappContact(formData);

      createNotification("success", "Formulario Enviado!", "Te contactaremos");
      setFormData(initialState);
      setLoading(false);
      console.log("esto se envió", formData);
      window.open("https://acortar.link/skr90b");

      //   history.push('https://acortar.link/skr90b');
    } catch (error) {
      createNotification(
        "error",
        "Por favor intentelo más tarde.",
        "Ha ocurrido un error al enviar su formulario"
      );
      setLoading(false);
    }
  };

  const commentTextArea = (placeholder) => (
    <textarea
      name="message"
      className={`${incompleteFields.includes("message") ? "input_error" : ""}`}
      placeholder={placeholder}
      value={formData.message}
      onChange={inputHandler}
    />
  );
  const {
    tenderAmount,
    warrantyTarget,
    startDate,
    endDate,
    warrantyId,
    firstName,
    lastName,
    phone,
    email,
    rut,
    businessRut,
    businessName,
    tenderId,
    warrantyType,
    message,
    amount,
    factoring,
    capitalTrabajo,
    garantia,
    typeMoney,
    name,
    stringAmount,
  } = formData;
  //Renderiza los formularios para cada accion que se requiera
  useEffect(() => {
    setIncompleteFields([]);
    setFormData({
      firstName: formData.firstName,
      lastName: formData.lastName,
      amount: formData.amount,
      rut: formData.rut,
      createdAt: formData.createdAt,
      email: formData.email,
      phone: formData.phone,
      businessRut: formData.businessRut,
      businessName: formData.businessName,
      warrantyType: formData.warrantyType,
      message: formData.message,
      garantia: formData.garantia,
      factoring: formData.factoring,
      capitalTrabajo: formData.capitalTrabajo,
      typeMoney: formData.typeMoney,
      cotizacion: formData.cotizacion,
      stringAmount: formData.stringAmount,
      name: formData.name,
    });
  }, [selectedOption]);

  console.log(formData);
  return (
    <form className="form-contact-index-wsp">
      <div className="form-contact__text__initial">
        <h2>Deja tus datos y hablemos</h2>
      </div>
      <div className="form-contact__form">
        <div className="form-contact__inputs__index">
          <div className="d-flex">
            <InputForm
              type="text"
              name="name"
              label="Nombre o Razon Social "
              value={formData.name}
              handlerChange={inputHandler}
              className="mt-4"
              fullWidth
              size="small"
            />
          </div>
          <div className="d-flex flex-column w-100">
            <TextField
              type="number"
              name="phone"
              className="mt-3"
              value={formData.phone}
              id="outlined-basic"
              label="Teléfono Móvil"
              title="Teléfono Móvil"
              variant="outlined"
              onChange={inputHandlerFono}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+56</InputAdornment>
                ),
              }}
            />

            {isError && <p className="invalid_phone">Telefono invalido</p>}
            <InputForm
              type="email"
              name="email"
              label="Email"
              value={formData.email}
              fullWidth
              placeholder="nombre@inge.cl"
              handlerChange={inputHandlerEmail}
              className="mt-4"
            />
            {isErrorEmail && <p className="invalid_phone">Email invalido</p>}
          </div>

          <div className="d-flex mt-4">
            <InputRut
              formData={formData}
              setFormData={setFormData}
              validateRut={setValidateRut}
              name="businessRut"
              label="Rut Empresa"
              className="input__rut__contact me-3"
            />
          </div>
        </div>
        <section className="d-flex  flex-column">
          <div className="w-100 d-flex justify-content-center">
            <button
              className="button__blue mt-2 w-50"
              type="submit"
              value="Enviar"
              onClick={submitHandler}
              disabled={submitButtonEnabled}
            >
              {loading ? <Spinner /> : <p className="m-0">Hablemos Aqui </p>}
            </button>
          </div>
        </section>
      </div>
    </form>
  );
};
