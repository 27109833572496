import React from 'react';
// import Button from '../../Button';
import SquareButton from '../../SquareButton';

//   const [errorTitle, setErrorTitle] = useState('');
//   const [errorSubtitle, setErrorSubtitle] = useState('');

//   useEffect(() => {
//     switch (errorType) {
//       case 'simulación':
//         setErrorTitle(' Ha ocurrido un error con su simulación');
//         setErrorSubtitle(' Por favor intente nuevamente');
//         break;
//       case 'request':
//         setErrorTitle(errorMessage);
//         break;
//       default: break;
//     }
//   }, []);
const errorStep = ({
  errorTitle, errorSubtitle, action, actionValue, executiveName, executiveEmail, executivePhone
}) => (
  <div className="step_notification_container">
    <h1
      className="step_notification_title"
    >
      {errorTitle}

    </h1>
    <h2
      className="step_notification_subtitle"
    >
      {errorSubtitle || ''}

    </h2>
    <table>
      <tr>
        <td>Ejecutivo: </td>
        <td>{executiveName}</td>
      </tr>
      <tr>
        <td>Email: </td>
        <td><a href={"mailto:"+executiveEmail}>{executiveEmail}</a></td>
      </tr>
      <tr>
        <td>Whatsapp: </td>
        <td><a href={'https://wa.me/'+executivePhone?.replace('+','').replace('(','').replace(')','').replace(' ','')}>{executivePhone}</a></td>
      </tr>
    </table>
    {action && <SquareButton styles={{ color: 'white', padding: '0.6rem' }} clickHandler={action} value={actionValue} />}
  </div>
);
export default errorStep;
