import {
  SET_COMPANIES,
  ADD_COMPANY,
  REMOVE_COMPANY,
  UPDATE_COMPANY,
  DEFAULT_COMPANY,
  RESET,
} from '../constants/companiesConstants';

export const setCompanies = (payload) => ({
  type: SET_COMPANIES,
  payload,
});

export const addCompany = (payload) => ({
  type: ADD_COMPANY,
  payload,
});

export const removeCompany = (payload) => ({
  type: REMOVE_COMPANY,
  payload,
});

export const updateCompany = (payload) => ({
  type: UPDATE_COMPANY,
  payload,
});

export const defaultCompany = (payload) => ({
  type: DEFAULT_COMPANY,
  payload,
});

export const resetCompanies = () => ({
  type: RESET,
});
