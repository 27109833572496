import React, { useState } from "react";
import "./style.css";
import { useSelector } from "react-redux";
import DataClient from "./dataClient";
import AddMember from "./dataMember";
import DataOptional from "./dataOptional";
import DataCompany from "./dataCompany";
import Resumen from "./Resumen";

export default function FormRegister() {
  const user = useSelector((state) => state.user);
  const { ClientId } = user.dataUser;

  // VARIABLES DE ESTADO Y HOOKS
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    rut: "",
    password: "",
    repeatPassword: "",
    acceptCheck: "",
    reason: "",
    rutCompany: "",
    phoneCompany: "",
    giro: "",
    address: "",
    officeNumber: "",
    region: "",
    comuna: "",
    member: "",
    memberName: "",
    memberRut: "",
    memberAddress: "",
    memberEmail: "",
    messageMember: "",
    passwordSii: "",
  });

  // CAMBIO DE VISTA
  const changeOfView = () => {
    if (page === 0) {
      return (
        <DataClient
          formData={formData}
          setFormData={setFormData}
          setPage={setPage}
          page={page}
        />
      );
    } else if (page === 1) {
      return (
        //subResgistro de empresa
        <DataOptional
          formData={formData}
          setFormData={setFormData}
          setPage={setPage}
          page={page}
        />
      );
    } else if (page === 2) {
      return (
        <AddMember
          formData={formData}
          setFormData={setFormData}
          setPage={setPage}
          page={page}
        />
      );
    } else if (page === 3) {
      return (
        <DataCompany
          formData={formData}
          setFormData={setFormData}
          setPage={setPage}
          page={page}
        />
      );
    }
  };

  <Resumen formData={formData} />;
  return (
    <div>
      <div>{changeOfView()}</div>
    </div>
  );
}
