import React from 'react';
import SquareButton from '../../SquareButton';

function StepFive({ data, next }) {
  const setTenderWarrantyTarget = (value) => {
    switch (value) {
      case '-1':
        return 'Seriedad de oferta';
      case '1':
        return 'Fiel cumplimiento';
      case '2':
        return 'Correcta ejecución';
      case '3':
        return 'Fiel cumplimiento y correcta ejecución';
      case '4':
        return 'Correcta ejecución y buen comportamiento de la obra';
      default:
        return ' ';
    }
  };

  return (
    <div className="step_container">
      <div
        className="step_content step_results"
      >
        <div
          className="step_results_item"
        >
          <p>
            ID Licitación:
          </p>
          <span>{data.TenderCode}</span>
        </div>
        <div
          className="step_results_item"
        >
          <p>
            Finalidad:
          </p>
          <span>{setTenderWarrantyTarget(data.TenderWarrantyTarget)}</span>
        </div>
        <div
          className="step_results_item"
        >
          <p>
            Monto:
          </p>
          <span>{data.TenderAmountString}</span>
        </div>
        <div
          className="step_results_item"

        >
          <p>
            Comisión:
          </p>
          <span>{data.WarrantyCostString}</span>
        </div>
        <div
          className="step_results_item"
        >
          <p>
            Glosa:
          </p>
          <span>{data.Glosa}</span>
        </div>
      </div>
      <div className="step_actions">
        <SquareButton
          value="Solicitar garantía"
          type="text"
          clickHandler={() => next()}
          styles={{ display: 'block', marginLeft: 'auto', width: 180 }}
        />
      </div>
    </div>
  );
}

export default StepFive;
