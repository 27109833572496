import "./style.css";
import React, { useState } from "react";
import logoSll from "../../assets/images/ssl-security.png";
import logoCt from "../../assets/images/logo_ct.png";
import InputRut from "../../components/InputForm/InputRut";
import InputPassword from "../../components/InputForm/InputPassword";
import btnClose from "../../assets/images/btnClose.svg"

function ModalSii({ formData, setFormData, setModalSii }) {



  const handlerInput = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const closeModal = () => {
    setModalSii(false);
  };


  const handlerCheck = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setFormData({
      ...formData,
      [name]: value,
    });
  };



  return (
    <article className="container__modal__simulation">
      <div className="modal__clave">
        <section className="header__modal__clave">

          <img src={logoCt} alt="logoSii" className="logoClave"></img>

        </section>
        <section className="container__inputs__claveSii">
          <section className="container__inputs__sii mt-3">
            <div className="label__claveSii mb-3">
              <label>RUT</label>
              <InputRut
                formData={formData}
                setFormData={setFormData}
                name="rut"
                title="RUT"
                value={formData.rut}
                handlerchange={handlerInput}
                className="input__save__rut"
              />
            </div>
            <div className="label__claveSii mb-3">
              <label>Ingresar clave tributaria</label>
              <InputPassword
                name="passwordSii"
                title="Ingresar Clave Tributaria"
                placeholder="*********"
                value={formData.passwordSii}
                handlerChange={handlerInput}
                className="input__save__clave"
              />
            </div>
            <div className="checkbox">
              <p className="acept-check">
                <input
                  name="acceptCheck"
                  value={formData.acceptCheck}
                  type="checkbox"
                  onClick={(e) => {
                    handlerCheck(e);
                  }}
                ></input>
                Acepto permitir a Inge acceder a visualizar mi informacion en el Servicios de Inpuestos Internos

              </p>

            </div>

            <section className="contenedor__modal__clave">

              <div className="container__btns">
                <button onClick={closeModal} className="button__omitir__clave">
                  Omitir Este Paso
                </button>
                <button type="submit" className="button__save__clave" onClick={closeModal}>Guardar</button>
              </div>
              <div className="container__button__modalError"></div>
            </section>
          </section>
          <hr className="sideBar"></hr>
          <section className="container__text__sii">
            <p>
              En Inge, nos comprometemos a proteger tus datos personales y respetar tu privacidad en todo momento.
            </p>
            <p>
              Todos los datos que proporcionas, incluida tu contraseña, se almacenan de manera cifrada.
            </p>
            <p>
              Nos comprometemos a ser transparentes en
              cuanto a cómo se utilizan tus datos y nunca compartiremos ni venderemos tu información personal a
              terceros.
            </p>
            <img src={logoSll} alt="logoSii" className="logoSll"></img>
          </section>

        </section>

      </div>
    </article>

  );
}

export default ModalSii;
