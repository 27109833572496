import React from "react";
import "./style.css";
import { ContactHeader } from "../../screens/Home/components/Contact/ContactHeader";
import emma from "../../assets/images/11.svg";
import { ContactLanding } from "../../screens/Home/components/Contact/ContactLanding";

export const Landing = () => {
  return (
    <article className="algo_main">
      <h1>La SGR más rápida y confiable de Chile</h1>
      <div className="landing_main">
        <section className="content_main">
          <div className="content_p">
            <figure className="content_landing">
              <img src={emma} alt="Emma" />
            </figure>
            <p>
              ¿Participas en licitaciones de Mercado Público, Privadas o en
              Tratos Directos?. <p></p> Nosotros te ayudamos en tiempo récord con las
              garantías que vas a necesitar. Somos la SGR más rápida de Chile.
            </p>
            <p>
              También tenemos alternativas de financiamiento que te permitirán
              obtener liquidez inmediata para tu empresa:
            </p>
            <p>
              <strong>* Factoring:</strong> envía las facturas que tengas con
              tus clientes y mandantes.
            </p>
            <strong>* Crédito Capital de Trabajo</strong>
            <p></p>
            <p>
              En <strong>InGe! Servicios Financieros </strong>estamos comprometidos con el éxito
              de tu negocio. Por eso te ofrecemos rapidez y sencillez en
              nuestros procesos, con soluciones a la medida que te ayudarán a
              alcanzar tus objetivos.
            </p>
            <p>
              ¿Quieres saber más?
              <p></p>
              <strong>¡Deja tus datos y descubre cómo podemos ayudarte!</strong>
            </p>
          </div>
        </section>
        <ContactLanding />
      </div>
    </article>
  );
};
