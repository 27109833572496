import React, { useEffect, useState } from "react";
import InputForm from "../../../../components/InputForm";
import Button from "../../../../components/Button";
import "./styles.css";
import InputSelect from "../../../../components/InputSelect";
import DateInputs from "../../../../components/DateInputs";
import CaseCotizacion from "./CaseCotizacion";
import {
  checkIncompleteFields,
  createNotification,
} from "../../../../utils/helpers/helpers";
import handscontract from "../../../../assets/images/handscontract.svg";

import InputRut from "../../../../components/InputForm/InputRut";
import { sendContactForm } from "../../../../utils/apis/companiesApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { InputAdornment, TextField } from "@mui/material";
import Spinner from "../../../../components/Spinner";

let newDate = new Date();
const tenderWarrantyOptions = [
  {
    value: "-1",
    nameValue: "Seriedad de oferta",
    label: "Seriedad de oferta",
  },
  {
    value: "1",
    nameValue: "Fiel cumplimiento",
    label: "Fiel cumplimiento",
  },
  {
    value: "2",
    nameValue: "Correcta Ejecucion",
    label: "Correcta ejecución",
  },
  {
    value: "3",
    nameValue: "Fiel Cumplimiento y Correcta Ejecución",
    label: "Fiel cumplimiento y Correcta ejecución",
  },
  {
    value: "4",
    nameValue: "Correcta Ejecución y Buen Comportamiento de la Obra",
    label: "Correcta ejecución y Buen comportamiento de la obra",
  },
];

const initialState = {
  firstName: "",
  lastName: "-",
  rut: "",
  email: "",
  phone: "",
  message: "",
  createdAt: newDate.toISOString(),
  factura: "-",
  rutProveedor: "-",
  rutReceptor: "-",
  warrantyTarget: "",
  warrantyId: "",
  tenderAmount: "",
  startDate: "",
  endDate: "",
  typeMoney: "",
  stringAmount: "",
};
const optionsMoney = [
  {
    value: "Pesos",
    label: "$ Peso",
  },
  {
    value: "UF",
    label: "UF Unidad de fomento",
  },
];
const initialOption = "Elija una opción...";

const options = [
  {
    value: "licitación privada",
    label: "Licitación Privada / Trato Directo",
  },
  {
    value: "solicitud de cobro",
    label: "Solicitud de cobro de garantía",
  },
  {
    value: "sugerencia",
    label: "Reclamos / Felicitaciones / Sugerencias",
  },
  {
    value: "cotizacion",
    label: "Solicitud de cotización",
  },
];

const initialRequiredFields = ["firstName", "phone", "email", "rut", "message"];

function Contact() {
  const [formData, setFormData] = useState({ ...initialState });
  const [selectedOption, setSelectedOption] = useState(initialOption);
  const [validateRut, setValidateRut] = useState(false);
  const [requiredFields, setRequiredFields] = useState([
    ...initialRequiredFields,
  ]);
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [loading, setLoading] = useState(false)
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);
  const history = useHistory();

  const inputHandler = (event) => {
    /// ////////////////////////////////////////////////////
    // Primero se saca el input en el que se está escribiendo de los campos incompletos
    setIncompleteFields(
      incompleteFields.filter((field) => field !== event.target.name)
    );
    /// ///////////////////////////////////////////
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const submitHandler = async (event) => {
    const incompleteFieldsArray = checkIncompleteFields(
      requiredFields,
      formData
    );
    setIncompleteFields(incompleteFieldsArray);
    event.preventDefault();
    if (incompleteFieldsArray.length === 0) {
      console.log(formData);
      try {
        setLoading(true)
        await sendContactForm(formData);
        history.push("/infoReceived");
        setLoading(false)
      } catch (error) {
        createNotification(
          "error",
          "Por favor intentelo más tarde.",
          "Ha ocurrido un error al enviar su formulario"
        );
        setLoading(false)
      }
      //vuelve los inputs a los estados normales
      setFormData(initialState);
      setSelectedOption(initialOption);
    }
  };
  const validateButton = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["firstName"],
      formData
    );
    if (
      incompleteFieldsArray.length == 0 &&
      validateRut &&
      !isError &&
      !isErrorEmail
    ) {
      setSubmitButtonEnabled(false);
    } else {
      setSubmitButtonEnabled(true);
    }
  };

  useEffect(() => {
    validateButton();
  }, [formData]);

  const inputHandlerFono = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const regex = /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/;

    if (!value || regex.test(value) === false) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const inputHandlerEmail = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!value || regex.test(value) === false) {
      setIsErrorEmail(true);
    } else {
      setIsErrorEmail(false);
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const commentTextArea = (placeholder) => (
    <textarea
      name="message"
      className={`${incompleteFields.includes("message") ? "input_error" : ""}`}
      placeholder={placeholder}
      value={formData.message}
      onChange={inputHandler}
    />
  );
  const { tenderAmount, warrantyTarget, startDate, endDate, warrantyId, typeMoney,
    stringAmount } =
    formData;
  //Renderiza los formularios para cada accion que se requiera
  useEffect(() => {
    setIncompleteFields([]);
    setFormData({
      firstName: formData.firstName,
      lastName: formData.lastName,
      rut: formData.rut,
      rutProveedor: formData.rutProveedor,
      rutReceptor: formData.rutReceptor,
      factura: formData.factura,
      createdAt: formData.createdAt,
      email: formData.email,
      phone: formData.phone,
      tenderAmount: formData.tenderAmount,
      warrantyId: formData.warrantyId,
      warrantyTarget: formData.warrantyTarget,
      message: formData.message,
      typeMoney: formData.typeMoney,
      stringAmount: formData.stringAmount,
    });
    setRequiredFields([...initialRequiredFields]);
    if (selectedOption === "cotizacion") {
      setRequiredFields([...requiredFields, "warrantyId", "warrantyTarget"]);
    }
  }, [selectedOption]);

  useEffect(() => {
    setIncompleteFields([]);
    if (selectedOption === "licitación privada") {
      setFormData({
        ...formData,
        message: `(Licitacion Privada / Trato Directo) Monto de licitacion : ${formData.tenderAmount} / Fecha :  ${formData.startDate} - ${formData.endDate}`,
        tenderAmount: formData.tenderAmount,
        startDate: formData.startDate,
        endDate: formData.endDate,
      });
      setRequiredFields([...initialRequiredFields]);
      if (selectedOption === "cotizacion") {
        setRequiredFields([...requiredFields, "warrantyId", "warrantyTarget"]);
      }
    } else {
      setFormData({
        ...formData,
        tenderAmount: formData.tenderAmount,
        message: "",
      });
    }
  }, [tenderAmount, selectedOption, startDate, endDate]);

  useEffect(() => {
    setIncompleteFields([]);
    if (selectedOption === "cotizacion") {
      setFormData({
        ...formData,
        message: `${formData.message}`,
        rutProveedor: `${formData.warrantyTarget}`,
        rutReceptor: `${formData.warrantyId}`
      });
      console.log(formData.message.length);

      setRequiredFields([...initialRequiredFields]);
      if (selectedOption === "cotizacion") {
        setRequiredFields([...requiredFields, "warrantyId", "warrantyTarget"]);
      }
    } else {
      setFormData({
        ...formData,
        message: "",
      });
    }
  }, [warrantyId, warrantyTarget]);
  useEffect(() => {
    if (typeMoney.length > 0) {
      setFormData({
        ...formData,
        tenderAmount: `${formData.stringAmount} ${formData.typeMoney}`,
        typeMoney: formData.typeMoney,
      });
      console.log(typeMoney.length);
    }
  }, [typeMoney]);

  return (
    <section className="contact__container" id="contact">
      <form className="form-contact" onSubmit={submitHandler}>
        <div className="form-contact__text">
          <h2>Hola, ¿Necesitas ayuda?</h2>
        </div>
        <div className="form-contact__form">
          <div className="form-contact__inputs">
            <InputForm
              type="text"
              name="firstName"
              label="Nombre y Apellido"
              value={formData.firstName}
              handlerChange={inputHandler}
              error={incompleteFields.includes("firstName")}
            />
            <TextField
              type="number"
              name="phone"
              className="mt-3"
              value={formData.phone}
              id="outlined-basic"
              label="Teléfono Móvil"
              title="Teléfono Móvil"
              variant="outlined"
              onChange={inputHandlerFono}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+56</InputAdornment>
                ),
              }}
            />
            {isError && <p className="invalid_phone">Telefono invalido</p>}
            <InputForm
              type="email"
              name="email"
              label="Email"
              value={formData.email}
              handlerChange={inputHandlerEmail}
              error={incompleteFields.includes("email")}
            />
            {isErrorEmail && <p className="invalid_phone">Email invalido</p>}
            <InputRut
              formData={formData}
              setFormData={setFormData}
              validateRut={setValidateRut}
              name="rut"
              label="Rut o Pasaporte"
              className="input__rut__contact"

            />
          </div>
          <div className="form-contact__optional_inputs">
            <span className="input__title form_contact_second_title">
              Cuéntanos, en qué podemos ayudarte
            </span>
            <div className="form_contact_select">
              <InputSelect
                name="optionValue"
                value={selectedOption}
                options={options}
                handlerChange={({ target: { value } }) =>
                  setSelectedOption(value)
                }
                defaultValue="Elija una opción..."
              />
            </div>
            {selectedOption === "licitación privada" && (
              <div className="case_licitacionPrivada_container w-100">
                <div className="d-flex">
                  <InputForm
                    type="number"
                    name="stringAmount"
                    title="Monto Promedio Mensual de tu Empresa"
                    placeholder="$99.999.999"
                    value={formData.stringAmount}
                    handlerChange={inputHandler}
                  />
                  <InputSelect
                    name="typeMoney"
                    label="Finalidad"
                    title="Elige el tipo de moneda"
                    className="padding__form ms-2 mt-4"
                    value={formData.typeMoney}
                    options={optionsMoney}
                    handlerChange={inputHandler}
                    error={incompleteFields.includes("typeMoney")}
                    defaultValue="Selecciona"
                  />
                </div>
                <DateInputs formData={formData} setFormData={setFormData} />
              </div>
            )}
            {selectedOption === "solicitud de cobro" &&
              commentTextArea("Solicitud de cobro...")}
            {selectedOption === "sugerencia" &&
              commentTextArea(
                "Escriba aqui su felicitación, reclamo o sugerencia..."
              )}
            {selectedOption === "cotizacion" && (
              <CaseCotizacion
                formData={formData}
                setFormData={setFormData}
                incompleteFields={incompleteFields}
                commentTextArea={commentTextArea}
              />

            )}
          </div>
        </div>
        <div className="form-contact__button d-flex justify-content-center">

          <button className="button__blue w-75 " type="submit" value="Enviar"
            disabled={submitButtonEnabled}>
            {
              loading ?
                <Spinner /> :
                <p className="m-0">
                  Enviar
                </p>
            }

          </button>

        </div>
      </form>
      <div className="hand__contact">
        <img src={handscontract}></img>
      </div>
    </section>
  );
}

export default Contact;
