import React, { useState, useEffect, useReducer } from 'react';
import './styles.css';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import StepOne from './steps/stepOne';
import StepTwo from './steps/stepTwo';
import StepThree from './steps/stepThree';
import StepFour from './steps/stepFour';
import StepFive from './steps/stepFive';
import StepSix from './steps/stepSix';
import ErrorStep from './steps/errorStep';
import { simulate, sendSimulation } from '../../utils/apis/warrantiesApi';
import { updateShoppingCart, createNotification } from '../../utils/helpers/helpers';
import ModalTemplate from '../ModalTemplate';
import { addToCart } from '../../store/actions/shoppingCartActions';

const stepsReducer = (state = 1, action) => {
  switch (action.type) {
    case 'next':
      return state + 1;
    case 'goBack':
      return state - 1;
    case 'reset':
      return 1;
    case 'error':
      return 7;
    default:
      return 1;
  }
};

const tenderWarrantyOptions = [
  {
    value: '-1',
    nameValue: 'Seriedad de oferta',
    label: 'Seriedad de oferta',
  },
  {
    value: '1',
    nameValue: 'Fiel cumplimiento',
    label: 'Fiel cumplimiento',
  },
  {
    value: '2',
    nameValue: 'Correcta Ejecucion',
    label: 'Correcta ejecución',
  },
  {
    value: '3',
    nameValue: 'Fiel Cumplimiento y Correcta Ejecución',
    label: 'Fiel cumplimiento y Correcta ejecución',
  },
  {
    value: '4',
    nameValue: 'Correcta Ejecución y Buen Comportamiento de la Obra',
    label: 'Correcta ejecución y Buen comportamiento de la obra',
  },
];

function TenderWarrantiesModal({
  // show,
  closeModal,
  formData,
  dataUser,
  tender,
  userCompanies,
  ...props
}) {


  const [warrantiesData, setWarrantiesData] = useState({
    tenderCode: tender.Code,
    tenderId: tender.TenderId,
    tenderMoneyUnit: tender.Currency,
    tenderAmountString: '',
    tenderBuyerName: tender.TenderBuyer.Name,
    tenderBuyerRut: tender.TenderBuyer.Rut,
    tenderBuyerAddress2: tender.TenderBuyer.UnitLocation || '',
    tenderBuyerAddress3: tender.TenderBuyer.UnitArea || '',
    tenderbuyerAddress: tender.TenderBuyer.UnitAddress || '',
    companyRut: '',
    companyName: '',
    glosa: '',
    tenderWarrantyTarget: '',
    startDate: '',
    endDate: '',
    contactName: dataUser.ClientName,
    contactEmail: dataUser.Email,
    contactPhone: dataUser.Phone,
  });

  const [warrantiesResponse, setWarrantiesResponse] = useState({});
  const [errorModal, setErrorModal] = useState(<ErrorStep
    errorTitle="Ha ocurrido un error en su solicitud"
    errorSubtitle="Por favor intente nuevamente"
  />);
  const [step, dispatch] = useReducer(stepsReducer, 1);
  const history = useHistory();

  // actions of the stepsReducer
  const next = () => {
    dispatch({ type: 'next' });
  };

  const goBack = () => {
    dispatch({ type: 'goBack' });
  };

  const reset = () => {
    dispatch({ type: 'reset' });
  };
  const error = () => {
    dispatch({ type: 'error' });
  };
  /* ------------------------ */

  const createErrorComponent = (title, subtitle,customData=null, actionValue = null, action = null) => (
    <ErrorStep
      errorTitle={title}
      errorSubtitle={subtitle}
      action={action}
      actionValue={actionValue}
      executiveEmail = {customData.ExecutiveEmail}
      executiveName = {customData.ExecutiveName}
      executivePhone = {customData.ExecutivePhone}
    />
  );

  const simulateWarranties = async () => {
    console.log('Data to simulate: ', warrantiesData);

    try {
      const res = await simulate(warrantiesData);
      console.log('Simulation Response:', res);
      setWarrantiesResponse(res);
      next();
    } catch (err) {
      error();
      console.error(err);
    }
  };

  const sendSimulationWarranties = async () => {
    // eslint-disable-next-line max-len
    const { nameValue: tenderWarrantyTargetName } = tenderWarrantyOptions.find((option) => option.value === warrantiesResponse.TenderWarrantyTarget);
    const sendSimulationData = {
      ...warrantiesResponse,
      TenderWarrantyTarget: tenderWarrantyTargetName,
      clientId: dataUser.ClientId,
      TypeCode: 0,

    };
    console.log('Request new data:', sendSimulationData);
    try {
      const res = await sendSimulation(sendSimulationData);
      console.log(res);
      if (res.Message) {
        const errorComponent = createErrorComponent(
          'Tu solicitud no pudo ser ingresada',
          'Uno de nuestros ejecutivos se pondrá en contacto',
          res
        );
        setErrorModal(errorComponent);
        error();
      } else if (res.ScoringState === 'Error') {
        const errorComponent = createErrorComponent(
          'Tu solicitud no pudo ser ingresada',
          'Uno de nuestros ejecutivos se pondrá en contacto',
          res
        );
        setErrorModal(errorComponent);
        error();
      } else {
        await updateShoppingCart(dataUser.ClientId, props.addToCart);
        reset();
        closeModal();
        createNotification('success', 'Ir a pagar', 'Licitación agregada correctamente', 3000, () => history.push('/cart'));
      }
    } catch (err) {
      console.log(err);
      error();
    }
  };

  useEffect(() => {
    if (userCompanies.companies.length === 0) {
      const errorComponent = createErrorComponent(
        'Usted no posee compañias guardadas',
        'Por favor agregue una en el siguiente link',
        'Agregar Compañia',
        () => { history.push('/storeCompany'); },
      );
      setErrorModal(errorComponent);
      error();
    }
  }, []);

  useEffect(() => {
    if (step === 4) {
      simulateWarranties();
    }
    if (step === 6) {
      sendSimulationWarranties();
    }
  }, [step]);

  // if (show) {
  return (
    <ModalTemplate closeModal={() => closeModal()}>
      <div className="modal-warranties__container">

        {step === 1
              && (
              <StepOne
                warrantiesData={warrantiesData}
                userCompanies={userCompanies}
                userId={dataUser.ClientId}
                setWarrantiesData={setWarrantiesData}
                next={next}
              />
              )}
        {step === 2
              && (
              <StepTwo
                contactName={warrantiesData.contactName}
                contactEmail={warrantiesData.contactEmail}
                contactPhone={warrantiesData.contactPhone}
                next={next}
                goBack={goBack}
              />
              )}
        {step === 3
              && (
              <StepThree
                warrantiesData={warrantiesData}
                setWarrantiesData={setWarrantiesData}
                tenderWarrantyOptions={tenderWarrantyOptions}
                next={next}
                goBack={goBack}
              />
              )}
        {step === 4
              && <StepFour />}
        {step === 5
              && (
              <StepFive
                data={warrantiesResponse}
                next={next}
              />
              )}
        {step === 6
              && <StepSix />}
        {step === 7
        && (
          errorModal
        )}
      </div>
    </ModalTemplate>
  );
  // s;
}

const mapDispatchToProps = (dispatch) => ({
  addToCart: (payload) => dispatch(addToCart(payload)),
});


export default connect(null, mapDispatchToProps)(TenderWarrantiesModal);
