import React from "react";
import "./styles.css";
import { NotificationContainer } from "react-notifications";
import Header from "../Header";
import WhatsappButton from "../WhatsappButton";
import Footer from "../Footer";

function Layout(props) {
  return (
    <div className="layout__container">
      <Header />
      <NotificationContainer />
      <div className="layout_container">{props.children}</div>
      <WhatsappButton />
      <Footer />
    </div>
  );
}

export default Layout;
