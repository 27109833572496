import React, { useEffect } from "react";
import "./styles.css";
import { Link, NavLink, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import InfoDisplay from "./InfoDisplay";
import DropdownMenu from "./DropdownMenu";
import useScrollPosition from "../../utils/helpers/Hooks/useScrollPosition";
import chile from "../../assets/images/chile.png";

import shoppingCart from "../../assets/images/shoppingCart.svg";

import { useSelector } from "react-redux";
import { SidebarData } from "./SidebarData";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function Header({
  menuOptions: {
    conocenosOptions,
    nuestrosProductosOptions,
    userOptions,
    userOptionsWithCompany,
  },
  ...props
}) {
  const { isAuth } = props.user;
  const { companies } = props.companies;
  const history = useHistory();
  const location = useLocation();


  const { warranties: warrantiesInCart, loading: cartLoading } = useSelector(
    (state) => state.shoppingCart
  );

  const scrollPosition = useScrollPosition();

  return (
    <>
      {location.pathname === "/formGarantia" || location.pathname === "/formCapitalTrabajo" || location.pathname === "/formFactoring" ?
        <header
          className={`header__landing ${scrollPosition > 3 ? "header__container--scrolled__landing" : ""
            } `}
        >
          <article className="header__container__landing">
            <div
              className="header__logo__inge"
              onClick={() => history.push("/")}
              role="button"
              tabIndex={0}
            />
          </article>
        </header >
        :
        <header
          className={`header ${scrollPosition > 3 ? "header__container--scrolled" : ""
            } `}
        >
          <article className="header__container">
            <nav className="header__nav">
              <div
                className="header__logo"
                onClick={() => history.push("/")}
                role="button"
                tabIndex={0}
              />

              <ul className="nav__link-container">

                <li className="nav__link">
                  <NavLink
                    className={({ isActive }) => `link ${isActive ? "active" : ""}`}
                    to="/Quoter"
                  >
                    Cotizador
                  </NavLink>
                </li>
                <li className="nav__link">
                  <NavLink
                    className={({ isActive }) => `link ${isActive ? "active" : ""}`}
                    to="/tenders"
                  >
                    Buscador de Licitaciones
                  </NavLink>
                </li>

                <li className="nav__link">
                  <NavLink
                    className={({ isActive }) => `link ${isActive ? "active" : ""}`}
                    to="/checkWarranty"
                  >
                    Verificador
                  </NavLink>
                </li>
                <li className="nav__link">
                  <NavLink
                    className={({ isActive }) => `link ${isActive ? "active" : ""}`}
                    to="/about"
                  >
                    Sobre Nosotros
                  </NavLink>
                </li>
              </ul>

              {isAuth ? <Sidebar {...props} /> : null}

              {isAuth ? (
                <InfoDisplay
                  {...props}
                  userOptions={
                    companies.length > 0 ? userOptionsWithCompany : userOptions
                  }
                />
              ) : (
                <section className="nav__buttons">
                  <button
                    onClick={() => history.push("/login")}
                    type="button"
                    value="Ingresar"
                    className="button__sesion"
                    style={{ top: "0px" }}
                  >
                    Iniciar Sesión
                  </button>
                  <button
                    onClick={() => history.push("/newUser")}
                    type="button"
                    value="Registrarse"
                    className="button__sesion"
                    style={{ top: "0px" }}
                  >
                    Registrarse
                  </button>
                </section>
              )}

            </nav>
          </article>
        </header>
      }

    </>

  );
}

export default Header;
