import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { connect, useSelector } from "react-redux";
import PaymentModal from "../../components/PaymentModal";
import CartItem from "../../components/CartItem";
import CartTotal from "./CartTotal";
import { sendToPay } from "../../utils/apis/warrantiesApi";
import LoadingScreen from "../../components/Spinner/LoadingScreen";
import cartShop from "../../assets/images/cartShop.svg";
import { useEffect } from "react";


const dataObject = {
  monto: 0,
  glosa: " ",
  productos: [
    {
      nombre_producto: "",
      descripcion_producto: "prueba",
      precio_producto: 0,
    },
  ],
  nom_pag: " ",
  mail_pag: " ",
  warrantiesIds: [0],
  companyUserId: 0,
};

function ShoppingCart({ shoppingCart, user, companies }) {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [totalPrice, setTotalPrice] = useState();
  const [cart, setCart] = useState([]);
  const [users, setUsers] = useState({});
  const { loading: loadingCart } = useSelector((state) => state.shoppingCart);
  const [data, setData] = useState(dataObject);
  const history = useHistory();
  const { ClientId, Email, ClientName } = useSelector(
    ({ user: { dataUser } }) => dataUser
  );

  const { dataUser } = user;
  const { warranties } = shoppingCart;
  
  const getWarranty = async () => {
    setCart(warranties);
    setUsers(dataUser);
  };

  useEffect(() => {
    getWarranty();
  }, [totalPrice]);

  const goToPayment = (total) => {
    if (warranties[0].InsuranceCompanyId == -3) {
      data.companyUserId = dataUser.ClientId;
      data.mail_pag = dataUser.Email;
      data.nom_pag = dataUser.ClientName;
      data.monto = warranties[0].WarrantyCost;
      data.glosa = warranties[0].Glosa;
      data.productos[0].nombre_producto = warranties[0].WarrantyType;
      data.productos[0].descripcion_producto = warranties[0].WarrantyInfo;
      data.productos[0].precio_producto = warranties[0].WarrantyCost;

      data.warrantiesIds = warranties.map(
        (warranty, index) => console.log(warranty)
        // warranty.WarrantyId
        
      );

      setLoad(true)
      sendToPay(data).then((result) => {        
        window.open(result.RedirectionFormUrl);
        setLoad(false)
        
      });
    } else {
      setShowPaymentModal(true);
      setTotalPrice(total);
      setData();
    }
  };
  return (
    <article className="contenedor_carrito mt-5">
      <section className="cart_container_empty">
        {cart.length === 0 ? (
          <LoadingScreen loadingText="Cargando Datos de Garantía..." />
        ) : (
          cart.map((warranty, index) => (
            <CartItem
              item={warranty}
              key={index}
              company={companies.defaultCompany}
              user={user}
            />
          ))
        )}
        {cart.length === 0 ? (
          <LoadingScreen />
        ) : (
          <CartTotal goToPayment={goToPayment} cart={cart} user={user} load={load} />
        )}
      </section>
      {showPaymentModal && (
        <PaymentModal
          closeModal={() => setShowPaymentModal(false)}
          shoppingCart={cart}
          totalPrice={totalPrice}
          totalPriceDesc={totalPrice.ShoppingCartTotal}
          user={user}
        />
      )}
    </article>
  );
}
const mapStateToProps = ({ user, shoppingCart, companies }) => ({
  shoppingCart,
  user,
  companies,
});

export default connect(mapStateToProps)(ShoppingCart);
