import "./style.css";
import ready from "../../assets/images/ready.svg"
import check from "../../assets/images/check.svg"
import Resumen from "../AddMember/Resumen";



export default function AccountCreated() {
  return (
    <section className="register__container">
      <div className="container-wizzard">
        <div className="text-wizzard">
          <p>Registro</p>
          <p>+Empresa</p>
          <p>+Integrante</p>
        </div>
        <div className="wizzard-accountCreated">
          <img src={check}></img>
          <div className="stick-wizard-step3"></div>
        </div>
      </div>
      <div className="container-forms-addmember">
        <Resumen />
        <div className="container-resumen">
        </div>
        <div className="form__container__accountCreated">
          <p className="text-1-createdAccount">¡YA ESTÁ TODO LISTO!</p>
          <img src={ready} className="img-createdAccount"></img>
          <p className="text-2-createdAccount">Cuenta creada con éxito</p>
        </div>
      </div>
      <button className="btn-add-later-step2">Ir al inicio</button>
    </section>
  );
}