import React from "react";
import "./styles.css";
import empresa1 from "../../../../assets/images/empresa1.png";
import empresa2 from "../../../../assets/images/empresa2.png";
import empresa3 from "../../../../assets/images/empresa3.png";
import empresa4 from "../../../../assets/images/empresa4.png";
import empresa5 from "../../../../assets/images/empresa5.png";
import empresa6 from "../../../../assets/images/empresa6.png";
import empresa7 from "../../../../assets/images/empresa7.png";
import empresa8 from "../../../../assets/images/empresa8.PNG";
import empresa9 from "../../../../assets/images/empresa9.png";
import empresa10 from "../../../../assets/images/empresa10.jpg";
import empresa11 from "../../../../assets/images/empresa11.png";
import empresa12 from "../../../../assets/images/empresa12.png";
import empresa13 from "../../../../assets/images/empresa13.jpg";
import empresa14 from "../../../../assets/images/empresa14.png";
import empresa15 from "../../../../assets/images/empresa15.png";
import seguros from "../../../../assets/images/seguros.png";
import garantias from "../../../../assets/images/garantias.png";
import click from "../../../../assets/images/click.png";
import edit from "../../../../assets/images/edit2.png";
import check from "../../../../assets/images/verified.png";


function Collaborators() {
  const images = [
    empresa1,
    empresa2,
    empresa3,
    empresa4,
    empresa5,
    empresa6,
    empresa7,
    empresa8,
    empresa9,
    empresa10,
    empresa11,
    empresa12,
    empresa13,
    empresa14,
    empresa15,
  ];

  const icons = [
    {
      title: "Garantías",
      image: <img src={garantias} alt="Seguros" />,
      text: "Obtén los documentos que necesites para tu postulación a licitación",
    },
    {
      title: "Seguros",
      image: <img src={seguros} alt="Seguros" />,
      text: "Obten el seguro que neceitas, personal o para tu empresa.",
    },
  ];
  return (
    <article className="collaborators__container">
      <h1 className="title__main mt-1">¿Cómo Solicitar?</h1>
      <section className="elipse ">
        <div className="caja-solicitar">
          <div className="dot">
            <h2>1</h2>
          </div>
          <h3>Registra</h3>
          <p>Completa tus datos y los de tu empresa</p>
          <img src={edit} />
        </div>
        <div className="caja-solicitar">
          <div className="dot">
            <h2>2</h2>
          </div>
          <h3>Cotiza</h3>
          <p>Selecciona el producto que necesitas</p>
          <img src={click} />
        </div>
        <div className="caja-solicitar">
          <div className="dot">
            <h2>3</h2>
          </div>
          <h3>Firma</h3>
          <p>Firma ONLINE  y listo obtén tus documentos</p>
          <img src={check} />
        </div>
      </section>      
      <section className="collaborators__banner">
        <div className="collaborators__banner_track">
          {images.map((image, index) => (
            <img src={image} alt={`enterprise ${index + 1}`} key={index} />
          ))}
          {images.map((image, index) => (
            <img src={image} alt={`enterprise ${index + 1}`} key={index} />
          ))}
        </div>
      </section>
    </article>
  );
}

export default Collaborators;
