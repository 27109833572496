// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import InputForm from '../../InputForm';
import SquareButton from '../../SquareButton';
import './styles.css';
import InputRut from '../../InputForm/InputRut';
import { checkIncompleteFields } from '../../../utils/helpers/helpers';

const requiredFields = [
  'name', 'rut', 'address', 'email'
]

function OwnerForm({ newOwnerData, setNewOwnerData, nextStep }) {
  // const [inputErrors, setInputErrors] = useState({});
  const [incompleteFields, setIncompleteFields] = useState([]);

  const inputHandler = (event) => {
    const key = event.target.name;
    const { value } = event.target;

    setIncompleteFields(incompleteFields.filter((field) => field !== key));

    setNewOwnerData((data) => ({
      ...data,
      [key]: value,
    }));
  };

  // const submitHandler = () => {

  // };
  function submitForm(){
    const incompleteFieldsArray = checkIncompleteFields(requiredFields, newOwnerData);
    setIncompleteFields(incompleteFieldsArray);
    if(incompleteFieldsArray.length > 0) {
      return;
    }
    nextStep();
  }

  // const validateInputs = ({name, rut, address, email}) => {
  //   const errors = {};
  //   if (name === '') {
  //     errors.name = 'Por favor ingrese un nombre';
  //   }
  //   if (newOwnerData.rut === '') {
  //     errors.name = 'Por favor ingrese un nombre';
  //   }
  //   if (newOwnerData.name === '') {
  //     errors.name = 'Por favor ingrese un nombre';
  //   }

  //   setInputErrors(errors);
  // };

  return (
    <div className="step_container">
      <div className="step_content">
        <InputForm
          type="text"
          name="name"
          title="Nombre y apellido"
          value={newOwnerData.name}
          styles={{ width: '95%' }}
          handlerChange={inputHandler}
          error={incompleteFields.includes('name')}
          // error={inputErrors.name}
        />
        <InputRut
          name="rut"
          title="Rut"
          setFormData={setNewOwnerData}
          formData={newOwnerData}
          styles={{ width: '95%' }}
          error={incompleteFields.includes('rut')}
          handlerChange={inputHandler}
        />
        <InputForm
          type="text"
          name="address"
          title="Dirección"
          value={newOwnerData.address}
          styles={{ width: '95%' }}
          handlerChange={inputHandler}
          error={incompleteFields.includes('address')}
        />
        <InputForm
          type="text"
          name="email"
          title="Email"
          value={newOwnerData.email}
          styles={{ width: '95%' }}
          handlerChange={inputHandler}
          error={incompleteFields.includes('email')}
        />
      </div>
      <div
        className="step_actions"
      >
        <SquareButton
          value="Siguiente"
          type="text"
          clickHandler={submitForm}
        />
      </div>
    </div>
  );
}

export default OwnerForm;
