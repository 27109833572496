import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./screens/Home/index";
import Login from "./screens/Login";
import About from "./screens/AboutUs";
import CheckWarranty from "./screens/CheckWarranty";
import Tenders from "./screens/Tenders";
import PostRegister from "./screens/PostRegister";
import Validate from "./screens/ValidateEmail";
import Faqs from "./screens/Faqs";
// import Welcome from './screens/Welcome';Warranty
import Documents from "./screens/Documents";
import EditCompany from "./screens/EditCompany";
import Profile from "./screens/Profile";
import StoreCompany from "./screens/StoreCompany";
import ShoppingCart from "./screens/ShoppingCart";
import paymentSuccess from "./screens/Payment/success";
import paymentFailed from "./screens/Payment/failed";
import Layout from "./components/Layout";
import CheckRoute from "./components/CheckRoute";
import RestoreScroll from "./components/ScrollToTop";
import ValidatingScreen from "./screens/ValidatingScreen";
import VerifyKhipu from "./screens/Payment/VerifyKhipu";
import Insurance from "./screens/Insurance";
import Mobility from "./screens/Mobility/";
import Patrimony from "./screens/Patrimony";
import Equipments from "./screens/Equipaments";
import Others from "./screens/Others";
import Received from "./screens/info received";
import AddMember from "./screens/AddMember";
import AccountCreated from "./screens/AccountCreated";
import NaturalPerson from "./screens/NaturalPerson";
import MyExecutive from "./screens/MyExecutive";
import Notifications from "./screens/Notifications";
import FormRegister from "./screens/FormRegister/";
import ForgotPassword from "./screens/ForgotPassword/ForgotPassword";
import NewPassword from "./screens/NewPassword";
import Quoter from "./screens/Quoter/index";
import Factoring from "./screens/Factoring";
import WarrantySimulation from "./screens/WarrantySimulation";
import Credit from "./screens/Credits/credit";
import { CartNotAuth } from "./screens/ShoppingCart/CartNotAuth";
import { Landing } from "./pages/landing/Landing";
import ModalSummary from "./components/simulationSimple/ModalSummary";
import Welcome from "./screens/Welcome";

function App() {
  return (
    <Router>
      <RestoreScroll />
      <Layout>
        <Switch>
          <CheckRoute exact path="/" component={Home} />
          <CheckRoute exact path="/pay/:id" component={CartNotAuth} />
          <CheckRoute type="guest" exact path="/login" component={Login} />
          <CheckRoute
            type="guest"
            exact
            path="/validate"
            component={Validate}
          />
          <CheckRoute exact path="/about" component={About} />
          <CheckRoute exact path="/checkWarranty" component={CheckWarranty} />
          <CheckRoute exact path="/tenders" component={Tenders} />
          <CheckRoute exact path="/faqs" component={Faqs} />
          <CheckRoute
            exact
            path="/Authentication/:q"
            component={ValidatingScreen}
          />
          {/* <CheckRoute type="private" exact path="/register/welcome" component={PostRegister} /> */}
          <CheckRoute type="private" exact path="/welcome" component={Welcome} />
          <CheckRoute
            type="private"
            exact
            path="/documents"
            component={Documents}
          />
          <CheckRoute
            type="private"
            exact
            path="/editCompany"
            component={EditCompany}
          />
          <CheckRoute
            type="private"
            exact
            path="/profile"
            component={Profile}
          />
          <CheckRoute
            type="private"
            exact
            path="/storeCompany"
            component={StoreCompany}
          />
          <CheckRoute
            type="private"
            exact
            path="/cart"
            component={ShoppingCart}
          />
          <CheckRoute
            type="private"
            exact
            path="/payment/success"
            component={paymentSuccess}
          />
          <CheckRoute
            type="private"
            exact
            path="/payment/failed"
            component={paymentFailed}
          />
          <CheckRoute
            type="private"
            exact
            path="/payment/awaitVerification"
            component={VerifyKhipu}
          />
          <CheckRoute exact path="/insurance" component={Insurance} />
          <CheckRoute exact path="/mobility" component={Mobility} />
          <CheckRoute exact path="/patrimony" component={Patrimony} />
          <CheckRoute exact path="/equipments" component={Equipments} />
          <CheckRoute exact path="/others" component={Others} />
          <CheckRoute exact path="/infoReceived" component={Received} />
          <CheckRoute exact path="/landing" component={Landing} />
          <CheckRoute
            type="guest"
            exact
            path="/addMember"
            component={AddMember}
          />
          <CheckRoute
            type="guest"
            exact
            path="/accountCreated"
            component={AccountCreated}
          />
          <CheckRoute
            type="guest"
            exact
            path="/naturalPerson"
            component={NaturalPerson}
          />
          <CheckRoute
            type="private"
            exact
            path="/myExecutive"
            component={MyExecutive}
          />
          
          <CheckRoute
            type="private"
            exact
            path="/notifications"
            component={Notifications}
          />
          <CheckRoute
            type="guest"
            exact
            path="/newUser"
            component={FormRegister}
          />
          <CheckRoute exact path="/ForgotPassword" component={ForgotPassword} />
          <CheckRoute exact path="/NewPassword" component={NewPassword} />
          <CheckRoute exact path="/Quoter" component={Quoter} />
          <CheckRoute exact path="/factoring" component={Factoring} />
          <CheckRoute
            exact
            path="/WarrantySimulation"
            component={WarrantySimulation}
          />
          <CheckRoute exact path="/Credit" component={Credit} />
          <CheckRoute exact path="/modalSummary" component={ModalSummary} />
          <CheckRoute exact path="/formGarantia" component={Landing} />
          <CheckRoute exact path="/formCapitalTrabajo" component={Landing} />
          <CheckRoute exact path="/formFactoring" component={Landing} />
          <Route render={() => <Redirect to={{ pathname: "/" }} />} />
        </Switch>
      </Layout>

    </Router>
  );
}

export default App;
