import React, { useEffect } from 'react';
import './styles.css';
import Spinner from '../Spinner';

function Button({
  value, type, clickHandler, loading, styles, icon, className, disabled, submitButtonEnabled
}) {
  useEffect(() => {}, [loading]);

  return (
    <button
      className={`${className && className}`}
      type={type}
      onClick={clickHandler}
      style={{ ...styles }}
      disabled={disabled && disabled}
      
    >

      {loading ? <Spinner />
        : (
          <div className="button__content">
            {icon && <img className="button__content__icon" src={icon} alt="button icon" />}
            <span className="button__content__text">{value}</span>
          </div>
        )}
    </button>
  );
}

export default Button;
