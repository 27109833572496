/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import GA4React from 'ga-4-react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './Routes';
// import reportWebVitals from './reportWebVitals';
import './index.css';
import { store, persistor } from './store';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

try {
  setTimeout((_) => {
    const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE);
    ga4react.initialize().catch((err) => console.error(err));
  }, 4000);
} catch (err) {
  console.error(err);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
