/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './styles.css'
import { useHistory, NavLink, useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import IngeLogo from '../../assets/images/Iso_inge.svg';
import MobileDropdownLink from './MobileDropdownLink';
import useScrollPosition from '../../utils/helpers/Hooks/useScrollPosition';
import Button from '../Button';
import CompanySelector from '../CompanySelector';
import CartIcon from './CartIcon';
import * as FaIcons from "react-icons/fa"
import { FaTimes } from "react-icons/fa"
import * as AiIcons from "react-icons/ai"

import { IconContext } from 'react-icons';
import { SidebarData } from './SidebarData';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import logo from "../../assets/images/Iso_inge.svg";



function MobileHeader({
  menuOptions: {
    conocenosOptions, userOptions, userOptionsWithCompany,
  },
  user: { isAuth, dataUser: { ClientName } },
  companies: { companies, defaultCompany }, ...props
}) {

  const [navLinks, setNavLinks] = useState([])
  const [open, setOpen] = useState(false)


  useEffect(() => {
    const nav = [{ name: "algo", path: "/" }, { name: "algoMas", path: "/" },]
    setNavLinks(nav);
  }, [])


  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const scrollPosition = useScrollPosition();
  const [sidebar, setSidebar] = useState(false)
  const showSidebar = () => setSidebar(!sidebar)
  const showSidebarHidden = () => setSidebar(sidebar)
  const location = useLocation();




  const navigate = (path) => {
    history.push(
      `${path}`);
  };

  const logout = () => {
    props.doLogout();
    props.resetCompanies();
    props.resetCart();
    // setShowMenu(false);
    history.push('/');
  };


  return (

    <header className={`header ${scrollPosition > 3 ? 'header__container--scrolled' : ''} `}
    >
      <article className="header__container">
        {location.pathname === "/formGarantia" || location.pathname === "/formCapitalTrabajo" || location.pathname === "/formFactoring" ?
          <div
            className="header__logo__inge"
            // onClick={showSidebar}
            onClick={() => history.push("/")}

            role="button"
            tabIndex={0}
          />
          : <div
            className="header__logo header__logo--mobile"
            // onClick={showSidebar}
            onClick={() => history.push("/")}

            role="button"
            tabIndex={0}
          />}
        <section className="header_left_container__mobile">
          <CartIcon style={{ fontSize: '27px', color: 'var(--primary-color)' }} />
          <div
            className="mobile_menu_toggler"
            role="button"
            tabIndex={0}
          />
          <div className='navbar'>
            <Link to="#" className='menu-bars'>
              <FaIcons.FaBars onClick={showSidebar} style={{ fontSize: '2rem' }} />
            </Link>
          </div>
          <article
            onClick={showSidebar}
            className={sidebar ? "body__navBar2 active" : "body__navBar2"}
          >
          </article>

          <section
            className="body__navBar"
          >
            <nav className={sidebar ? "nav-menu active" : "nav-menu"}>

              <ul className='nav-menu-items' >
                <div className='d-flex justify-content-between align-items-center w-100 mb-4'>
                  <img src={logo} alt="" style={{ height: '3rem' }} />
                  <Link onClick={showSidebar} to="#" className=' menu-bars'>
                    <FaTimes />
                  </Link>
                </div>
                <li className='navbar-toggle'>
                  {isAuth && (

                    < div className="mobile_menu_logedUser"
                    >
                      <p className="mobile_menu_name">
                        Bienvenido
                        {' '}
                        {ClientName || '.'}
                      </p>
                      {companies.length > 0 && <CompanySelector />}
                      <MobileDropdownLink
                        link={{ label: 'Empresa' }}
                        // showMenu={showMenu}
                        // onClick={!sidebar}
                        setShowMenu={setShowMenu}
                        options={companies.length > 0 ? userOptionsWithCompany : userOptions}

                      />
                    </div>
                  )}
                  <Link to="/" className="nav-text mt-4" onClick={showSidebar}>
                    <span>inicio</span>
                  </Link>
                  <Link to="/quoter" className="nav-text" onClick={showSidebar}>
                    <span>cotizador</span>
                  </Link>
                  <Link to="/tenders" className="nav-text" onClick={showSidebar}>
                    <span>Buscador de Licitaciones</span>
                  </Link>
                  <Link to="/checkWarranty" className="nav-text" onClick={showSidebar}>
                    <span>Verificador</span>
                  </Link>
                  <Link to="/about" className="nav-text" onClick={showSidebar}>
                    <span>Sobre Nosotros</span>
                  </Link>
                </li>

              </ul>
              {!isAuth ? (
                <div className="nav__buttons nav_buttons--mobile">
                  <ul className='ul__navbar' onClick={showSidebar}>
                    <li className='users__navbar'>
                      <Button
                        clickHandler={() => history.push('/login')}
                        type="button"
                        onClick={showSidebar}
                        // className={sidebar ? "nav-menu active" : "nav-menu"}
                        value="Ingresar"
                        className="button__blue"
                      />
                    </li>
                    <li className='users__navbar'>
                      <Button
                        clickHandler={() => history.push('/newUser')}
                        onClick={showSidebar}
                        type="button"
                        styles={{ color: 'white', backgroundColor: 'var(--primary-color)' }}
                        value="Registrarse"
                        className="button__blue"

                      />

                    </li>
                  </ul>


                </div>
              ) : (
                <Button
                  clickHandler={() => logout()}
                  type="button"
                  value="Salir"
                  className="button__blue w-100  "
                />
              )}
            </nav>
          </section>


        </section>




      </article>
    </header >
  );
}

export default MobileHeader;
