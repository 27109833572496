import React, { useState } from "react";
import "./styles.css";
import InputForm from "../../components/InputForm";
import Form from "../../components/Form";
import InputPassword from "../../components/InputForm/InputPassword";
import rocket3D from "../../assets/images/rocket3D.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const initialState = {
  user: "",
  password: "",
};

function Login() {
  const [formData, setFormData] = useState({ ...initialState });
  const [unmatching, setUnmatching] = useState(false);
  const history = useHistory();

  const handlerInput = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  return (
    <section className="login-screen__container">
      <div className="form__container">
        <Form
          goTo="/newUser"
          text1="¿No tienes cuenta?"
          text2="Registrate"
          buttonValue="Ingresar"
          className="button__profile__login"
          formData={formData}
          setUnmatching={setUnmatching}
          type="login"
        >
          <img className="rocket-login" src={rocket3D}></img>
          <h2 className="login__title"></h2>
          <div className="login-form__container">
            <InputForm
              type="text"
              name="user"
              label="Email"
              placeholder="nombre@inge.cl"
              value={formData.user}
              handlerChange={handlerInput}
              className="container__login  my-4"
            />
            <InputPassword
              name="password"
              label="Contraseña"
              value={formData.password}
              handlerChange={handlerInput}
              className="container__login"
            />
            <button
              type="button"
              onClick={() => history.push("/forgotPassword")}
              className="login__forgot-password"
            >
              ¿Olvidaste tu contraseña?
            </button>
            {unmatching && (
              <p className="text__user__password__incorrect">
                Usuario o contraseña incorrectos
              </p>
            )}
          </div>
        </Form>
      </div>
    </section>
  );
}

export default Login;
