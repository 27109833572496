import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import './styles.css';

import {
  ApartmentOutlined, AssignmentOutlined, EditOutlined, PersonOutlined,
} from '@material-ui/icons';
import { Logout } from '@mui/icons-material';

import Desktop from './Desktop';
import Mobile from './Mobile';
import { doLogout } from '../../store/actions/userActions';
import { resetCompanies } from '../../store/actions/companiesActions';
import { addToCart, resetCart, setLoadingShoppingCartInStore } from '../../store/actions/shoppingCartActions';
import useWindowDimentions from '../../utils/helpers/Hooks/useWindowDimentions';
import { updateShoppingCart } from '../../utils/helpers/helpers';
import { editUser } from "../../utils/apis/clientUsersApi";

// import { getItemsAndTotalPrice } from '../../utils/helpers/helpers';

const MenuOptions = {
  nuestrosProductosOptions: [
    {
      label: 'Seriedad de Oferta',
      path: '',
      description: 'Garantizamos que lo pactado en la licitación se cumpla. Si te adjudicas la licitación y no se ejecuta el proyecto, se hará efectiva la garantía.',
    },
    {
      label: 'Fiel Cumplimiento',
      path: '',
      description: 'Acreditamos el cumplimento de las condiciones acordadas en el contrato.',

    },
    {
      label: 'Correcto uso de Anticipo',
      path: '',
      description: 'Garantizamos que los recursos sean invertidos de manera correctayen los plazos establecidos en el contrato.',

    },
    {
      label: 'Correcta ejecución de Obra',
      path: '',
      description: 'Garantizamos la correcta ejecución de los trabajos en un plazo posteriorala entrega de la obra.',
    },
    {
      label: 'Canje de Retenciones',
      path: '',
      description: 'Garantiza el cobro de las retenciones en proyectos de construcción. Respalda al beneficiario y genera liquidez para nuestro cliente y permite que su dinero no quede inmovilizado.',
    },
  ],

  conocenosOptions: [
    {
      label: 'Nuestra Historia',
      path: '/about',
    }
  ],

  userOptions: [
    {
      label: 'Crear Empresa',
      path: '/storeCompany',
    },
    {
      label: 'Mis Datos',
      path: '/profile',
    },
  ],

  userOptionsWithCompany: [
    {
      label: 'Crear Empresa',
      path: '/storeCompany',
      icon: <ApartmentOutlined />,
    },
    {
      label: 'Mis Datos',
      path: '/profile',
      icon: <PersonOutlined />,
    },
    {
      label: 'Editar Empresa',
      path: '/editCompany',
      icon: <EditOutlined />,
    },
    {
      label: 'Mis Documentos',
      path: '/documents',
      icon: <AssignmentOutlined />,
    },
  ],
};

function Header({ ...props }) {


  const [width] = useWindowDimentions();
  const dispatch = useDispatch();

  useEffect(async () => {
    const { dataUser: { ClientId }, isAuth } = props.user;
    if (isAuth) {
      dispatch(setLoadingShoppingCartInStore(true));
      await updateShoppingCart(ClientId, props.addToCart);
      dispatch(setLoadingShoppingCartInStore(false));
      console.log('cart updated');
    }
  }, []);

  return (
    <div>
      {width < 1300
        ? (
          <Mobile
            {...props}
            menuOptions={MenuOptions}
            activeCompany={props.companies.defaultCompany}
          />
        )
        : (
          <Desktop
            activeCompany={props.companies.defaultCompany}
            {...props}
            menuOptions={MenuOptions}
          />
        )}
    </div>
  );
}

const mapStateToProps = ({ user, companies, shoppingCart }) => ({
  user,
  companies,
  shoppingCart,

});

const mapDispatchToProps = (dispatch) => ({
  doLogout: () => dispatch(doLogout()),
  resetCompanies: () => dispatch(resetCompanies()),
  addToCart: (payload) => dispatch(addToCart(payload)),
  resetCart: () => dispatch(resetCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
