export const dataOptions = {
    contactFormId: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };
  
  export const initialOption = "Selecciona un seguro";
  
  export const options = [
      {
        value: "Incendio nominado",
        label: "Incendio nominado",
      },
      {
        value: "Seguro todo riesgo",
        label: "Seguro todo riesgo",
      },
  ];
  
  export const initialRequiredFields = [
    "firstName",
    "lastName",
    "email",
    "phone",
    "message",
  ];
  