import React from 'react';
import './styles.css';

// Componente ocupado dentro de ModalTemplate para activar el cierre del modal al tocar fuera.
function BackDrop({ children, onClick }) {
  return (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="backdrop_container"
      onClick={(e) => onClick(e)}
    >
      {children}

    </div>
  );
}

export default BackDrop;
