import requestBaseMethod from './baseApi';


export const assignExecutive = (id) => {
    console.log(id)
    const url = `Companies/${id}/AssignExecutive`;
    return requestBaseMethod(url, 'POST');
  };
  
  export const executiveByRut = (rut) => {
    const url = `Companies/ExecutiveByRut/?rut=${rut}`;
    return requestBaseMethod(url, 'GET');
  };


