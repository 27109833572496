import React from 'react';
import { useHistory } from 'react-router-dom';
import image from '../../assets/images/Imagen6.png';
import './styles.css';

function PostRegister() {
  const history = useHistory();

  return (
    <section className="screen__container screen_center">
      <h1 className="welcome__title">
        ¡Felicitaciones!
      </h1>
      <span className="welcome__subtitle">
        Registro exitoso
      </span>
      <span className="welcome__text">
        ¡Ya eres parte de Garantias Chile Compra, ahora puedes simular y comprar!
      </span>
      <figure className="welcome__image">
        <img src={image} alt="Welcome" />
      </figure>
      <button
        className="welcome__button"
        onClick={() => history.push('/')}
      >
        Comenzar a buscar licitaciones
      </button>
    </section>
  );
}

export default PostRegister;
