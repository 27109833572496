/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { checkIncompleteFields } from '../../../utils/helpers/helpers';
import InputForm from '../../InputForm';
import InputSelect from '../../InputSelect';
import SquareButton from '../../SquareButton';

function StepOne({
  warrantiesData,
  userCompanies,
  setWarrantiesData,
  next,
}) {
  const requiredFields = ['companyName', 'companyRut'];

  const [incompleteFields, setIncompleteFields] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const { companyName, companyRut } = warrantiesData;

  const companiesOptions = () => (
    userCompanies.companies.map((company) => ({
      value: company.CompanyId,
      label: company.CompanyName,

    }))
  );

  const companyChangeHandler = async ({ target }) => {
    const { value: selectedCompanyId } = target;
    // eslint-disable-next-line max-len
    const { CompanyName, CompanyRut } = userCompanies.companies.find((company) => company.CompanyId == selectedCompanyId);
    setWarrantiesData((state) => ({
      ...state,
      companyName: CompanyName,
      companyRut: CompanyRut,
    }));
    setIncompleteFields([]);
  };

  const inputHandler = (event) => {
    setIncompleteFields(incompleteFields.filter((field) => field !== event.target.name));
    const key = event.target.name;
    const { value } = event.target;
    setWarrantiesData((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const nextStep = () => {
    const incompleteFieldsArray = checkIncompleteFields(requiredFields, warrantiesData);
    setIncompleteFields(incompleteFieldsArray);
    if (incompleteFieldsArray.length === 0) {
      next();
    }
  };

  return (
    <div className="step_container">
      <div className="step_content">
        <h2
          className="step_content_title"
        >
          Selecciona tu empresa
        </h2>
        <InputSelect
          name="companyName"
          title="Nombre / Razón social"
          value={companyName}
          options={companiesOptions()}
          handlerChange={companyChangeHandler}
          error={incompleteFields.includes('companyName')}
        />
        <InputForm
          type="text"
          name="companyRut"
          title="Rut"
          value={companyRut}
          handlerChange={inputHandler}
          error={incompleteFields.includes('companyRut')}
          loading={loadingData}
        />
      </div>
      <div
        className="step_actions"
      >
        <SquareButton
          value="Siguiente"
          type="text"
          clickHandler={nextStep}
          styles={{ display: 'block', marginLeft: 'auto' }}
        />
      </div>
    </div>
  );
}

export default StepOne;
