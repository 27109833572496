import "./style.css";
export default function Resumen() {
  const data = localStorage.getItem("data register");
  const getDataRegister = JSON.parse(data);

  const dataCompany = localStorage.getItem("data company");
  const getDataCompany = JSON.parse(dataCompany);

  const dataAddMember = localStorage.getItem("data addMember");
  const getDataMember = JSON.parse(dataAddMember);

  return (
    <div className="container-resumen">
      <div className="resumen">
        <h1 className="info__resumen">RESUMEN</h1>
        <p className="name__resumen">
          {getDataRegister.name}
          {"    "}
          {getDataRegister.lastName}
        </p>
        <div className="container__info__resumen">
          <span>{getDataRegister.rut}</span>
          <span>{getDataRegister.phone}</span>
          <span>{getDataRegister.email}</span>
        </div>
        <p className="name__resumen">{getDataCompany?.reason}</p>
        <div className="container__info__resumen">
          <span>{getDataCompany.rutCompany}</span>
          <span>
            {getDataCompany.address} {getDataCompany.number},{" "}
            {getDataCompany.comuna}
          </span>
          <span>oficina {getDataCompany.officeNumber}</span>
          <span>{getDataCompany.phone}</span>
          <span>{getDataCompany.email}</span>
        </div>
        {/* <div className="container__info__resumen">
          <p className="name__resumen">{getDataMember?.memberName}</p>
          <span>{getDataMember?.memberRut}</span>
          <span>{getDataMember?.memberEmail}</span>
          <span>{getDataMember?.address}</span>
        </div> */}
      </div>

    </div>
  );
}
