import React, { useEffect, useState } from "react";
import InputForm from "../../../../components/InputForm";
import Button from "../../../../components/Button";
import "./styles.css";
import InputSelect from "../../../../components/InputSelect";
import DateInputs from "../../../../components/DateInputs";
import CaseCotizacion from "./CaseCotizacion";
import {
  checkIncompleteFields,
  createNotification,
} from "../../../../utils/helpers/helpers";
import handscontract from "../../../../assets/images/handscontract.svg";

import InputRut from "../../../../components/InputForm/InputRut";
import {
  sendContactForm,
  sendGeneralContact,
  sendLandingContact,
} from "../../../../utils/apis/companiesApi";
import { InputAdornment, TextField } from "@mui/material";
import Spinner from "../../../../components/Spinner";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

let newDate = new Date();

const initialState = {
  firstName: "",
  lastName: "",
  rut: "",
  email: "",
  phone: "",
  message: "",
  warrantyType: "",
  startDate: "",
  endDate: "",
  businessRut: "",
  businessName: "",
  amount: "",
  stringAmount: "",
  tenderId: "",
  factoring: "",
  capitalTrabajo: "",
  garantia: "",
  typeMoney: "",
};
const optionsMoney = [
  {
    value: "Pesos",
    label: "$ Peso",
  },
  {
    value: "UF",
    label: "UF Unidad de fomento",
  },
];

const initialOption = "Elija una opción...";

const options = [
  {
    value: "licitación privada",
    label: "Licitación Privada / Trato Directo",
  },
  {
    value: "solicitud de cobro",
    label: "Solicitud de cobro de garantía",
  },
  {
    value: "sugerencia",
    label: "Reclamos / Felicitaciones / Sugerencias",
  },
  {
    value: "factoring",
    label: "Factoring",
  },
  {
    value: "capitalTrabajo",
    label: "Capital de Trabajo",
  },
];

const initialRequiredFields = [
  "firstName",
  "lastName",
  "phone",
  "email",
  "rut",
  "message",
  "businessRut",
  "businessName",
  "tenderId",
  "warrantyType",
  "factoring",
  "capitalTrabajo",
  "typeMoney",
  "stringAmount",
];

export const ContactHeader = () => {
  const [formData, setFormData] = useState({ ...initialState });
  const [selectedOption, setSelectedOption] = useState(initialOption);
  const [validateRut, setValidateRut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobile, setmobile] = useState("");
  const [isError, setIsError] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);

  const location = useLocation();

  const [requiredFields, setRequiredFields] = useState([
    ...initialRequiredFields,
  ]);
  const [incompleteFields, setIncompleteFields] = useState([]);

  const inputHandler = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const inputHandlerFono = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const regex = /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/;

    if (!value || regex.test(value) === false) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const inputHandlerEmail = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!value || regex.test(value) === false) {
      setIsErrorEmail(true);
    } else {
      setIsErrorEmail(false);
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const validateButton = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["firstName"],
      formData
    );
    if (
      incompleteFieldsArray.length == 0 &&
      validateRut &&
      !isError &&
      !isErrorEmail
    ) {
      setSubmitButtonEnabled(false);
    } else {
      setSubmitButtonEnabled(true);
    }
  };

  useEffect(() => {
    validateButton();
  }, [formData]);

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      setFormData({
        ...formData,
        phone: `+56 ${formData.phone}`,
      });
      setLoading(true);
      await sendGeneralContact(formData);

      createNotification("success", "Formulario Enviado!", "Te contactaremos");
      setFormData(initialState);
      setLoading(false);
      // console.log("esto se envió", formData);
      // history.push("/infoReceived");
    } catch (error) {
      createNotification(
        "error",
        "Por favor intentelo más tarde.",
        "Ha ocurrido un error al enviar su formulario"
      );
      setLoading(false);
    }
  };

  const commentTextArea = (placeholder) => (
    <textarea
      name="message"
      className={`${incompleteFields.includes("message") ? "input_error" : ""}`}
      placeholder={placeholder}
      value={formData.message}
      onChange={inputHandler}
    />
  );
  const { startDate, endDate, message, typeMoney, stringAmount, amount } = formData;
  //Renderiza los formularios para cada accion que se requiera
  useEffect(() => {
    setIncompleteFields([]);
    setFormData({
      firstName: formData.firstName,
      lastName: formData.lastName,
      amount: formData.amount,
      rut: formData.rut,
      createdAt: formData.createdAt,
      email: formData.email,
      phone: formData.phone,
      businessRut: formData.businessRut,
      businessName: formData.businessName,
      warrantyType: formData.warrantyType,
      message: formData.message,
      garantia: formData.garantia,
      factoring: formData.factoring,
      capitalTrabajo: formData.capitalTrabajo,
      typeMoney: formData.typeMoney,
      cotizacion: formData.cotizacion,
      stringAmount: formData.stringAmount,
    });
  }, [selectedOption]);

  useEffect(() => {
    setIncompleteFields([]);
    if (selectedOption === "sugerencia") {
      setFormData({
        ...formData,
        amount: "0",
      });
      setRequiredFields([...initialRequiredFields]);
    } else {
      setFormData({
        ...formData,
        amount: formData.amount,
        message: formData.message,
      });
    }
  }, [selectedOption, message]);

  useEffect(() => {
    setIncompleteFields([]);
    // console.log(selectedOption);
    if (selectedOption == "licitación privada") {
      setFormData({
        ...formData,
        message: `(Licitacion Privada / Trato Directo) Monto de licitacion : ${formData.stringAmount} ${formData.typeMoney}/ Fecha :  ${formData.startDate} - ${formData.endDate}`,
        stringAmount: formData.stringAmount,
        amount:0,
        startDate: formData.startDate,
        endDate: formData.endDate,
        typeMoney: formData.typeMoney
      });
      setRequiredFields([...initialRequiredFields]);
    } 
  }, [stringAmount, selectedOption, startDate, endDate, typeMoney]);

  useEffect(() => {
    if (selectedOption === "factoring") {
      setFormData({
        ...formData,
        message: ` Factoring - Monto : ${formData.amount}`,
      });
      setRequiredFields([...initialRequiredFields]);
    }
    setIncompleteFields([]);
    if (selectedOption === "solicitud de cobro") {
      setFormData({
        ...formData,
        amount: "0",
      });
      setRequiredFields([...initialRequiredFields]);
    }

    if (selectedOption === "capitalTrabajo") {
      setFormData({
        ...formData,
        message: `${formData.capitalTrabajo} - monto promedio mensual ${formData.stringAmount}`,
        capitalTrabajo: "Capital de Trabajo",
        amount: formData.stringAmount,
      });
      setRequiredFields([...initialRequiredFields]);
    }
  }, [selectedOption, message, amount, stringAmount]);

  useEffect(() => {
    if (typeMoney.length > 0) {
      setFormData({
        ...formData,
        amount: `${formData.stringAmount} ${formData.typeMoney}`,
        typeMoney: formData.typeMoney,
      });
      // console.log(typeMoney.length);
    }
  }, [typeMoney]);

  useEffect(() => {
    setFormData({
      ...formData,
      phone: `${formData.phone}`,
    });
  }, []);
  
  return (
    <section className="contact__container__index" id="contact">
      <form className="form-contact-index">
        <div className="form-contact__text__initial">
          <h2>Deja tus datos y cotiza con nosotros</h2>
        </div>
        <div className="form-contact__form">
          <div className="form-contact__inputs__index">
            <div className="d-flex">
              <InputForm
                type="text"
                name="firstName"
                label="Nombre y Apellido "
                value={formData.firstName}
                handlerChange={inputHandler}
                className="mt-4"
                fullWidth
                size="small"
              />
            </div>
            <div className="d-flex flex-column w-100">
              <TextField
                type="number"
                name="phone"
                className="mt-3"
                value={formData.phone}
                id="outlined-basic"
                label="Teléfono Móvil"
                title="Teléfono Móvil"
                variant="outlined"
                onChange={inputHandlerFono}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+56</InputAdornment>
                  ),
                }}
              />

              {isError && <p className="invalid_phone">Telefono invalido</p>}
              <InputForm
                type="email"
                name="email"
                label="Email"
                value={formData.email}
                fullWidth
                placeholder="nombre@inge.cl"
                handlerChange={inputHandlerEmail}
                className="mt-4"
              />
              {isErrorEmail && <p className="invalid_phone">Email invalido</p>}
            </div>

            <div className="d-flex mt-4">
              <InputRut
                formData={formData}
                setFormData={setFormData}
                validateRut={setValidateRut}
                name="businessRut"
                label="Rut Empresa"
                className="input__rut__contact me-3"
              />
              <InputForm
                type="text"
                name="businessName"
                label="Nombre Empresa "
                className="input__rut__contact me-3"
                value={formData.businessName}
                handlerChange={inputHandler}
              />
            </div>
          </div>
          <section className="d-flex  flex-column">
            <div className="form-contact__optional_inputs">
              <div className="form_contact_select w-100">
                <InputSelect
                  name="optionValue"
                  value={selectedOption}
                  options={options}
                  handlerChange={({ target: { value } }) =>
                    setSelectedOption(value)
                  }
                  defaultValue="¿Cual es tu producto de interés?"
                />
              </div>
              {selectedOption === "licitación privada" && (
                <div className="case_licitacionPrivada_container w-100">
                  <div className="d-flex justify-content-between align-items-center">
                    <InputForm
                      type="number"
                      name="stringAmount"
                      title="Monto de Licitación"
                      placeholder="$99.999.999"
                      value={formData.stringAmount}
                      handlerChange={inputHandler}
                    />
                    <InputSelect
                      name="typeMoney"
                      label="Finalidad"
                      title="Elige el tipo de moneda"
                      className="padding__form ms-2 "
                      value={formData.typeMoney}
                      options={optionsMoney}
                      handlerChange={inputHandler}
                      error={incompleteFields.includes("typeMoney")}
                      defaultValue="Selecciona"
                    />
                  </div>
                  <DateInputs formData={formData} setFormData={setFormData} />
                </div>
              )}
              {selectedOption === "solicitud de cobro" &&
                commentTextArea("Solicitud de cobro...")}
              {selectedOption === "sugerencia" &&
                commentTextArea(
                  "Escriba aqui su felicitación, reclamo o sugerencia..."
                )}

              {selectedOption === "capitalTrabajo" && (
                <div className="d-flex">
                  <InputForm
                    type="number"
                    name="stringAmount"
                    title="Monto Promedio Mensual de tu Empresa"
                    placeholder="$99.999.999"
                    value={formData.stringAmount}
                    handlerChange={inputHandler}
                  />
                </div>
              )}
              {selectedOption === "factoring" && (
                <div className="d-flex justify-content-between">
                  <InputForm
                    type="number"
                    name="amount"
                    title="Monto de Licitación"
                    placeholder="$99.999.999"
                    value={formData.amount}
                    handlerChange={inputHandler}                    
                  />
                </div>
              )}
            </div>
            <div className="check__index mt-3">
              <input name="acceptCheck" type="checkbox" />
              <p>Acepto los terminos y Condiciones y Politicas de privacidad</p>
            </div>
            <div className="w-100 d-flex justify-content-center">
              <button
                className="button__blue mt-2 w-75"
                type="submit"
                value="Enviar"
                onClick={submitHandler}
                disabled={submitButtonEnabled}
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <p className="m-0">Quiero que me Contacten</p>
                )}
              </button>
            </div>
          </section>
        </div>
      </form>
    </section>
  );
};
