/* eslint-disable no-unused-vars */
import React from 'react';
import './styles.css';

function TableItem({ item, mobileDataFields, actions }) {
  return (
    <div className="table_item">
      {Object.keys(item).map((key, index) => {
        // eslint-disable-next-line no-constant-condition
        const dataRow = mobileDataFields.find(({ field }) => field === key);
        if (dataRow && item[dataRow.field]) {
          return (
            <div className="table_item_data" key={index}>
              <h4 className="item_data_title">
                {dataRow.title}
                :
                {' '}
              </h4>
              <div className="item_data_value">
                <p>{item[dataRow.field]}</p>
              </div>
            </div>
          );
        } return null;
      })}
      {actions && (
      <div className="item_data_actions">
        {actions.map((action, index) => (
          <div
            className={`item_data_action ${action.round && 'round'}`}
            key={index}
            onClick={() => action.onClick(item)}
            role="button"
            tabIndex={0}
            style={{ backgroundColor: action.backgroundColor || '#0166ff' }}
          >
            {action.icon()}
          </div>
        ))}
      </div>
      )}
    </div>
  );
}

export default TableItem;
