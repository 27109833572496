import React, { useEffect, useState } from "react";
import "./styles.css";
import { checkRut, prettifyRut } from "react-rut-formatter";
import InputForm from ".";
import { checkIncompleteFields } from "../../utils/helpers/helpers";
import { TextField } from "@mui/material";

function InputRut({
  formData,
  setFormData,
  name,
  className,
  title,
  error,
  styles,
  validateRut,
  label
}) {
  const [rutIsValid, setRutIsValid] = useState(true);
  const rutInputHandler = (event) => {
    const {
      target: { value, name: key },
    } = event;
    const prettyRut = prettifyRut(value);
    setRutIsValid(checkRut(prettyRut));
    if (!rutIsValid) {
      validateRut(true)
    } else {
      validateRut(false)
    }
    setFormData({
      ...formData,
      [key]: prettyRut,
    });
  };
  return (
    <div
      style={styles ? styles : { width: "100%", margin: 0, padding: 0 }}
      className={`rut_input_container ${className || ""}`}
    >
      <TextField
        type="text"
        name={name}
        title={title}
        value={formData[name]}
        onChange={(e) => rutInputHandler(e)}
        error={error}
        id="outlined-basic"
        label={label}
        variant="outlined"
        size="small"
        fullWidth

      />
      {!rutIsValid && <p className="invalid_rut">Rut invalido</p>}
    </div>
  );
}

export default InputRut;
