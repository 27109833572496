import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import celebrateImg from '../../assets/images/logo-celebrate.png';
import { addToCart } from '../../store/actions/shoppingCartActions';
import { updateShoppingCart } from '../../utils/helpers/helpers';
import Button from '../../components/Button';
import './styles.css';

// eslint-disable-next-line no-shadow
function Success({ user, addToCart }) {
  const history = useHistory();

  useEffect(async () => {
    const { dataUser: { ClientId }, isAuth } = user;
    if (isAuth) {
      await updateShoppingCart(ClientId, addToCart);
      console.log('cart updated');
    }
  }, []);

  return (
    <div className="screen__container screen_center">
      <div className="loading_container">
        <div className="validation_img_container"><img src={celebrateImg} alt="" /></div>
        <h1>Transacción realizada con éxito!</h1>
        <Button value="MIS DOCUMENTOS" styles={{ width: 200 }} clickHandler={() => history.push('/documents')} />
      </div>
    </div>
  );
}

const mapStateToProps = ({ user, companies, shoppingCart }) => ({
  user,
  companies,
  shoppingCart,
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (payload) => dispatch(addToCart(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Success);
