import "./styles.css";
import InputForm from "../InputForm";
import InputSelect from "../InputSelect";
import React, { useEffect, useState } from "react";
import { sendAnonymousSimulation } from "../../utils/apis/warrantiesApi";
import ModalLoading from "./ModalLoading";
import ModalSendRequest from "./ModalSendRequest";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { checkIncompleteFields } from "../../utils/helpers/helpers";
import InputRut from "../InputForm/InputRut";
import { Divider, FormControl, Input, InputAdornment, InputBase, InputLabel, MenuItem, Select, TextField, styled } from "@mui/material";

import { Spinner } from "react-bootstrap";


const optionsMoney = [
  {
    value: "Pesos",
    label: "$ Peso",
  },
  {
    value: "UF",
    label: "UF Unidad de fomento",
  }
];

const initialState = {
  fullName: "",
  rut: "",
  phone: "",
  tenderAmountString: "",
  amount: "",
  endDate: "",
  tenderWarrantyTarget: "",
  email: "",
  typeMoney: ""
};


function SimulationInitial({ incompleteFields, options, todaysDate }) {
  const [data, setData] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const history = useHistory();
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);
  const [validateRut, setValidateRut] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [currency, setCurrency] = React.useState('dollar');
  const [age, setAge] = React.useState('');

  const {
    fullName,
    rut,
    phone,
    tenderAmountString,
    endDate,
    tenderWarrantyTarget,
    email,
    typeMoney
  } = data
  useEffect(() => {
    setData({
      fullName: data.fullName,
      rut: data.rut,
      phone: data.phone,
      tenderAmountString: data.tenderAmountString,
      amount : data.amount,
      endDate: data.endDate,
      tenderWarrantyTarget: data.tenderWarrantyTarget,
      email: data.email,
      typeMoney: data.typeMoney

    });

  }, [])



  const validateButton = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      [
        "fullName",
        "rut",
        "phone",
        "tenderAmountString",
        "endDate",
        "tenderWarrantyTarget",
        "email",
        "typeMoney"
      ],
      data
    );
    if (incompleteFieldsArray.length == 0 && validateRut && !isError && !isErrorEmail) {
      setSubmitButtonEnabled(false);
    } else {
      setSubmitButtonEnabled(true);
    }
  };
  const inputHandler = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setAge(value)
    setData({
      ...data,
      [key]: value,
    });
  };
  useEffect(() => {
    if (typeMoney.length > 0) {
      setData({
        ...data,
        tenderAmountString: `${data.amount} ${data.typeMoney}`,
        typeMoney: data.typeMoney
      })
      console.log(typeMoney.length)
    }


  }, [typeMoney]);

  const inputHandlerEmail = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!value || regex.test(value) === false) {
      setIsErrorEmail(true);
    } else {
      setIsErrorEmail(false);
    }
    setData({
      ...data,
      [key]: value,
    });
  };
  useEffect(() => {
    validateButton();
  }, [data]);

  const handleClick = () => {
    history.goBack();
  };

  const inputHandlerFono = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const regex = /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/;

    if (!value || regex.test(value) === false) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setData({
      ...data,
      [key]: value,
    });
  };

  console.log(data)
  const simulationSimpleInitial = async () => {
    try {
      setLoading(true);
      const res = await sendAnonymousSimulation(data);
      setLoading(false);
      history.push("/infoReceived");
    } catch (err) {
      console.error();
    }
  };

  return (
    <>
      <div className="d-flex flex-column my-4">
        <InputForm
          type="text"
          name="fullName"
          label="Nombre Completo"

          className="padding__form"
          value={data.fullName}
          handlerChange={inputHandler}
          error={incompleteFields.includes("tenderAmountString")}
        />

        <InputRut
          formData={data}
          type="text"
          name="rut"
          label="Rut"
          placeholder="9.999.999-9"
          className="my-4 padding__form"
          value={data.rut}
          setFormData={setData}
          validateRut={setValidateRut}
          handlerChange={inputHandler}
          error={incompleteFields.includes("tenderAmountString")}
        />
        <TextField
          type="number"
          name="phone"
          className="w-100 padding__form"
          value={data.phone}
          id="outlined-basic"
          label="Teléfono Móvil"
          title="Teléfono Móvil"
          variant="outlined"
          onChange={inputHandlerFono}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+56</InputAdornment>
            ),
          }}
        />
        {isError && <p className="invalid_phone">Telefono invalido</p>}

      </div>
      <div className="d-flex flex-column">
        <InputForm
          type="text"
          name="amount"
          label="Monto de garantía"
          // placeholder="1500000"
          className="padding__form"
          value={data.amount}
          handlerChange={inputHandler}
          id="demo-customized-textbox"
        />
        <InputSelect
          name="typeMoney"
          label="Finalidad"
          className="padding__form mt-4"
          value={data.typeMoney}
          options={optionsMoney}
          handlerChange={inputHandler}
          error={incompleteFields.includes("typeMoney")}
          defaultValue="Elige el tipo de moneda"
        />
        <InputForm
          type="email"
          name="email"
          label="Email"
          className="my-4 padding__form"
          value={data.email}
          handlerChange={inputHandlerEmail}
          error={incompleteFields.includes("email")}
        />
        {isErrorEmail && <p className="invalid_phone">Email invalido</p>}
        <InputSelect
          name="tenderWarrantyTarget"
          label="Finalidad"
          className="padding__form"
          value={data.tenderWarrantyTarget}
          options={options}
          handlerChange={inputHandler}
          error={incompleteFields.includes("tenderWarrantyTarget")}
          defaultValue="Seleccione su finalidad..."
        />
        <InputForm
          title="Fecha Término"
          type="date"
          min={todaysDate}
          className="padding__form"
          value={data.endDate || ""}
          name="endDate"
          handlerChange={inputHandler}
          error={incompleteFields.includes("endDate")}
        />
      </div>


      <button
        type="button"
        onClick={() => handleClick()}
        className="button__gray mt-4 float-start"
      >
        Volver
      </button>
      <button
        type="button"
        onClick={() => simulationSimpleInitial()}
        className="button__blue mt-4 float-end"
        disabled={submitButtonEnabled}
      >
        {
          loading ?
            <Spinner /> :
            <p className="m-0">
              Simular
            </p>

        }
      </button>
    </>

  );
}

export default SimulationInitial;
