import React, { useState } from "react";
import { useEffect } from "react";
import { sendToPay } from "../../utils/apis/warrantiesApi";
import { getShopWarranty } from "../../utils/apis/warrantiNotAuth";
import LoadingScreen from "../../components/Spinner/LoadingScreen";
import CartItem from "../../components/CartItem";
import CartTotal from "./CartTotal";
import PaymentModal from "../../components/PaymentModal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CartTotalNotAuth } from "./CartTotalNotAuth";

const dataObject = {
    monto: 0,
    glosa: " ",
    productos: [
      {
        nombre_producto: "",
        descripcion_producto: "prueba",
        precio_producto: 0,
      },
    ],
    nom_pag: " ",
    mail_pag: " ",
    warrantiesIds: [0],
    companyUserId: 0,
  };
  

export const CartNotAuth = () => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState();
  const [cart, setCart] = useState([]);
  const [data, setData] = useState(dataObject);

  let { id } = useParams();
  const getWarranty = async () => {
    const resp = await getShopWarranty(id);
    setCart(resp);
  };

  useEffect(() => {
    getWarranty();
  }, [totalPrice]);

  const goToPayment = (total) => {
    if (cart.Warranties[0].InsuranceCompanyId == -3) {
      data.companyUserId = cart.ClientUserId;
      data.mail_pag = "Ingresa tu Email";
      data.nom_pag = "ClientName";
      data.monto = cart.ShoppingCartTotal;
      data.glosa = cart.Warranties[0].Glosa;
      data.productos[0].nombre_producto = cart.Warranties[0].WarrantyType;
      data.productos[0].descripcion_producto = cart.Warranties[0].WarrantyInfo;
      data.productos[0].precio_producto = cart.Warranties[0].WarrantyCost;

      data.warrantiesIds = cart.Warranties.map(
        (warranty, index) => warranty.WarrantyId
      );
      sendToPay(data).then((result) => {
        console.log(result);
        window.open(result.RedirectionFormUrl);
      });
      console.log(cart);
    } else {
      setShowPaymentModal(true);
      setTotalPrice(total);
      setData();
    }
  };

  return (

      <article className="contenedor_carrito mt-5">
        <section className="cart_container_empty">
          {cart.length === 0 ? (
            <LoadingScreen loadingText="Cargando Datos de Garantía..." />
          ) : (
            cart.Warranties.map((warranty, index) => (
              <CartItem
                item={warranty}
                key={index}              
              />
            ))
          )}
          {cart.length === 0 ? (
            <LoadingScreen />
          ) : (
            <CartTotalNotAuth goToPayment={goToPayment} cart={cart} />
          )}
        </section>
        {showPaymentModal && (
          <PaymentModal
            closeModal={() => setShowPaymentModal(false)}
            shoppingCart={cart}
            totalPrice={totalPrice}
            totalPriceDesc={totalPrice.ShoppingCartTotal}         
          />
        )}
      </article>

  );
};
