import React from 'react';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

function BannerFaqs({ title }) {
  return (
    <article className="banner-faqs">
      <h2>
        {title}
      </h2>
      <FontAwesomeIcon
        icon={faChevronDown}
        color="#372FA8"
        size="1x"
      />
    </article>
  );
}

export default BannerFaqs;
