import "./styles.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logoSll from "../../assets/images/ssl-security.png";
import logoCt from "../../assets/images/logo_ct.png";
import InputRut from "../../components/InputForm/InputRut";
import InputPassword from "../../components/InputForm/InputPassword";

import { saveSiiCredentials } from "../../utils/apis/clientUsersApi";
import btnClose from "../../assets/images/btnClose.svg";
import swal from "sweetalert";
import { FaRegWindowClose } from "react-icons/fa";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { checkIncompleteFields } from "../../utils/helpers/helpers";


function ModalClave({ setClaveSii }) {
  const user = useSelector((state) => state.user);
  const { ClientId } = user.dataUser;
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(true);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [isError, setIsError] = useState(false);



  const validateButton = () => {
    const incompleteFieldsArray = checkIncompleteFields(
      ["rut", "password", "acceptCheck"],
      formData
    );
    console.log("campos vacios", incompleteFieldsArray);
    if (incompleteFieldsArray.length == 0 && validateRut && !isError) {
      setSubmitButtonEnabled(false);

    } else {
      setSubmitButtonEnabled(true);
    }
  };

  const [formData, setFormData] = useState({
    clientId: ClientId,
    rut: "",
    password: "",
    acceptCheck: ""
  });
  const [validateRut, setValidateRut] = useState(false);

  const handlerCheck = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlerInput = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  useEffect(() => {
    validateButton();
  }, [formData]);

  const CloseModal = () => {
    setClaveSii(false);
  };

  const saveCredentials = async () => {
    try {
      const res = await saveSiiCredentials(formData);
      console.log(res);
      setClaveSii(false);
    } catch (err) {
      swal("No se pudo guardar su clave, intentelo nuevamente");
      setClaveSii(false);
    }
  };
  return (
    <article className="container__modal__simulation">
      <div className="modal__clave">
        <section className="header__modal__clave">

          <img src={logoCt} alt="logoSii" className="logoClave"></img>
          <button
            type="button"
            onClick={CloseModal}
            className="btn__close__clave"
          >
            <FaRegWindowClose className="close_sii" />
          </button>
        </section>
        <section className="container__inputs__claveSii">
          <section className="container__inputs__sii mt-3">
            <div className="label__claveSii mb-3">
              <label>RUT</label>
              <InputRut
                formData={formData}
                setFormData={setFormData}
                name="rut"
                title="RUT"
                value={formData.rut}
                validateRut={setValidateRut}
                handlerchange={handlerInput}
                className="input__save__rut"
              />
            </div>
            <div className="label__claveSii mb-3">
              <label>Ingresar clave tributaria</label>
              <InputPassword
                name="password"
                title="Ingresar Clave Tributaria"
                placeholder="*********"
                value={formData.password}
                handlerChange={handlerInput}
                className="input__save__clave"
              />
            </div>
            <section className="checkbox">
              <div className="acept-check">
                <input
                  name="acceptCheck"
                  value={formData.acceptCheck}
                  type="checkbox"
                  onClick={(e) => {
                    handlerCheck(e);
                  }}
                ></input>
                Acepto permitir a Inge acceder a visualizar mi informacion en el Servicios de Inpuestos Internos
              </div>
            </section>

            <section className="contenedor__modal__clave">

              <button
                type="submit"
                onClick={saveCredentials}
                disabled={submitButtonEnabled}
                className="button__save__clave"
              >
                Guardar
              </button>

              <div className="container__button__modalError"></div>
            </section>
          </section>
          <hr className="sideBar"></hr>
          <div className="container__text__sii">
            <p>
              En Inge, nos comprometemos a proteger tus datos personales y respetar tu privacidad en todo momento.
            </p>
            <p>
              Todos los datos que proporcionas, incluida tu contraseña, se almacenan de manera cifrada.
            </p>
            <p>
              Nos comprometemos a ser transparentes en
              cuanto a cómo se utilizan tus datos y nunca compartiremos ni venderemos tu información personal a
              terceros.
            </p>
            <img src={logoSll} alt="logoSii" className="logoSll"></img>
          </div>

        </section>

      </div>
    </article>
  );
}

export default ModalClave;
