/* eslint-disable no-unused-vars */
import React from "react";
import "./styles.css";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Graphic from "../Chart/Chart.js";
import image from "../../../../assets/images/illustration4.png";
import Button from "../../../../components/Button";

function Chart() {
  const history = useHistory();

  return (
    <div className="content-graph-principal">
      <div className="text-principal">
        <div className="graph-container">
          <div className="container__text__background">
            <div className="text__graph">
              <div className="title__graph">
                <h2>Hasta la fecha hemos emitido más de...</h2>
              </div>
              <div className="warrantys__graph">
                <p>+6.200 Garantías</p>
              </div>
              {/* 
          <p>Compra tus garantías y los seguros generales que vas a necesitar para llevar a tu empresa a otro nivel con las licitaciones de Mercado Público o Tratos Directos.
</p> */}
            </div>
          </div>

          <div className="content-graphic-warranty">
            <div className="graphic-warranty">
              <Graphic />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(Chart);
