import React, { useEffect, useState } from 'react';
import { checkIncompleteFields, createNotification } from '../../utils/helpers/helpers';
import { changePassword } from '../../utils/apis/clientUsersApi';
import Button from '../Button';
import InputPassword from '../InputForm/InputPassword';
import InputForm from '../InputForm';
import './styles.css';

function PasswordChange({
  formData, setFormData, userData: { ClientId }, closeSection,
}) {
  const [incompleteFields, setIncompleteFields] = useState([]);
  const [unmatchingPasswords, setUnmatchingPasswords] = useState(false);
  const [loading, setLoading] = useState(false);


  const inputHandler = (event) => {
    /// /////////////////////////////////////////////////////
    // Primero se saca el input en el que se está escribiendo de los campos incompletos.
    /// /////////////////////////////////////////////////////
    setIncompleteFields(incompleteFields.filter((field) => field !== event.target.name));
    /// //////////////////////////////////////////
    const key = event.target.name;
    const { value } = event.target;
    setFormData({
      ...formData,
      [key]: value,
    });
  };
  const checkPassword = async () => {
    const incompleteFieldsArray = checkIncompleteFields(['newPassword', 'newPasswordRepeated'], formData);
    setIncompleteFields(incompleteFieldsArray);
    if (incompleteFieldsArray.length === 0) {
      if (formData.newPassword === formData.newPasswordRepeated) {
        setUnmatchingPasswords(false);
        setLoading(true);
        try {
          const res = await changePassword(
            ClientId,
            formData.newPassword,
            formData.newPasswordRepeated,
            formData.changePasswordCode
          );
          createNotification('success', null, 'Contraseña cambiada con éxito', 3000);
          closeSection();
        } catch (error) {
          setLoading(false);
        }
      } else {
        setUnmatchingPasswords(true);
      }
    }
  };

  useEffect(() => {
    setFormData({
      ...formData, newPassword: '', newPasswordRepeated: '', changePasswordCode: '',
    });
  }, []);
  return (
    <div className="passwordChange_container">
      <InputForm
      title="Código"
      value={formData.changePasswordCode}
      name="changePasswordCode"
      handlerChange={inputHandler}
      error={incompleteFields.includes('changePasswordCode')}
      className="input__password__change">
      </InputForm>

      <InputPassword
        title="Nueva contraseña"
        value={formData.newPassword}
        placeholder="********"
        name="newPassword"
        handlerChange={inputHandler}
        error={incompleteFields.includes('newPassword')}
        className="input__password__change"

      />
      {unmatchingPasswords && <p className="passwordChange__error">Las contraseñas deben coincidir *</p>}
      <InputPassword
        title="Confirmar contraseña"
        value={formData.newPasswordRepeated}
        placeholder="********"
        name="newPasswordRepeated"
        handlerChange={inputHandler}
        error={incompleteFields.includes('newPasswordRepeated')}
        className="input__password__change"

      />
      {unmatchingPasswords && <p className="passwordChange__error">Las contraseñas deben coincidir *</p>}
      <div className="passwordChange__actions">
        <Button  className="button__change__password" value="Cancelar" styles={{ fontSize: '0.8rem', backgroundColor: 'transparent' }} clickHandler={() => closeSection()} />
        <Button  value="Guardar" loading={loading} styles={{ fontSize: '0.8rem' }} clickHandler={() => checkPassword()} />
      </div>
    </div>
  );
}

export default PasswordChange;
