import React from 'react';
// import Spinner from '../../components/Spinner';
import './styles.css';

function PaymentValidation() {
  // const [validating] = useState(false);
  // const [transactionValidated] = useState(false);
  return (
    <div className="validation_container">
      {/* {validating && (
      <div className="loading_container">
        <h1>Estamos validando su transacción</h1>
        <Spinner styles={{
          fontSize: '25px', margin: 0, borderColor: 'var(--secondary-color)',
        }}
        />
      </div>
      )} */}
      <div className="loading_container">
        <h1>Ups! El pago ha sido rechazado.</h1>
        {/* <div className="validation_img_container">
            <img src={celebrateImg} alt="" /></div> */}
      </div>

    </div>
  );
}

export default PaymentValidation;
