export const dataOptions = {
    contactFormId: 0,
    firstName: "",
    factura: "",
    email: "",
    phone: "",
    ticket: "",
    rutProveedor:"",
    rutReceptor:"",
    typeMoney: "" ,
    stringAmount:"",
    "g-recaptcha-response": ""
}
export const initialRequiredFields = [
    "firstName",
    "factura",
    "email",
    "phone",
  ];