import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { store } from '../../store';

function RouteCheck(props) {
  const { isAuth } = store.getState().user;

  if (props.type === 'private' && !isAuth) return <Redirect to="/" />;
  if (props.type === 'guest' && isAuth) return <Redirect to="/" />;
  return <Route {...props} />;
}

export default RouteCheck;
